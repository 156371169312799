import emptySplitApi from "../utils/rtk";
import { User, updateProfile } from "../types/User";
import { END_POINTS } from "../helpers";
import { CommonBody } from "../types/General";

type CommonResponseType = {
  data: any;
  statusCode: number;
  message: string;
  s3Location?: string
};


export const authApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    postAddProperty: builder.mutation<
      CommonResponseType & { data: any },
      CommonBody
    >({
      query: (body) => ({
        url: END_POINTS.property,
        method: "POST",
        body,
      }),
    }),
    getPendingProperty: builder.query<CommonResponseType & { data: any },
      { page?: number; search?: string; limit: number, propertyType?: string, category?: string, price?: string }>({
        query: ({ page, search, limit,propertyType,category,price }) => {
          let url = `${END_POINTS.property}?page=${page}&search=${search}&limit=${limit}&propertyTypeId=${propertyType}&categoryId=${category}&price=${price}`;


          return {
            url: url,
            method: "GET",
          };
        },
      }),
    getApprovedProperty: builder.query<CommonResponseType & { data: any }, 
    { page?: number; search?: string; limit: number, propertyType?: string, category?: string, price?: string }>({
      query: ({ page, search, limit ,propertyType,category,price}) => {
        let url = `${END_POINTS.property}?propertyStatus=2&page=${page}&search=${search}&limit=${limit}&propertyTypeId=${propertyType}&categoryId=${category}&price=${price}`;
       

        return {
          url: url,
          method: "GET",
        };
      },
    }),

    getRejectedProperty: builder.query<CommonResponseType & { data: any },
     { page?: number; search?: string; limit: number , propertyType?: string, category?: string, price?: string}>({
      query: ({ page, search, limit ,propertyType,category,price}) => {
        let url = `${END_POINTS.property}?propertyStatus=3&page=${page}&search=${search}&limit=${limit}&propertyTypeId=${propertyType}&categoryId=${category}&price=${price}`;
     

        return {
          url: url,
          method: "GET",
        };
      },
    }),



    getSpecificPropertyById: builder.query<CommonResponseType & { data: any }, { userId: any }>
      ({
        query: ({ userId }) => ({
          url: `${END_POINTS.property}/${userId}`,
          method: "GET"
        })
      }),
    popertyChatById: builder.query<CommonResponseType & { data: any }, { userId: any }>
      ({
        query: ({ userId }) => ({
          url: `${END_POINTS.chats}?propertyId=${userId}`,
          method: "GET"
        })
      }),

    EditProperty: builder.mutation<
      CommonResponseType & { data: CommonBody },
      { id: string | undefined; body: CommonBody }
    >({
      query: ({ body, id }) => ({
        url: `${END_POINTS.property}/${id}`,
        method: "PUT",
        body,
      }),
    }),



    deleteByIdProperty: builder.query<CommonResponseType & { data: any }, { userId: any }>({
      query: ({ userId }) => ({
        url: `${END_POINTS.property}/${userId}`,
        method: "DELETE",
      }),
    }),
    getAmenitiesListing: builder.query<CommonResponseType, {}>({
      query: ({ }) => {
        let url = END_POINTS.amenitiesListing;


        return {
          url: url,
          method: "GET",
        };
      },
    }),

    getpropertYTypeListing: builder.query<CommonResponseType, {}>({
      query: ({ }) => {
        let url = END_POINTS.propertyTypeListing;


        return {
          url: url,
          method: "GET",
        };
      },
    }),
  }),
});

export const {
  usePostAddPropertyMutation,
  useLazyGetPendingPropertyQuery,
  useLazyGetApprovedPropertyQuery,
  useLazyGetRejectedPropertyQuery,
  useLazyGetSpecificPropertyByIdQuery,
  useEditPropertyMutation,
  useLazyDeleteByIdPropertyQuery,
  useLazyGetAmenitiesListingQuery,
  useLazyGetpropertYTypeListingQuery,
  useLazyPopertyChatByIdQuery
} = authApi;
