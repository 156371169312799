 

import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import MainContainer from "../../layout/MainContainer";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  usePostAddNotificatiosnMutation,
  useLazyGetAllAgentQuery,
  useLazyGetAllUserListNotificationQuery,
  useLazyGetAllCompanyQuery,
} from "../../services/notifications";
import { errorToast, successToast } from "../../helpers";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";

const AddNotification = () => {
  const navigate = useNavigate();
  const [addNotification, { isLoading }] = usePostAddNotificatiosnMutation();
  const [receiver, setReceiver] = useState<any[]>([]);
  const [fetchAgents] = useLazyGetAllAgentQuery();
  const [fetchUsers] = useLazyGetAllUserListNotificationQuery();
  const [fetchCompanies] = useLazyGetAllCompanyQuery();
  const [agentList, setAgentList] = useState<any[]>([]);
  const [userList, setUserList] = useState<any[]>([]);
  const [companyList, setCompanyList] = useState<any[]>([]);
  const [dropdownType, setDropdownType] = useState("");
  const [role, setRole] = useState<any>([]);
  const [selectAllFromFirstDropdown, setSelectAllFromFirstDropdown] = useState(false);

  const handleDropdownTypeChange = async (event: any) => {
    const selectedType = event.target.value;
    setDropdownType(selectedType);
    setReceiver([]);
    setSelectAllFromFirstDropdown(selectedType === "Select All");

    if (selectedType !== "Select All") {
      switch (selectedType) {
        case "User":
          setRole(4);
          await fetchAllUsers();
          break;
        case "Agent":
          setRole(3);
          await fetchAllAgents();
          break;
        case "Company":
          setRole(5);
          await fetchAllCompanies();
          break;
        default:
          setAgentList([]);
          setUserList([]);
          setCompanyList([]);
          setRole(null);
      }
    } else {
      setRole([]);
      setAgentList([]);
      setUserList([]);
      setCompanyList([]);
    }
  };

  const fetchAllAgents = async () => {
    try {
      const response = await fetchAgents({}).unwrap();
      if (response?.statusCode === 200) {
        setAgentList(response?.data);
      }
    } catch (error: any) {
      errorToast(error.message);
    }
  };

  const fetchAllUsers = async () => {
    try {
      const response = await fetchUsers({}).unwrap();
      if (response?.statusCode === 200) {
        setUserList(response?.data);
      }
    } catch (error: any) {
      errorToast(error.message);
    }
  };

  const fetchAllCompanies = async () => {
    try {
      const response = await fetchCompanies({}).unwrap();
      if (response?.statusCode === 200) {
        setCompanyList(response?.data);
      }
    } catch (error: any) {
      errorToast(error.message);
    }
  };

  const handleReceiverChange = (event: any, value: any) => {
    setReceiver(value);
    formik.setFieldValue('receiverId', value);
    formik.setFieldTouched('receiverId', true);
  };

  const formik = useFormik({
    initialValues: {
      title: "",
      message: "",
      receiverId: receiver,
    },
    validationSchema: Yup.object({
      title: Yup.string()
        .required("This field is required")
        .max(80, "Maximum 80 characters are allowed")
        .min(2, "Minimum 2 characters are required"),
      message: Yup.string()
        .required("This field is required")
        .max(100, "Maximum 100 characters are allowed")
        .min(2, "Minimum 2 characters are required"),
    }),
    onSubmit: async (values) => {
      console.log("Form submitted", values);
  
    
      if (dropdownType !== "Select All" && receiver.length === 0) {
        errorToast("Please select at least one receiver");
        return;
      }
  
      let body: any = {
        title: formik.values.title,
        message: formik.values.message,
      };
  
      if (selectAllFromFirstDropdown || (receiver.length === 1 && receiver[0]._id === "Select All")) {
        if (!selectAllFromFirstDropdown) {
          body.role = role;
        }
      } else {
        body.role = role;
        body.users = receiver.map((rec) => rec._id);
      }
   
      try {
        const encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
        const response = await addNotification(encryptedBody).unwrap();
        if (response.statusCode === 200) {
          successToast(response?.message||"Notification Sent Successfully");
          navigate("/manage-notifications");
        } else {
          errorToast(response.message);
        }
      } catch (e: any) {
        console.error("API call failed", e);
        errorToast(e?.data?.message);
      }
    },
  });
  
  const getOptions = () => {
    const baseOptions = [{ name: "Select All", _id: "Select All" }];
    switch (dropdownType) {
      case "User":
        return baseOptions.concat(userList);
      case "Agent":
        return baseOptions.concat(agentList);
      case "Company":
        return baseOptions.concat(companyList);
      default:
        return baseOptions;
    }
  };

  return (
    <>
      <MainContainer>
        <div className="main_loyout">
          <div className="dashboard">
            <h1 className="mn_hdng">Add Notification</h1>
            <Button
              className="btn btn_primary"
              onClick={() => {
                navigate("/manage-notifications");
              }}
            >
              Back
            </Button>
          </div>
          <Card className="cards">
            <form onSubmit={formik.handleSubmit}>
              <CardContent sx={{ p: 1 }}>
                <Grid container spacing={2}>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      Notification Title
                    </Typography>
                    <TextField
                      hiddenLabel
                      type="text"
                      name="title"
                      variant="outlined"
                      fullWidth
                      placeholder="Title"
                      value={formik.values.title}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.title && Boolean(formik.errors.title)}
                      helperText={
                        formik.touched.title && formik.errors.title
                          ? formik.errors.title.toString()
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      Notification Message
                    </Typography>
                    <TextField
                      hiddenLabel
                      type="text"
                      name="message"
                      variant="outlined"
                      fullWidth
                      placeholder="Message"
                      value={formik.values.message}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.message && Boolean(formik.errors.message)}
                      helperText={
                        formik.touched.message && formik.errors.message
                          ? formik.errors.message.toString()
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      Select Receiver Type
                    </Typography>
                    <FormControl sx={{ width: "100%" }}>
                      <Select
                        value={dropdownType}
                        onChange={handleDropdownTypeChange}
                        displayEmpty
                      >
                        <MenuItem value="" disabled>
                          Select Receiver Type
                        </MenuItem>
                        <MenuItem value="User">User</MenuItem>
                        <MenuItem value="Agent">Agent</MenuItem>
                        <MenuItem value="Company">Company</MenuItem>
                        <MenuItem value="Select All">Select All</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  {dropdownType !== "Select All" && (
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <Typography className="custom_label">
                        Select Receivers
                      </Typography>
                      <FormControl sx={{ width: "100%" }}>
                        <Autocomplete
                          multiple
                          options={getOptions()}
                          getOptionLabel={(option) => option.name || "No Name"}
                          value={receiver}
                          onChange={handleReceiverChange}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              placeholder="Search and select receivers"
                              error={formik.touched.receiverId && Boolean(formik.errors.receiverId)}
                              helperText={
                                formik.touched.receiverId && formik.errors.receiverId
                                  ? formik.errors.receiverId.toString()
                                  : ""
                              }
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                  )}
                </Grid>
                <div className="form_btn">
                  <Button size="large" type="submit" className="btn btn_primary">
                    Save
                  </Button>
                </div>
              </CardContent>
            </form>
          </Card>
        </div>
      </MainContainer>
    </>
  );
};

export default AddNotification;
