import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Button,
  Card,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import SearchBar from "../../components/SearchBar";
import { useNavigate, useParams } from "react-router-dom";
import {usePostAddSubscriptionMutation,
useLazyGetAllSubscriptionQuery,
useLazyGetSpecificSubscriptionByIdQuery,
useLazyDeleteByIdSubscriptionQuery,
useDeleteByIdSubscriptionQuery,
useEditSubscriptionMutation}from "../../services/subscription";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";
import { errorToast, successToast } from "../../helpers";
import Pagination from "../../components/Pagination";
import WarnModal from "../../components/WarnModal";
import SearchBar2 from "../../components/SearchBar2";
import { isValidInput } from "../../utils/validation";
import Loader from "../../helpers/constants/Loader";
import AddIcon from '@mui/icons-material/Add';
const ManageSubscription = () => {
  const navigate = useNavigate();
  const{_id}=useParams();
  const [rows1, setRows1] = useState<any>([]);
  console.log("sublist",rows1);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
    const [ByIdDetails, setByIdDetails] = useState<any>(null);
  const [totalCount, setTotalCount] = useState<number>(0);
   const [limit, setLimit] = useState<number>(10);
  let totalPages = Math.ceil(totalCount / limit);
 
 const[fetchById]=useLazyGetSpecificSubscriptionByIdQuery();
  const [deleteSubscriptionById] = useLazyDeleteByIdSubscriptionQuery();
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState<number>(0);
  const onPageChange = (newPage: number) => {
    setPage(newPage);
    setRows1([]);
  };
  const [SubScriptionListing,{isLoading}]=useLazyGetAllSubscriptionQuery();
  const[editSubscriptionbyId]=useEditSubscriptionMutation();
  const fetchData = async (page: number, search: string) => {
    try {
      const response = await SubScriptionListing({ page:page, search:search,limit:limit }).unwrap();
      if (response.statusCode === 200) {
        setRows1(response?.data?.subscription||[]);
        setTotalCount(response?.data?.count);
      }  
    } catch (error) {
      console.error(error);
    }
  };
 
  const changeSubscriptionStatus=async(userId:string, userStatus:boolean)=>{
    try{
     let body={
      isBlocked:String(userStatus)
     }
     let encryptedBody=generateEncryptedKeyBody(body) as CommonBody;
     const response =await editSubscriptionbyId({body:encryptedBody,id:userId}).unwrap();
  if(response?.statusCode===200)
  {
    successToast("Status updated successfully");
  }
    }
    catch (error: any) {
      errorToast(error?.message);
      console.error(error);
    }
   }
   const handleDeleteSubscription = async (userId: any) => {
    try {
      const response = await deleteSubscriptionById({ userId }).unwrap();
      if (response?.statusCode === 200) {
        successToast("Subscription deleted successfully");
        fetchData(page, searchTerm);
      }
    } catch (error: any) {
      console.error(error);
      errorToast(error?.message || "");
    }
  };
  const fetchDataById = async (id: any) => {
    try {
      const response = await fetchById({ userId: id }).unwrap();
      if (response.statusCode === 200) {
        setByIdDetails(response?.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (_id) {
      fetchDataById(_id);
    }
  }, [_id]);

  useEffect(() => {
    fetchData(page, debouncedSearchTerm);
  }, [page, debouncedSearchTerm,limit]);
 
  return (
    <MainContainer>
        <Loader isLoad={isLoading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Manage Subscription</h1>
        </div>
        <Card className="cards">
          <Box className="cards_header">
          <SearchBar2
              value={searchTerm}
              searchTerm={searchTerm}
              onCross={() => setSearchTerm("")}
              setDebouncedSearchTerm={setDebouncedSearchTerm}
              onChange={(val: any) => {
                if (isValidInput(val.target.value)) {
                  setSearchTerm(val.target.value);
                }
              }}
            />
            <Box className="cards_header_right">
              <Button
                className="btn btn_primary"
                onClick={() => navigate("/manage-subscription/add")}
              >
                <AddIcon/>
                Add Subscription
              </Button>
            </Box>
          </Box>
          <TableContainer className="table_container">
            <Box className="heading"></Box>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">S.No</TableCell>
                  <TableCell>Subscription Name</TableCell>
                  <TableCell>Subscription type</TableCell>
                  <TableCell>Price</TableCell>
                  <TableCell>Validity</TableCell>
                 
                  {/* <TableCell>Status</TableCell> */}
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows1.length===0?(
                  <TableCell colSpan={7} align="center">
                 No subscription found
                  </TableCell>
                ):(rows1.map((row:any, i:any) => (
                  <TableRow key={row.i}>
                    <TableCell align="center">{i + 1}</TableCell>
                    <TableCell>{row?.name||"-"}</TableCell>
                    <TableCell>{row?.subscriptionPack=="1"?"Monthly":
                    row?.subscriptionPack=="3"?"Quarterly":
                    row?.subscriptionPack=="6"?"Half-Yearly":
                    row?.subscriptionPack=="12"?"Yearly":"-"
                    }</TableCell>
                    <TableCell>
                      <Typography variant="h6" fontSize={14}>
                        {row?.price||"-"}
                      </Typography>
                       
                    </TableCell>
                   
                    <TableCell>{row?.subscriptionPack=="1"?"1 month":
                    row?.subscriptionPack=="3"?"3 months":
                    row?.subscriptionPack=="6"?"6 months":
                    row?.subscriptionPack=="12"?"1 year":"-"
                    }</TableCell>
                   
                    {/* <TableCell>
                    <Switch
          defaultChecked={!row.isBlocked}
          onChange={(e) => {
            const isChecked = !e.target.checked;
            changeSubscriptionStatus(row._id, isChecked);  
          }}
          size="small"
        />
                    </TableCell> */}
                    <TableCell>
                      <Box className="table_actions">
                        <IconButton
                          onClick={() => navigate(`/manage-subscription/details/${row?._id}`)}
                        >
                          <VisibilityIcon />
                        </IconButton>
                        <IconButton
                          onClick={() =>
                            navigate(`/manage-subscription/edit/${row?._id}`, {
                              state: { type: "edit" },
                            })
                          }
                        >
                          <ModeEditIcon />
                        </IconButton>
                        <IconButton onClick={()=>{
                          setOpen(true);
                          setSelectedId(row?._id);
                        }}>
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                )))}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </div>
      {rows1?.length > 0 ? (
        <Pagination
          setPage={setPage}
          module={rows1}
          limit={limit}
          setLimit={setLimit}
          page={page}
          onPageChange={onPageChange}
          totalPages={totalPages}
        />
      ) : (
        ""
      )}
      <WarnModal
        open={open}
        setOpen={setOpen}
        handleDelete={() => handleDeleteSubscription(selectedId)}
        name="Subscription"
      />
    </MainContainer>
  );
};

export default ManageSubscription;
