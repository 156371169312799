 

import React, { useEffect } from "react";
import "./App.scss";
import Routing from "./Routes";
import { toast } from "react-toastify";
import { firebaseCloudMessaging } from "./utils/firebase";
import { useNavigate } from "react-router-dom";

function App() {
 
 

  return <Routing />;
}

export default App;
