import { useLocation, useNavigate } from "react-router-dom";
import React, { useState } from "react";
import {
  Box,
  Button,
  Card, Tab, Tabs,
  Typography
} from "@mui/material";


 
import MainContainer from "../../layout/MainContainer";
import { DocumentViewer } from "../../components";
import CompanyByIdDetails from "../../features/company/CompanyDetails";
import CompanyAgents from "../../features/company/CompanyAgents";
import CompanyProperty from "../../features/company/property";
import TokenHistoryAgent from "../../features/agents/TokenHistoryIndex";
import CompanyMessages from "../../features/company/companyMessage";
import CompanyReels from "../../features/company/ReelsByCompany";
 
 

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const CompanyDetails = () => {
  const location = useLocation();
  const { state } = location;
  const navigate = useNavigate();
  const [modalType, setModalType] = useState<number>(1);
  const [open, setOpen] = useState<boolean>(false);

  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">View Company Details</h1>
          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/manage-company");
            }}
          >
            Back
          </Button>
        </div>
     
        <Card sx={{ mt: 4 }} className="cards">
          <Box className="custom_tabs">
            <Box className="flx_sc">
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="basic tabs example"
                className="custom_tabs_links"
              >
                
                <Tab label="Profile Details" {...a11yProps(0)} />
                <Tab label="Agents" {...a11yProps(1)} />
                <Tab label="Properties" {...a11yProps(2)} />
                <Tab label="Messages" {...a11yProps(3)} />
                <Tab label="Token History" {...a11yProps(4)}/>
                <Tab label="Reels" {...a11yProps(5)}/>
               
               
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
              <CompanyByIdDetails />
            </CustomTabPanel> 
             <CustomTabPanel value={value} index={1}>
              <CompanyAgents />
            </CustomTabPanel> 
            <CustomTabPanel value={value} index={2}>
              <CompanyProperty />
            </CustomTabPanel> 
            <CustomTabPanel value={value} index={3}>
              <CompanyMessages />
            </CustomTabPanel> 
            <CustomTabPanel value={value} index={4}>
              <TokenHistoryAgent />
            </CustomTabPanel> 
            <CustomTabPanel value={value} index={5}>
              <CompanyReels />
            </CustomTabPanel> 
            
          </Box>
        </Card>
        <DocumentViewer open={open} setOpen={setOpen} />
      </div>
    </MainContainer>
  );
};

export default CompanyDetails;
