import emptySplitApi from "../utils/rtk";
 
import { END_POINTS } from "../helpers";
 

type CommonResponseType = {
  statusCode: number;
  message: string;
   s3Location?:string
};

 
export const emailApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
     
    getAllNotifyEmail: builder.query<CommonResponseType & { data: any }, { page?: number; search?: string;limit?:number}>({
      query: ({ page, search ,limit}) => {
        // let url = END_POINTS.notifyList;
        let url = `${END_POINTS.notifyList}?page=${page}&search=${search}&limit=${limit}`;
        // url+=`?limit=10`;
        // if (page && search) {
        //   url += `&page=${page}&search=${search}`;
        // } else if (page) {
        //   url += `&page=${page}`;
        // } else if (search) {
        //   url += `&search=${search}`;
        // }
       
        return {
          url: url,
          method: "GET",
        };
      },
    }),
    
    exportNotify: builder.query<CommonResponseType & { data: any }, {}>({
        query: ({ }) => {
          let url = END_POINTS.notifyExport;
        
         
          return {
            url: url,
            method: "GET",
          };
        },
      }),
 
 
  }),
});

export const {
    useLazyExportNotifyQuery,
    useLazyGetAllNotifyEmailQuery
} = emailApi;
