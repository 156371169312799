import {
  Drawer,
  Toolbar,
  List,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Box,
  IconButton,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import AddHomeWorkIcon from '@mui/icons-material/AddHomeWork';
import MergeTypeIcon from '@mui/icons-material/MergeType';
import SummarizeTwoToneIcon from "@mui/icons-material/SummarizeTwoTone";
import CloseIcon from "@mui/icons-material/Close";
import DashboardIcon from "@mui/icons-material/Dashboard";
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import PeopleIcon from "@mui/icons-material/People";
import BarChartIcon from "@mui/icons-material/BarChart";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import MessageIcon from '@mui/icons-material/Message';
import { SIDEBAR_WIDTH } from "../constants";
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ReceiptIcon from '@mui/icons-material/Receipt';
import BusinessIcon from '@mui/icons-material/Business';
import { PropsWithoutRef, useEffect, useRef, useState } from "react";
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import ThunderstormIcon from '@mui/icons-material/Thunderstorm';
import SettingsIcon from '@mui/icons-material/Settings';
import ApartmentIcon from '@mui/icons-material/Apartment';
import PostAddIcon from '@mui/icons-material/PostAdd';
import EmailIcon from '@mui/icons-material/Email';
import CollapseIcon from '@mui/icons-material/ChevronLeft';
import MissedVideoCallIcon from '@mui/icons-material/MissedVideoCall';
interface Props {
  mobileOpen: boolean;
  handleSidebarToggle: () => void;
}

export default function Sidebar({
  mobileOpen,
  handleSidebarToggle,
}: PropsWithoutRef<Props>) {
  const location = useLocation();
  const navigate = useNavigate();
  const selectedRef = useRef<HTMLDivElement | null>(null)
  const [open, setOpen] = useState<boolean>(false);
  const [isSelected, setIsSelected] = useState("")
  const drawer = (
    <>
      <Toolbar className="sidebar-logo" sx={{ position: "relative" }}>
        <figure className="logo_cntnr" >
          <img src="/static/images/logo.png" alt="" />
        </figure>
        {/* <h1 style={{color: 'white', marginLeft:50}}>ZAID</h1> */}
        <IconButton
          sx={{
            position: "absolute",
            top: "10px",
            right: "10px",
            color: "white",
            display: { lg: "none" },
          }}
          onClick={handleSidebarToggle}
        >
          <CloseIcon />
        </IconButton>
      </Toolbar>

      <List sx={{ flexGrow: 1 }} className="sidebr-lst">
        <ListItemButton
          className="lst-itm"
          selected={location.pathname === "/dashboard"}
          onClick={() => navigate("/dashboard")}
        >
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText className="lstitm-txt" primary="Dashboard" />
        </ListItemButton>
        {/* users */}
        <ListItemButton
          className="lst-itm"
          selected={
            location.pathname === "/manage-users" ||
            location.pathname.startsWith("/manage-users/details") ||
            location.pathname.startsWith("/manage-users/edit") ||
            location.pathname.startsWith("/manage-users/usersform") ||
            location.pathname.startsWith("/manage-users/token/details") ||
            location.pathname.startsWith("/manage-users/property/details")
          }
          onClick={() => navigate("/manage-users")}
        >
          <ListItemIcon>
            <PeopleIcon />
          </ListItemIcon>
          <ListItemText className="lstitm-txt" primary="Manage Users" />
        </ListItemButton>

        <ListItemButton
          className="lst-itm"
          selected={
            location.pathname === "/manage-agents" ||
            location.pathname.startsWith("/manage-agents/details") ||
            location.pathname.startsWith("/manage-reels/agents") ||
            location.pathname.startsWith("/manage-agents/edit") ||
            location.pathname.startsWith("/manage-agents/add") ||
            location.pathname.startsWith("/manage-agents/edit/chat") ||
            location.pathname.startsWith("/manage-agent/token/details") ||
            location.pathname.startsWith("/manage-agents/property/details") ||
            location.pathname.startsWith("/manage-agents/property-listing/details")
          }
          onClick={() => navigate("/manage-agents")}
        >
          <ListItemIcon>
            <SupportAgentIcon />
          </ListItemIcon>
          <ListItemText className="lstitm-txt" primary="Manage Agents" />
        </ListItemButton>
        {/* company */}
        <ListItemButton
          className="lst-itm"
          selected={
            location.pathname === "/manage-company" ||
            location.pathname.startsWith("/manage-reels/company") ||
            location.pathname.startsWith("/manage-company/details") ||
            location.pathname.startsWith("/manage-company/edit") ||
            location.pathname.startsWith("/manage-company/add") ||
            location.pathname.startsWith("/manage-company/edit/chat") ||
            location.pathname.startsWith("/company/property-listing/details") ||
            location.pathname.startsWith("manage-users/property/details/ChatInbox")

          }
          onClick={() => navigate("/manage-company")}
        >
          <ListItemIcon>
            <ApartmentIcon />
          </ListItemIcon>
          <ListItemText className="lstitm-txt" primary="Manage Company" />
        </ListItemButton>
        {/* ads */}
        <ListItemButton
          className="lst-itm"
          selected={
            location.pathname === "/manage-ads" ||
            location.pathname.startsWith("/manage-ads/details") ||
            location.pathname.startsWith("/manage-ads/edit") ||
            location.pathname.startsWith("/manage-ads/add")
          }
          onClick={() => navigate("/manage-ads")}
        >
          <ListItemIcon>
            <BusinessIcon />
          </ListItemIcon>
          <ListItemText className="lstitm-txt" primary="Manage Ads" />
        </ListItemButton>
        {/* banner */}
        <ListItemButton
          className="lst-itm"
          selected={
            location.pathname === "/manage-banners/" ||
            location.pathname === "/manage-banners" ||
            location.pathname.startsWith("/manage-banners/details") ||
            location.pathname.startsWith("/manage-banners/edit") ||
            location.pathname.startsWith("/manage-banners/add")
          }
          onClick={() => navigate("/manage-banners")}
        >
          <ListItemIcon>
            <PostAddIcon />
          </ListItemIcon>
          <ListItemText className="lstitm-txt" primary="Manage Banners" />
        </ListItemButton>
        {/* category */}
        <ListItemButton
          className="lst-itm"
          selected={
            location.pathname === "/manage-categories" ||
            location.pathname.startsWith("/manage-categories/details") ||
            location.pathname.startsWith("/manage-categories/edit") ||
            location.pathname.startsWith("/manage-categories/add")
          }
          onClick={() => navigate("/manage-categories")}
        >
          <ListItemIcon>
            <ReceiptIcon />
          </ListItemIcon>
          <ListItemText className="lstitm-txt" primary=" Manage Categories" />
        </ListItemButton>
        {/* reels */}
        <ListItemButton
          className="lst-itm"
          selected={
            location.pathname === "/manage-reels" ||
            location.pathname.startsWith("/manage-reels/details") ||
            location.pathname.startsWith("/manage-reels/edit") ||
            location.pathname.startsWith("/manage-reels/add")
          }
          onClick={() => navigate("/manage-reels")}
        >
          <ListItemIcon>
            <MissedVideoCallIcon />
          </ListItemIcon>
          <ListItemText className="lstitm-txt" primary=" Manage Reels" />
        </ListItemButton>
        {/* amenities */}
        <ListItemButton
          className="lst-itm"
          selected={
            location.pathname === "/manage-amenities" ||
            location.pathname.startsWith("/manage-amenities/details") ||
            location.pathname.startsWith("/manage-amenities/edit") ||
            location.pathname.startsWith("/manage-amenities/add")
          }
          onClick={() => navigate("/manage-amenities")}
        >
          <ListItemIcon>
            <AddHomeWorkIcon />
          </ListItemIcon>
          <ListItemText className="lstitm-txt" primary=" Manage Amenities" />
        </ListItemButton>
        {/* amenities */}
        {/* property type */}
        <ListItemButton
          className="lst-itm"
          selected={
            location.pathname === "/manage-propertyType" ||
            location.pathname.startsWith("/manage-propertyType/details") ||
            location.pathname.startsWith("/manage-propertyType/edit") ||
            location.pathname.startsWith("/manage-propertyType/add")
          }
          onClick={() => navigate("/manage-propertyType")}
        >
          <ListItemIcon>
            <MergeTypeIcon />
          </ListItemIcon>
          <ListItemText className="lstitm-txt" primary=" Manage Property Type" />
        </ListItemButton>
        {/* pwhc23hrc */}
        <ListItemButton
          className="lst-itm"
          selected={
            location.pathname === "/property-listing" ||
            location.pathname.startsWith("/property-listing/details") ||
            location.pathname.startsWith("/property-listing/edit") ||
            location.pathname.startsWith("/property-listing/add")
          }
          onClick={() => navigate("/property-listing")}
        >
          <ListItemIcon>
            <CalendarTodayIcon />
          </ListItemIcon>
          <ListItemText className="lstitm-txt" primary="Manage Property Listing  " />
        </ListItemButton>



        <ListItemButton
          className="lst-itm"
          selected={
            location.pathname === "/manage-subscription" ||
            location.pathname.startsWith("/manage-subscription/details") ||
            location.pathname.startsWith("/manage-subscription/edit") ||
            location.pathname.startsWith("/manage-subscription/add")
          }
          onClick={() => navigate("/manage-subscription")}
        >
          <ListItemIcon>
            <SubscriptionsIcon />
          </ListItemIcon>
          <ListItemText className="lstitm-txt" primary="Manage Subscription" />
        </ListItemButton>
        {/* chat */}
        <ListItemButton
          className="lst-itm"
          selected={
            location.pathname === "/manage-chat" ||
            location.pathname.startsWith("/manage-chat/details") ||
            location.pathname.startsWith("/manage-chat/edit") ||
            location.pathname.startsWith("/manage-chat/add")
          }
          onClick={() => navigate("/manage-chat")}
        >
          <ListItemIcon>
            <MessageIcon />
          </ListItemIcon>
          <ListItemText className="lstitm-txt" primary="Manage Chat" />
        </ListItemButton>
        {/* revenue */}
        <ListItemButton
          className="lst-itm"
          selected={
            location.pathname === "/manage-revenue" ||
            location.pathname.startsWith("/manage-revenue/details") ||
            location.pathname.startsWith("/manage-revenue/edit") ||
            location.pathname.startsWith("/manage-revenue/add")
          }
          onClick={() => navigate("/manage-revenue")}
        >
          <ListItemIcon>
            <AttachMoneyIcon />
          </ListItemIcon>
          <ListItemText className="lstitm-txt" primary="Manage Revenue" />
        </ListItemButton>




        <ListItemButton
          className="lst-itm"
          selected={
            location.pathname === "/manage-boost" ||
            location.pathname.startsWith("/manage-boost/details") ||
            location.pathname.startsWith("/manage-boost/edit") ||
            location.pathname.startsWith("/manage-boost/add")
          }
          onClick={() => navigate("/manage-boost")}
        >
          <ListItemIcon>
            <ThunderstormIcon />
          </ListItemIcon>
          <ListItemText className="lstitm-txt" primary="Manage Boost Plan" />
        </ListItemButton>
        {/* workout videos management */}


        <ListItemButton
          className="lst-itm"
          selected={location.pathname === "/manage-emails"
          }
          onClick={() => navigate("/manage-emails")}
        >
          <ListItemIcon>
            <EmailIcon />
          </ListItemIcon>
          <ListItemText className="lstitm-txt" primary="Subscribed Emails" />
        </ListItemButton>



        <ListItemButton
          className="lst-itm"
          selected={location.pathname === "/manage-notifications"
            || location.pathname.startsWith("/manage-notifications/add")
            || location.pathname.startsWith("/recieved-notifications")}
          onClick={() => navigate("/manage-notifications")}
        >
          <ListItemIcon>
            <NotificationsActiveIcon />
          </ListItemIcon>
          <ListItemText className="lstitm-txt" primary=" Notification Management" />
        </ListItemButton>
        <ListItemButton
          className="lst-itm"
          selected={location.pathname === "/analytics"}
          onClick={() => navigate("/analytics")}
        >
          <ListItemIcon>
            <BarChartIcon />
          </ListItemIcon>
          <ListItemText
            className="lstitm-txt"
            primary="Analytics"
          />
        </ListItemButton>
        <ListItemButton
          className="lst-itm"
          selected={location.pathname === "/cms"}
          onClick={() => navigate("/cms")}

        >
          <ListItemIcon ref={isSelected?.includes("/cms") ? selectedRef : null}>
            <SummarizeTwoToneIcon />
          </ListItemIcon>
          <ListItemText className="lstitm-txt" primary="Manage CMS" />
        </ListItemButton>
        {/* settings */}
        <ListItemButton
          className="lst-itm"
          selected={location.pathname === "/settings"}
          onClick={() => navigate("/settings")}
        >
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText className="lstitm-txt" primary="Settings" />
        </ListItemButton>

      </List>
    </>
  );

  useEffect(() => {
    console.log(selectedRef, "useefff")
    if (selectedRef.current) {
      console.log("helooooooooooooo,red")
      selectedRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        // inline:"center"
      });
    }
    setIsSelected(location.pathname)
  }, [location.pathname, isSelected])

  useEffect(() => {
    if (selectedRef.current) {
      console.log("helooooooooooooo,red")
      selectedRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        // inline:"center"
      });
    }
  }, [selectedRef])

  return (
    <Box
      component="nav"
      sx={{ flexShrink: { lg: 0 } }}
      className="sidebar-main-div"
    >
      <Drawer
        className="sidebar_drawer"
        variant="temporary"
        open={mobileOpen}
        onClose={handleSidebarToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: "block", lg: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: SIDEBAR_WIDTH,
          },
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        className="sidebar_drawer"

        sx={{
          display: { xs: "none", lg: "block" },
          "& .MuiDrawer-paper": {
            width: SIDEBAR_WIDTH,
            boxSizing: "border-box",
          },
        }}
        open
      >
        {drawer}
      </Drawer>
    </Box>
  );
}
