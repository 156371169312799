import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import MainContainer from "../../layout/MainContainer";
import { useLazyGetSpecificCategoryByIdQuery } from "../../services/category";
import Loader from "../../helpers/constants/Loader";
import Pagination from "../../components/Pagination";

const CategoryDetails = () => {
  const { _id } = useParams();
  const [categoryDetails, setCategoryDetails] = useState<any>({});
  const [fetchApi, { isLoading }] = useLazyGetSpecificCategoryByIdQuery();
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [properties, setProperties] = useState<any[]>([]);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const [limit, setLimit] = useState<number>(10);
  let totalPages = Math.ceil(totalCount / limit);
  const fetchDataById = async (_id: any, page: number) => {
    try {
      const response = await fetchApi({ userId: _id, page: page, limit: limit }).unwrap();
      if (response.statusCode === 200) {
        setCategoryDetails(response?.data);
        setProperties(response?.data?.properties[0]?.properties || []);
        setTotalCount(response?.data?.properties[0]?.totalCount);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchDataById(_id, page);
  }, [_id, page,limit]);

  const navigate = useNavigate();

  return (
    <MainContainer>
      <Loader isLoad={isLoading} />
      <div className="main_layout">
        <div className="dashboard" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <h1 className="mn_hdng">Category Details</h1>
          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/manage-categories");
            }}
          >
            Back
          </Button>
        </div>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            mb: 2,
            gap: 2,
            flexDirection: 'column'
          }}
        >
          {categoryDetails.image && (
            <img
              src={categoryDetails.image}
              alt="Category"
              style={{ width: "100px", height: "100px", objectFit: 'cover' }}
            />
          )}
          <Typography variant="h5">Category Name: {categoryDetails.name}</Typography>
        </Box>
        <Card className="cards">
          <TableContainer className="table_container">
            <Box className="heading"></Box>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">S.No</TableCell>
                  <TableCell>Images</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Area</TableCell>
                  <TableCell>Key Feature</TableCell>
                  <TableCell>Specifications</TableCell>
                  <TableCell>Address</TableCell>
                  <TableCell>Description</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {properties.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={8} align="center">
                      No property in this category
                    </TableCell>
                  </TableRow>
                ) : (
                  properties.map((property: any, i: any) => (
                    <TableRow key={property._id}>
                      <TableCell align="center">
                        {(page - 1) * 10 + i + 1}
                      </TableCell>
                      <TableCell>
                        {property?.images?.length
                          ? <img
                            src={property.images[0]}
                            alt={`Property ${i + 1}`}
                            style={{ width: "50px", height: "50px" }}
                          />
                          : "-"}
                      </TableCell>
                      <TableCell>{property?.name || "-"}</TableCell>
                      <TableCell>{property?.area || "-"}</TableCell>
                      <TableCell>{property?.keyFeature || "-"}</TableCell>
                      <TableCell>{property?.specifications || "-"}</TableCell>
                      <TableCell>{property?.address || "-"}</TableCell>
                      <TableCell>{property?.description || "-"}</TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </div>
      {totalCount > 0 ? (
        <Pagination
          setPage={setPage}
          module={categoryDetails}
          page={page}
          onPageChange={onPageChange}
          totalPages={totalPages}
          limit={limit}
          setLimit={setLimit}
        />
      ) : (
        ""
      )}
    </MainContainer>
  );
};

export default CategoryDetails;
