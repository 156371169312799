import React, { Dispatch, SetStateAction } from "react";
import { Box, Button, Modal, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { generateResponsiveStyle } from "../utils/modalStyle";

type props = {
  openLogout: boolean;
  setOpenLogout: Dispatch<SetStateAction<boolean>>;
  handleLogout: () => void;
};

const LogoutModal = ({ openLogout, setOpenLogout, handleLogout }: props) => {
  const style = generateResponsiveStyle();

  return (
    <Modal
      open={openLogout}
      onClose={() => setOpenLogout(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div style={{ position: "relative", marginTop: 20 }}>
          <div className="cross_icn_logout" style={{ paddingTop: 22 }}>
            <CloseIcon onClick={() => setOpenLogout(false)} />
          </div>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ textAlign: "center", fontSize: "19px" }}
          >
            Are you sure, you want to logout?
          </Typography>
          <div className="flexDiv" style={{ justifyContent: "center", marginTop: 20 }}>
            <Button
              sx={{
                backgroundColor: "grey",
                color: "black",
                textTransform: "capitalize",
                marginRight: 2,
                "&:hover": {
                  backgroundColor: "black",
                  color: "white",
                },
              }}
              onClick={() => {
                setOpenLogout(false);
                handleLogout();
              }}
            >
              Yes
            </Button>

            <Button
              sx={{
                backgroundColor: "grey",
                color: "black",
                textTransform: "capitalize",
                "&:hover": {
                  backgroundColor: "black",
                  color: "white",
                },
              }}
              onClick={() => setOpenLogout(false)}
            >
              No
            </Button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default LogoutModal;
