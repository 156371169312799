import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Box,
  Switch,
  Button,
  Tabs,
  Tab,

  Typography,
  InputAdornment,
  TextField,
} from "@mui/material";

import { errorToast, successToast } from "../../helpers";

import { isValidInput } from "../../utils/validation";
import SearchBar2 from "../../components/SearchBar2";
import Pagination from "../../components/Pagination";
import {
  useLazyGetSubscriptionListingQuery,
  useLazyGetAllTokenListingQuery,
  useLazyGetAllBoostProfileListingQuery,
}
  from "../../services/revenue";
import ClearIcon from '@mui/icons-material/Clear';

import {
  useLazyExportRevenueCsvSubscribeQuery,
  useLazyExportRevenueCsvTokenQuery,
  useLazyExportRevenueCsvBoostQuery
}
  from "../../services/revenue";
import Loader from "../../helpers/constants/Loader";


function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
const ManageRevenue = () => {

  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [rev1, setRev1] = useState<number>(0);
  const [rev2, setRev2] = useState<number>(0);
  const [rev3, setRev3] = useState<number>(0);
  const [exportCsv] = useLazyExportRevenueCsvSubscribeQuery();
  const [exportCsv2] = useLazyExportRevenueCsvBoostQuery();
  const [exportCsv3] = useLazyExportRevenueCsvTokenQuery();
  const [page, setPage] = useState(1);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    setPage(1);
  };
  const today = new Date().toISOString().split('T')[0];
  const [isLoading, setISLoading] = useState<boolean>(false)
  const [totalCount, setTotalCount] = useState<number>(0);
  const [totalCount2, setTotalCount2] = useState<number>(0);
  const [totalCount3, setTotalCount3] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [startDateSend, setStartDateSend] = useState<string>("");
  const [endDateSend, setEndDateSend] = useState<string>("");
  let totalPages = Math.ceil(totalCount / limit);
  let totalPages2 = Math.ceil(totalCount2 / 10);
  let totalPages3 = Math.ceil(totalCount3 / 10);
  const [subscriptionRows, setSubscriptionRows] = useState<any>([]);
  const [boostProfileRows, setboostProfileRows] = useState<any>([]);
  const [tokenRows, setTokenRows] = useState<any>([]);
  const [value, setValue] = React.useState(0);
  const formatDate = (dateString: string | number | Date) => {
    if (!dateString) return "-";
    const date = new Date(dateString);
    return date.toLocaleDateString();
  };
  const [subscriptionRevenue] = useLazyGetSubscriptionListingQuery();

  const [boostProfile] = useLazyGetAllBoostProfileListingQuery();

  const [tokenBasedSubscribption] = useLazyGetAllTokenListingQuery();

  const onPageChange1 = (newPage: number) => {
    setPage(newPage);
    setSubscriptionRows([]);
  };
  const onPageChange2 = (newPage: number) => {
    setPage(newPage);
    setSubscriptionRows([]);
  };
  const onPageChange3 = (newPage: number) => {
    setPage(newPage);
    setTokenRows([]);
  };

  const handleDateChange = (event: any) => {
    setStartDate(event.target.value)
    const selectedDate = new Date(event.target.value);
    if (!isNaN(selectedDate.getTime())) {
      const utcDate = new Date(Date.UTC(selectedDate.getUTCFullYear(), selectedDate.getUTCMonth(), selectedDate.getUTCDate(), 0, 0, 0));

      setStartDateSend(utcDate.toISOString());
    } else {
      console.error("Invalid date selected");
    }
  };

  const handleDateChange1 = (event: any) => {
    setEndDate(event.target.value)
    const selectedDate = new Date(event.target.value);
    if (!isNaN(selectedDate.getTime())) {
      const utcDate = new Date(Date.UTC(selectedDate.getUTCFullYear(), selectedDate.getUTCMonth(), selectedDate.getUTCDate(), 0, 0, 0));

      setEndDateSend(utcDate.toISOString());
    } else {
      console.error("Invalid date selected");
    }
  };



  const fetchsubscriptionListing = async (page: number, search: string) => {
    setISLoading(true)
    try {
      const response = await subscriptionRevenue({ page: page, search: search, limit: limit, startDate: startDateSend, endDate: endDateSend }).unwrap();
      setISLoading(false)
      if (response?.statusCode === 200) {
        setSubscriptionRows(response?.data?.details || []);
        setRev1(response?.data?.totalRev || 0);
        setTotalCount(response?.data?.totalCount || 0);
      }
    }
    catch (e: any) {
      setISLoading(false)
      errorToast(e?.message);
    }
  }
  const fetchBoostListing = async (page: number, search: string) => {
    setISLoading(true)
    try {
      const response = await boostProfile({ page: page, search: search, limit: limit, startDate: startDateSend, endDate: endDateSend }).unwrap();
      setISLoading(false)
      if (response?.statusCode === 200) {
        setboostProfileRows(response?.data?.details || []);
        setRev2(response?.data?.totalRev || 0);
        setTotalCount2(response?.data?.totalCount || 0);
      }
    }
    catch (e: any) {
      setISLoading(false)
      errorToast(e?.message);
    }
  }
  const fetchTokenListing = async (page: number, search: string) => {
    setISLoading(true)
    try {
      const response = await tokenBasedSubscribption({ page: page, search: search, limit: limit, startDate: startDateSend, endDate: endDateSend }).unwrap();
      setISLoading(false)
      if (response?.statusCode === 200) {
        setTokenRows(response?.data?.details || []);
        setRev3(response?.data?.totalRev || 0);
        setTotalCount3(response?.data?.totalCount || 0);
      }
    }
    catch (e: any) {
      setISLoading(false)
      errorToast(e?.message);
    }
  }
  useEffect(() => {
    if (value === 0) {
      fetchsubscriptionListing(page, debouncedSearchTerm)
    }
    else if (value === 1) {
      fetchBoostListing(page, debouncedSearchTerm)
    }
    else if (value === 2) {
      fetchTokenListing(page, debouncedSearchTerm)
    }
  }, [value, page, debouncedSearchTerm, limit, startDate, endDate]);

  const handleExportCsv = async () => {
    try {
      const res = await exportCsv({

      }).unwrap();
      console.log("eugded", res);




      if (res?.statusCode === 200) {
        window.open(res?.data);
        successToast("CSV exported successfully")
      } else {

        errorToast("Error: s3Location not found in response");
      }
    } catch (error: any) {
      errorToast(error?.message);
    }
  };
  const handleExportCsv2 = async () => {
    try {
      const res = await exportCsv2({

      }).unwrap();
      console.log("eugded", res);




      if (res?.statusCode === 200) {
        window.open(res?.data);
        successToast("CSV exported successfully")
      } else {

        errorToast("Error: s3Location not found in response");
      }
    } catch (error: any) {
      errorToast(error?.message);
    }
  };
  const handleExportCsv3 = async () => {
    try {
      const res = await exportCsv3({

      }).unwrap();
      console.log("eugded", res);




      if (res?.statusCode === 200) {
        window.open(res?.data);
        successToast("CSV exported successfully")
      } else {

        errorToast("Error: s3Location not found in response");
      }
    } catch (error: any) {
      errorToast(error?.message);
    }
  };

  function formatRevenue(labelValue: number) {
    // Nine Zeroes for Billions
    return Math.abs(Number(labelValue)) >= 1.0e9
      ? (Math.abs(Number(labelValue)) / 1.0e9).toFixed(2) + " B"
      : // Six Zeroes for Millions
      Math.abs(Number(labelValue)) >= 1.0e6
        ? (Math.abs(Number(labelValue)) / 1.0e6).toFixed(2) + " M"
        : // Three Zeroes for Thousands
        Math.abs(Number(labelValue)) >= 1.0e3
          ? (Math.abs(Number(labelValue)) / 1.0e3).toFixed(2) + " K"
          : Math.abs(Number(labelValue));
  }


  return (
    <MainContainer>
      <Loader isLoad={isLoading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Manage Revenue</h1>
        </div>
        <Card className="cards">
          <Box className="custom_tabs">
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="basic tabs example"
              className="custom_tabs_links"
            >
              <Tab label="Subscription" {...a11yProps(0)} />
              <Tab label="Boost Plan" {...a11yProps(1)} />
              <Tab label="Token" {...a11yProps(2)} />
            </Tabs>
            <CustomTabPanel value={value} index={0}>
              <Box className="cards_header">
                <SearchBar2
                  value={searchTerm}
                  searchTerm={searchTerm}
                  onCross={() => setSearchTerm("")}
                  setDebouncedSearchTerm={setDebouncedSearchTerm}
                  onChange={(val: any) => {
                    if (isValidInput(val.target.value)) {
                      setSearchTerm(val.target.value);
                    }
                  }}
                />


                <Box className="cards_header_right">

                  <Button
                    className="btn btn_primary"
                    onClick={handleExportCsv}
                  >
                    <FileDownloadIcon /> Export CSV
                  </Button>
                </Box>
              </Box>
              <Box className="crds_date" >
                <div style={{ display: "flex", marginTop: "10px" }}>
                  <div style={{ marginRight: "10px" }} >
                    <Typography>Start Date</Typography>
                    <TextField
                      // hiddenTypography
                      type="date"
                      name="startDate"
                      variant="outlined"
                      fullWidth
                      placeholder="Start Time"
                      className="text_field"
                      inputProps={{ maxLength: 30, max: today }}
                      sx={{ maxWidth: "260px" }}
                      value={startDate}
                      onChange={handleDateChange}
                      InputProps={{
                        endAdornment: (
                          startDate && (
                            <InputAdornment position="end">
                              <IconButton onClick={() => { setStartDate(""); setStartDateSend("") }}>
                                <ClearIcon />
                              </IconButton>
                            </InputAdornment>
                          )
                        ),
                      }}
                    />
                  </div>
                  <div style={{ marginRight: "10px" }}>

                    <Typography>End Date</Typography>
                    <TextField
                      hiddenLabel
                      type="date"
                      name="startDate"
                      variant="outlined"
                      fullWidth
                      placeholder="End Time"
                      className="text_field"
                      sx={{ maxWidth: "260px" }}
                      inputProps={{ maxLength: 30, max: today }}
                      value={endDate}
                      onChange={handleDateChange1}
                      InputProps={{
                        endAdornment: (
                          endDate && (
                            <InputAdornment position="end">
                              <IconButton onClick={() => { setEndDateSend(""); setEndDate("") }}>
                                <ClearIcon />
                              </IconButton>
                            </InputAdornment>
                          )
                        ),
                      }}

                    />
                  </div>

                  <h3 style={{
                    marginLeft: "auto",
                    marginTop: "30px"
                  }}>
                    Total Revenue :AED {" " + rev1 ? formatRevenue(rev1) : "0"}
                  </h3>
                </div>

              </Box>
              <TableContainer className="table_container">
                <Box className="heading"></Box>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">S.No</TableCell>
                      <TableCell>User Name</TableCell>
                      <TableCell>Supscription Name</TableCell>
                      <TableCell>Price</TableCell>
                      <TableCell>Subscription Start Date</TableCell>
                      <TableCell>Subscription End Date</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {subscriptionRows.length === 0 ? (
                      <TableRow>
                        <TableCell colSpan={6} align="center">
                          No revenue found in subscription
                        </TableCell>
                      </TableRow>
                    ) : (
                      subscriptionRows.map((row: any, i: any) => (
                        <TableRow key={row.name}>
                          <TableCell align="center">
                            {(page - 1) * 10 + i + 1}
                          </TableCell>
                          <TableCell>{row?.userName || "-"}</TableCell>

                          <TableCell>{row?.subscriptionName || "-"}</TableCell>
                          <TableCell>AED {" " + row?.price || "-"}</TableCell>
                          <TableCell>{formatDate(row?.subscriptionStartDate) || "-"}</TableCell>
                          <TableCell>{formatDate(row?.subscriptionEndDate) || "-"}</TableCell>


                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              {subscriptionRows?.length > 0 ? (
                <Pagination
                  setPage={setPage}
                  module={subscriptionRows}
                  page={page}
                  onPageChange={onPageChange1}
                  totalPages={totalPages}
                  limit={limit}
                  setLimit={setLimit}
                />
              ) : (
                ""
              )}

            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <Box className="cards_header">
                <SearchBar2
                  value={searchTerm}
                  searchTerm={searchTerm}
                  onCross={() => setSearchTerm("")}
                  setDebouncedSearchTerm={setDebouncedSearchTerm}
                  onChange={(val: any) => {
                    if (isValidInput(val.target.value)) {
                      setSearchTerm(val.target.value);
                    }
                  }}
                />
                
                <Box className="cards_header_right">

                  <Button
                    className="btn btn_primary"
                    onClick={handleExportCsv2}
                  >
                    <FileDownloadIcon /> Export CSV
                  </Button>
                </Box>
              </Box>
              <Box className="crds_date" >
                <div style={{ display: "flex", marginTop: "10px" }}>
                  <div style={{ marginRight: "10px" }} >
                    <Typography>Start Date</Typography>
                    <TextField
                      // hiddenTypography
                      type="date"
                      name="startDate"
                      variant="outlined"
                      fullWidth
                      placeholder="Start Time"
                      className="text_field"
                      inputProps={{ maxLength: 30, max: today }}
                      sx={{ maxWidth: "260px" }}
                      value={startDate}
                      onChange={handleDateChange}
                      InputProps={{
                        endAdornment: (
                          startDate && (
                            <InputAdornment position="end">
                              <IconButton onClick={() => { setStartDate(""); setStartDateSend("") }}>
                                <ClearIcon />
                              </IconButton>
                            </InputAdornment>
                          )
                        ),
                      }}
                    />
                  </div>
                  <div style={{ marginRight: "10px" }}>

                    <Typography>End Date</Typography>
                    <TextField
                      hiddenLabel
                      type="date"
                      name="startDate"
                      variant="outlined"
                      fullWidth
                      placeholder="End Time"
                      className="text_field"
                      sx={{ maxWidth: "260px" }}
                      inputProps={{ maxLength: 30, max: today }}
                      value={endDate}
                      onChange={handleDateChange1}
                      InputProps={{
                        endAdornment: (
                          endDate && (
                            <InputAdornment position="end">
                              <IconButton onClick={() => { setEndDateSend(""); setEndDate("") }}>
                                <ClearIcon />
                              </IconButton>
                            </InputAdornment>
                          )
                        ),
                      }}

                    />
                  </div>

                  <h3 style={{
                    marginLeft: "auto",
                    marginTop: "30px"
                  }}>
                    Total Revenue :AED {" " + rev2 ? formatRevenue(rev2) : "0"}
                  </h3>
                </div>

              </Box>
              <TableContainer className="table_container">
                <Box className="heading"></Box>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">S.No</TableCell>
                      <TableCell>User Name</TableCell>
                      <TableCell>Boost Name</TableCell>
                      <TableCell>Boost Start Date</TableCell>
                      <TableCell>Boost End Date</TableCell>
                      <TableCell>Price</TableCell>

                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {boostProfileRows.length === 0 ? (
                      <TableRow>
                        <TableCell colSpan={6} align="center">
                          No revenue found in boost profile
                        </TableCell>
                      </TableRow>
                    ) : (
                      boostProfileRows.map((row: any, i: any) => (
                        <TableRow key={row.name}>
                          <TableCell align="center">
                            {(page - 1) * 10 + i + 1}
                          </TableCell>
                          <TableCell>{row?.userName || "-"}</TableCell>

                          <TableCell>{row?.boostProfileName || "-"}</TableCell>
                          <TableCell>{formatDate(row?.boostProfileStartDate) || "-"}</TableCell>
                          <TableCell>{formatDate(row?.boostProfileEndDate) || "-"}</TableCell>
                          <TableCell>{row?.price || "-"}</TableCell>


                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              {boostProfileRows?.length > 0 ? (
                <Pagination
                  setPage={setPage}
                  module={boostProfileRows}
                  page={page}
                  onPageChange={onPageChange2}
                  totalPages={totalPages2}
                  limit={limit}
                  setLimit={setLimit}
                />
              ) : (
                ""
              )}

            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <Box className="cards_header">
                <SearchBar2
                  value={searchTerm}
                  searchTerm={searchTerm}
                  onCross={() => setSearchTerm("")}
                  setDebouncedSearchTerm={setDebouncedSearchTerm}
                  onChange={(val: any) => {
                    if (isValidInput(val.target.value)) {
                      setSearchTerm(val.target.value);
                    }
                  }}
                />
               
                <Box className="cards_header_right">

                  <Button
                    className="btn btn_primary"
                    onClick={handleExportCsv3}
                  >
                    <FileDownloadIcon /> Export CSV
                  </Button>
                </Box>
              </Box>
              <Box className="crds_date" >
                <div style={{ display: "flex", marginTop: "10px" }}>
                  <div style={{ marginRight: "10px" }} >
                    <Typography>Start Date</Typography>
                    <TextField
                      // hiddenTypography
                      type="date"
                      name="startDate"
                      variant="outlined"
                      fullWidth
                      placeholder="Start Time"
                      className="text_field"
                      inputProps={{ maxLength: 30, max: today }}
                      sx={{ maxWidth: "260px" }}
                      value={startDate}
                      onChange={handleDateChange}
                      InputProps={{
                        endAdornment: (
                          startDate && (
                            <InputAdornment position="end">
                              <IconButton onClick={() => { setStartDate(""); setStartDateSend("") }}>
                                <ClearIcon />
                              </IconButton>
                            </InputAdornment>
                          )
                        ),
                      }}
                    />
                  </div>
                  <div style={{ marginRight: "10px" }}>

                    <Typography>End Date</Typography>
                    <TextField
                      hiddenLabel
                      type="date"
                      name="startDate"
                      variant="outlined"
                      fullWidth
                      placeholder="End Time"
                      className="text_field"
                      sx={{ maxWidth: "260px" }}
                      inputProps={{ maxLength: 30, max: today }}
                      value={endDate}
                      onChange={handleDateChange1}
                      InputProps={{
                        endAdornment: (
                          endDate && (
                            <InputAdornment position="end">
                              <IconButton onClick={() => { setEndDateSend(""); setEndDate("") }}>
                                <ClearIcon />
                              </IconButton>
                            </InputAdornment>
                          )
                        ),
                      }}

                    />
                  </div>

                  <h3 style={{
                    marginLeft: "auto",
                    marginTop: "30px"
                  }}>
                    Total Revenue :AED {" " + rev3 ? formatRevenue(rev3) : "0"}
                  </h3>
                </div>

              </Box>
              <TableContainer className="table_container">
                <Box className="heading"></Box>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">S.No</TableCell>
                      <TableCell>User Name</TableCell>
                      <TableCell>Tokens Used</TableCell>
                      <TableCell>Price</TableCell>
                      <TableCell>Transaction Id</TableCell>


                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tokenRows.length === 0 ? (
                      <TableRow>
                        <TableCell colSpan={6} align="center">
                          No revenue found in Token
                        </TableCell>
                      </TableRow>
                    ) : (
                      tokenRows.map((row: any, i: any) => (
                        <TableRow key={row.name}>
                          <TableCell align="center">
                            {(page - 1) * 10 + i + 1}
                          </TableCell>
                          <TableCell>{row?.userName || "-"}</TableCell>
                          <TableCell>{row?.token || "0"}</TableCell>
                          <TableCell>{row?.price || "-"}</TableCell>
                          <TableCell>{row?.transactionNo || "-"}</TableCell>


                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              {tokenRows?.length > 0 ? (
                <Pagination
                  setPage={setPage}
                  module={tokenRows}
                  page={page}
                  onPageChange={onPageChange3}
                  totalPages={totalPages3}
                  limit={limit}
                  setLimit={setLimit}
                />
              ) : (
                ""
              )}

            </CustomTabPanel>
          </Box>
        </Card>
      </div >
    </MainContainer >
  );
};

export default ManageRevenue;
