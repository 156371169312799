import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useAddSettingsMutation, useLazyGetAllSettingsQuery } from "../../services/settings";
import { errorToast, successToast } from "../../helpers";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";
import { isNumber } from "../../utils/validation";
import { useLazyGetpropertYTypeListingQuery } from "../../services/property";

const Settings = () => {
  const [getData, setGetData] = useState<any>([]);
  const [addSettings] = useAddSettingsMutation();
  const [getSettings] = useLazyGetAllSettingsQuery();
  const [propertyTypeList] = useLazyGetpropertYTypeListingQuery();
  const [propertyTypeId, setPropertyType] = useState<
    {
      propertyTypeId: any; _id: string; name: string
    }[]
  >([]);

  console.log(propertyTypeId, "--00000")
  const handlePropertyTypeChange = (event: SelectChangeEvent) => {
    const selectedPropertyTypeId = event.target?.value;
    formik.setFieldValue("propertyTypeId", selectedPropertyTypeId);

    const selectedProperty = getData.find(
      (item: any) => item?.propertyTypeId?._id === selectedPropertyTypeId
    );

    if (selectedProperty) {
      formik.setFieldValue("tokenPrice", selectedProperty?.tokenPrice);
      formik.setFieldValue("required", selectedProperty?.token);

    }
  };

  const fetchPropertyType = async () => {
    try {
      const response = await propertyTypeList({}).unwrap();
      setPropertyType(response?.data || []);
    } catch (e: any) {
      errorToast(e?.message);
    }
  };

  const validationSchema = Yup.object().shape({
    tokenPrice: Yup.string().max(10, "At most 10 characters can be filled").required("This field is required"),
    // required: Yup.string().max(10, "At most 10 characters can be filled").required("This field is required"),
    // propertyTypeId: Yup.string().required("Property Type is required"),
  });

  const formik = useFormik({
    initialValues: {
      tokenPrice: "",
      // required: "",
      // propertyTypeId: '',
      beds: "",
      baths: ""
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const body = {
        tokenPrice: values?.tokenPrice,
        beds: values?.beds,
        baths: values?.baths,
        // propertyTypeId: values?.propertyTypeId,
        // token: values?.required,
      };

      try {
        const encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
        let response = await addSettings(encryptedBody).unwrap();
        console.log("API response:", response);
        if (response?.statusCode === 200) {
          successToast(response?.message || "Settings Updated Successfully");
        } else {
          errorToast(response?.message);
        }
      } catch (e: any) {
        console.error("API call failed:", e);
        errorToast(e?.message);
      }
    },
  });

  const fetchGetApi = async () => {
    try {
      let response = await getSettings({}).unwrap();
      if (response?.statusCode === 200) {
        setGetData(response?.data || []);
        const initialData = response?.data[1];
        console.log("initialData", initialData);
        if (initialData) {
          formik.setFieldValue("tokenPrice", initialData?.tokenPrice);
          formik.setFieldValue("beds", initialData?.beds || "");
          formik.setFieldValue("baths", initialData?.baths ||"");
        }
      }
    } catch (e: any) {
      console.error("Failed to fetch settings:", e);
      errorToast(e?.message);
    }
  };

  useEffect(() => {
    fetchGetApi();
    fetchPropertyType();
  }, []);

  return (
    <MainContainer>
      <div className="main_layout">
        <div className="dashboard">
          <h1 className="mn_hdng">Settings</h1>
        </div>
        <Card className="cards">
          <form onSubmit={formik.handleSubmit}>
            <CardContent sx={{ p: 1 }}>
              <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography className="custom_label">Per token price</Typography>
                  <TextField
                    hiddenLabel
                    className="text_field"
                    type="text"
                    name="tokenPrice"
                    variant="outlined"
                    fullWidth
                    placeholder="Enter Token Price"
                    onChange={formik?.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values?.tokenPrice}
                    error={formik.touched?.tokenPrice && Boolean(formik.errors.tokenPrice)}
                    helperText={formik.touched.tokenPrice && formik.errors.tokenPrice}
                  />
                </Grid>

                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <FormControl fullWidth>
                    <Typography className="custom_label">Beds</Typography>
                    <Select
                      className="select_div"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="beds"
                      value={formik.values?.beds}
                      onChange={(e) => formik.setFieldValue('beds', e.target.value)}
                      onBlur={formik.handleBlur}
                      error={formik.touched?.beds && Boolean(formik.errors?.beds)}
                      displayEmpty
                    >
                      <MenuItem value="" disabled>
                        Select
                      </MenuItem>
                      <MenuItem value="1">1</MenuItem>
                      <MenuItem value="2">2</MenuItem>
                      <MenuItem value="3">3</MenuItem>
                      <MenuItem value="4">4</MenuItem>
                      <MenuItem value="5">5</MenuItem>
                      <MenuItem value="6">6</MenuItem>
                      <MenuItem value="7">7+</MenuItem>
                    </Select>
                   
                  </FormControl>
                </Grid>


                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <FormControl fullWidth>
                    <Typography className="custom_label">Baths</Typography>
                    <Select
                      className="select_div"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="propertyTypeId"
                      value={formik.values?.baths}
                      onChange={(e) => formik.setFieldValue('baths', e.target.value)}
                      onBlur={formik.handleBlur}
                      error={formik.touched?.baths && Boolean(formik.errors?.baths)}
                      displayEmpty
                    >
                      <MenuItem value="" disabled>
                        Select
                      </MenuItem>
                      <MenuItem value="1">1</MenuItem>
                      <MenuItem value="2">2</MenuItem>
                      <MenuItem value="3">3</MenuItem>
                      <MenuItem value="4">4</MenuItem>
                      <MenuItem value="5">5</MenuItem>
                      <MenuItem value="6">6</MenuItem>
                      <MenuItem value="7">7+</MenuItem>
                    </Select>
                   
                  </FormControl>
                </Grid>


                {/* <Grid item lg={6} md={6} sm={6} xs={12}>
                  <FormControl fullWidth>
                    <Typography className="custom_label">Property Type</Typography>
                    <Select
                      className="select_div"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="propertyTypeId"
                      value={formik.values?.propertyTypeId}
                      onChange={handlePropertyTypeChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched?.propertyTypeId && Boolean(formik.errors?.propertyTypeId)}
                      displayEmpty
                    >
                      <MenuItem value="" disabled>
                        Select
                      </MenuItem>
                      {propertyTypeId?.length > 0
                        ? propertyTypeId?.map((it) => (
                          <MenuItem key={it?._id} value={it?._id}>
                            {it?.name}
                          </MenuItem>
                        ))
                        : []}
                    </Select>
                    {formik.touched.propertyTypeId && typeof formik.errors.propertyTypeId === "string" && (
                      <Typography variant="caption" color="error">
                        {formik.errors?.propertyTypeId}
                      </Typography>
                    )}
                  </FormControl>
                </Grid>

                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography className="custom_label">Token required</Typography>
                  <TextField
                    hiddenLabel
                    type="text"
                    name="required"
                    className="text_field"
                    variant="outlined"
                    fullWidth
                    placeholder="Enter number of token required"
                    onChange={(val) => {
                      if (val.target?.value === " " || val.target?.value === ".") {
                      } else if (isNumber(val.target?.value)) {
                        formik.handleChange(val);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values?.required}
                    error={formik.touched?.required && Boolean(formik.errors?.required)}
                    helperText={formik.touched?.required && formik.errors?.required}
                  />
                </Grid> */}
              </Grid>
              <div className="form_btn">
                <Button size="large" type="submit" className="btn btn_primary">
                  Save
                </Button>
              </div>
            </CardContent>
          </form>
        </Card>
      </div>
    </MainContainer>
  );
};

export default Settings;
