import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {
  Button,
  Card,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import Box from "@mui/material/Box";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SearchBar from "../../components/SearchBar";
import { useNavigate } from "react-router-dom";
import {
  usePostAddCategoryMutation,
  useLazyGetAllCategoryQuery,
  useLazyGetSpecificCategoryByIdQuery,
  useEditCategoryMutation,
  useLazyDeleteByIdCategoryQuery,
} from "../../services/category";
import { errorToast, successToast } from "../../helpers/toast";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";
import SearchBar2 from "../../components/SearchBar2";
import { isValidInput } from "../../utils/validation";
import Pagination from "../../components/Pagination";
import WarnModal from "../../components/WarnModal";
import Loader from "../../helpers/constants/Loader";
const ManageCategory = () => {
  const navigate = useNavigate();
  const [categoryStatus] = useEditCategoryMutation();
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState<number>(0);
  const onPageChange = (newPage: number) => {
    setPage(newPage);
    setRows([]);
  };
  const [totalCount, setTotalCount] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  let totalPages = Math.ceil(totalCount / limit);
  const [rows, setRows] = useState<any>([]);
  const [fetchAllCategory, { isLoading }] = useLazyGetAllCategoryQuery();
  const [deleteCategory] = useLazyDeleteByIdCategoryQuery();
  const CategoryList = async (page: number, search: string) => {
    try {
      const response = await fetchAllCategory({ page: page, search: search, limit: limit }).unwrap();
      setRows(response?.data?.category || []);
      setTotalCount(response?.data?.count);
    }
    catch (e: any) {
      errorToast(e?.message);
    }
  }
  const handleDeleteCategory = async (userId: any) => {
    try {
      const response = await deleteCategory({ userId }).unwrap();
      if (response?.statusCode === 200) {
        successToast("Category deleted successfully");
        CategoryList(page, searchTerm);
      }
    } catch (error: any) {
      console.error(error);
      errorToast(error?.message || "");
    }
  };


  useEffect(() => {
    CategoryList(page, debouncedSearchTerm)
  }, [page, debouncedSearchTerm, limit])



  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Manage Category</h1>
        </div>
        <Loader isLoad={isLoading} />
        <Card className="cards">
          <Box className="cards_header">
            <SearchBar2
              value={searchTerm}
              searchTerm={searchTerm}
              onCross={() => setSearchTerm("")}
              setDebouncedSearchTerm={setDebouncedSearchTerm}
              onChange={(val: any) => {
                if (isValidInput(val.target.value)) {
                  setSearchTerm(val.target.value);
                }
              }}
            />

            <Box className="cards_header_right">
              {/* <Button className="btn btn_primary">
                <FileDownloadIcon /> Export CSV
              </Button> */}
              <Button
                className="btn btn_primary"
                onClick={() => {
                  navigate("/manage-categories/add");
                }}
              >
                <AddIcon />
                Add Category
              </Button>
            </Box>
          </Box>
          <TableContainer className="table_container">
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">S.No</TableCell>
                  <TableCell>Image</TableCell>
                  <TableCell>Category Name</TableCell>
                  <TableCell>Tokens</TableCell>
                  {/* <TableCell>Status</TableCell> */}
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows?.length == "0" ? (<TableRow>
                  <TableCell colSpan={12} align="center">
                    No category found
                  </TableCell>
                </TableRow>) :
                  (rows.map((row: any, index: any) => (
                    <TableRow key={row._id}>
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell>
                        <figure className="user_img">
                          <img
                            style={{ borderRadius: 0 }}
                            src={row?.image ? row?.image : `row.image`}
                            alt={row?.name || "-"}
                          />
                        </figure>
                      </TableCell>
                      <TableCell>{row?.name || "-"}</TableCell>
                      <TableCell>{row?.settings?.token ||"-"}</TableCell>

                      <TableCell>
                        <Box className="table_actions">
                          <IconButton
                            onClick={() =>
                              navigate(`/manage-categories/details/${row?._id}`)
                            }
                          >
                            <VisibilityIcon />
                          </IconButton>
                          <IconButton
                            onClick={() =>
                              navigate(`/manage-categories/edit/${row?._id}`)
                            }
                          >
                            <ModeEditIcon />
                          </IconButton>
                          <IconButton onClick={() => {
                            setOpen(true);
                            setSelectedId(row?._id);
                          }}>
                            <DeleteIcon />
                          </IconButton>
                        </Box>
                      </TableCell>
                    </TableRow>
                  )))}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </div>
      {rows?.length > 0 ? (
        <Pagination
          setPage={setPage}
          module={rows}
          page={page}
          onPageChange={onPageChange}
          totalPages={totalPages}
          limit={limit}
          setLimit={setLimit}
        />
      ) : (
        ""
      )}
      <WarnModal
        open={open}
        setOpen={setOpen}
        handleDelete={() => handleDeleteCategory(selectedId)}
        name="Category"
      />
    </MainContainer>
  );
};

export default ManageCategory;
