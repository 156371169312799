import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import {
  Box,
  Button,
  Checkbox,
  Container,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
} from "@mui/material";
import "./Login.scss";
import { usePostLogInMutation } from "../../services/auth";
import {
  STORAGE_KEYS,
  errorToast,
 
 
  successToast,
} from "../../helpers";
import { setToStorage, removeFromStorage , getFromStorage,} from "../../constants/storage";
import { setCredentials } from "../../reducers/authSlice";
import { useAppDispatch } from "../../hooks/store";
import Loader from "../../helpers/constants/Loader";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";
import { firebaseCloudMessaging } from "../../utils/firebase";
import { toast } from "react-toastify";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [postLogin, { isLoading }] = usePostLogInMutation();
  const [deviceToken,setDeviceToken]=useState<any>("");
  console.log("deviceToken",deviceToken);
  const [showPassword, setShowPassword] = useState(false);
  console.log("DEVICE_TOKEN",deviceToken);
  
  // useEffect(() => {
  //   async function setToken() {
  //     try {
  //       const fcmToken = await firebaseCloudMessaging.init();
  //       if (fcmToken) {
  //         setDeviceToken(fcmToken);
  //         console.log("FCFCFCM",fcmToken);
  //       }
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   }
  //   setToken();
  // },[]);



  useEffect(() => {
    async function setToken() {
      try {
        const fcmToken = await firebaseCloudMessaging.init();
        if (fcmToken) {
          setDeviceToken(fcmToken);

          console.log(fcmToken);
        }
      } catch (error) {
        console.log(error);
      }
    }
    setToken();
    // Event listener that listens for the push notification event in the background
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.addEventListener("message", (event) => {
        try {
          event?.source?.postMessage("Hi client");
          console.log("event for the service worker", event);
          return toast(
            <div style={{ height: "100%" }}>
              <div
                style={{ color: "#820000" }}
                onClick={() => navigate("/recieved-notifications")}
              >
                { event?.data?.notification?.title}
              </div>
              <div
                style={{ color: "#1D1D1D", paddingTop: "10px" }}
                onClick={() => navigate("/recieved-notifications")}
              >
                {event?.data?.notification?.body}
              </div>
            </div>);
          return event;
        } catch (e) {
          console.log(e, "error");
          return e;
        }
      });
    }
    if ("permissions" in navigator) {
      navigator.permissions
        .query({ name: "notifications" })
        .then(function (notificationPerm) {
          notificationPerm.onchange = function () {
            if (notificationPerm.state === "granted") {
              setToken();
            }
          };
        });
    }
    return () => {
      if ("serviceWorker" in navigator) {
        navigator.serviceWorker.removeEventListener("message", (e) =>
          console.log(e)
        );
      }
    };
  }, []);




  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
    const inputEl: HTMLInputElement | null = document.getElementById(
      "password"
    ) as HTMLInputElement | null;
    if (inputEl) {
      setTimeout(() => {
        inputEl.setSelectionRange(inputEl.value.length, inputEl.value.length);
      }, 0);
    }
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      remember: false,
    },
    validationSchema: Yup.object({
      remember: Yup.boolean(),
      email: Yup.string()
      .required("Email is required")
      .matches(
        /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Enter a valid email address"
      )
      .matches(
        /^[^\uD800-\uDBFF\uD800-\uDBFF]+$/, // Regex to disallow emojis
        "Emojis are not allowed in the email address"
      ),
      password: Yup.string()
        .required("Password is required!")
         
    }),

    onSubmit: async (values) => {
      formik.setSubmitting(true);
      const fcmToken= getFromStorage(STORAGE_KEYS.fcmToken);
      console.log("fffdd",fcmToken);

      let body = {
        email: formik.values.email,
        password: formik.values.password,
        deviceToken:deviceToken|| "1234",
        deviceType:"WEB"
        // appKey: new Date().toISOString(),
      };
      console.log("ene", body);

      try {
        let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
        const response = await postLogin(encryptedBody).unwrap();
        if (response?.statusCode === 200) {
          successToast("Logged in successfully.");
          
          setToStorage(STORAGE_KEYS.token, response?.data?.accessToken || "");
          dispatch(
            setCredentials({
              user: response?.data,
              token: response?.data?.accessToken || "",
            })
          );
          if (formik.values.remember) {
            setToStorage(STORAGE_KEYS.credentials, JSON.stringify(body));
          } else {
            removeFromStorage(STORAGE_KEYS.credentials);
          }
          navigate("/dashboard");
        }
      } catch (error: any) {
        errorToast(error?.data?.message);
      }
    },
  });
 const token=getFromStorage(STORAGE_KEYS.token);
 console.log("toke1",token);
  useEffect(() => {
    const data = getFromStorage(STORAGE_KEYS.credentials);
    if (data) {
      const rememberData = JSON.parse(`${data}`);
      formik.setFieldValue("password", rememberData?.password);
      formik.setFieldValue("email", rememberData?.email);
      formik.setFieldValue("remember", true);
    }
    // eslint-disable-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      component="main"
      className="auth_module"
      sx={{
        alignItems: "center",
        display: "flex",
        flexGrow: 1,
      }}
    >
      <Loader isLoad={isLoading} />
      <Container className="auth_cntnr" maxWidth="xs">
        <Box
          m={0}
          className="auth_logo"
          sx={{ display: "flex", justifyContent: "center" }}
        >
        <figure className="logo_cntnr"  >
            <img src="/static/images/logo.png" alt="" />
          </figure>
        </Box>
        <div className="auth_box">
          <Box sx={{ mb: 3, textAlign: "center", color: "#1d1d1d" }}>
            <Typography variant="h5">Log in to GetProp Admin</Typography>
          </Box>
          <form onSubmit={formik.handleSubmit}>
            <FormControl sx={{ mb: 2, width: "100%" }}>
              <Typography className="custom_label">Email Address</Typography>
              <TextField
                className="text_field"
                hiddenLabel
                placeholder="Email Address"
                fullWidth
                name="email"
                type="email"
                id="email"
                variant="outlined"
                onChange={(val) => {
                  if (val.target.value === " " || val.target.value === ".") {
                  } else {
                    formik.handleChange(val);
                  }
                }}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                helperText={formik.touched.email && formik.errors.email}
              />
            </FormControl>
            <FormControl sx={{ mb: 2, width: "100%" }}>
              <Typography className="custom_label">Password</Typography>
              <TextField
                className="text_field"
                hiddenLabel
                placeholder="Password"
                fullWidth
                name="password"
                id="password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
                helperText={formik.touched.password && formik.errors.password}
                type={showPassword ? "text" : "password"}
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment className="eye_btn" position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box className="remember_box">
                <FormControlLabel
                  control={<Checkbox />}
                  label="Remember me"
                  checked={formik.values.remember}
                  name="remember"
                  onChange={formik.handleChange}
                />
              </Box>
              <Box
                className="anchor_link"
                onClick={() => navigate("/forgotpassword")}
              >
                <Typography>Forgot Password?</Typography>
              </Box>
            </Box>
            <Box sx={{ pt: 3 }}>
              <Button
                className="btn btn_primary"
                color="primary"
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                // onClick={() => navigate("/dashboard")}
              >
                Sign In
              </Button>
            </Box>
          </form>
        </div>
      </Container>
    </Box>
  );
};

export default Login;
