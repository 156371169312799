import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useEditUserMutation } from "../../services/users";
import { UploadMedia } from "../../utils/uploadMedia";
import { errorToast, successToast } from "../../helpers/toast";
import { useLazyGetSpecificAgentByIdQuery } from "../../services/agents";
import { CommonBody, Document } from "../../types/General";
import EditIcon from "@mui/icons-material/Edit";
import moment from "moment";
import { generateEncryptedKeyBody } from "../../utils/crypto";


const CompanyDocumentation = () => {
  const { id } = useParams();
  const location = useLocation();
  const { state } = location;
  const navigate = useNavigate();

  const [ByIdDetails, setByIdDetails] = useState<Document>();
  const [editUSERbyId] = useEditUserMutation();
  const [fetchApi, { isLoading }] = useLazyGetSpecificAgentByIdQuery();

  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>,
    type: "front" | "back"
  ) => {
    const files = event.target.files;
    const file = files?.length ? files[0] : null;

    try {
      if (!file) {
        formik.setFieldValue("frontImage", "");
        formik.setFieldValue("backImage", "");
        return;
      }

      const res = await UploadMedia(file);
      if (res?.statusCode === 200) {
        const imageUrl = res?.data[0]?.location;
        if (type === "front") {
          formik.setFieldValue("frontImage", imageUrl);
        } else if (type === "back") {
          formik.setFieldValue("backImage", imageUrl);
        }
        successToast("Image uploaded successfully.");
      } else {
        errorToast(res?.message);
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      errorToast("Error uploading image. Please try again.");
    }
  };

  const fetchDataById = async (id: any) => {
    try {
      const response = await fetchApi({ userId: id }).unwrap();
      if (response.statusCode === 200) {
        setByIdDetails(response?.data?.documents[0] || {});
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchDataById(id);
  }, [id]);

  const formik = useFormik({
    initialValues: {
      documentName: "",
      expiryDate: "",
      frontImage: "",
      backImage: "",
    },
    validationSchema: Yup.object().shape({
      documentName: Yup.string().required("Document name is required"),
      expiryDate: Yup.date().required("Expiry date is required").nullable(),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      if (!formik.isValid) return;
      try {
        setSubmitting(true);

        let body = {
          documents: {
            name: values.documentName,
            expiryDate: moment(values.expiryDate)
              .utc()
              .format("YYYY-MM-DDTHH:mm:ss[Z]"),
            frontImage: formik.values.frontImage,
            backImage: formik.values.backImage,
          },

        };

        console.log("---profile_body", body);
        let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
        const response = await editUSERbyId({
          id,
          body: encryptedBody,
        }).unwrap();

        if (response.statusCode === 200) {
          successToast("Company updated successfully");
          navigate("/manage-company");
        } else {
          errorToast("Failed to update user");
        }
      } catch (error) {
        console.error("Error updating user:", error);
        errorToast("Error updating user. Please try again.");
      } finally {
        setSubmitting(false);
      }
    },
  });


  useEffect(() => {
    if (ByIdDetails) {
      formik.setValues({
        documentName: ByIdDetails.name || "",
        expiryDate: moment(ByIdDetails.expiryDate).format("YYYY-MM-DD") || "",
        frontImage: ByIdDetails.frontImage || "",
        backImage: ByIdDetails.backImage || "",
      });
    }
  }, [ByIdDetails]);


  return (
    <div className="main_layout">
      <div className="dashboard">
        {state?.type === "edit" ? (
          <h1 className="main_heading">Edit Agent</h1>
        ) : (
          <h1 className="main_heading">Documentation</h1>
        )}
        <Button
          className="btn btn_primary"
          onClick={() => {
            navigate("/manage-company");
          }}
        >
          Back
        </Button>
      </div>
      <Card className="card">
        <form onSubmit={formik.handleSubmit}>
          <CardContent sx={{ p: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} className="heading">
                <Typography className="custom_label" variant="h5">
                  Documents
                </Typography>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Typography className="custom_label">Document Name</Typography>
                <TextField
                  hiddenLabel
                  type={"text"}
                  name="documentName"
                  className="text_field"
                  variant="outlined"
                  fullWidth
                  placeholder="Enter Document Name"
                  value={formik.values.documentName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.documentName && Boolean(formik.errors.documentName)}
                  helperText={
                    formik.touched.documentName &&
                    formik.errors.documentName
                  }
                />
              </Grid>

              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Typography className="custom_label">Expiry Date</Typography>
                <TextField
                  hiddenLabel
                  type={"date"}
                  name="expiryDate"
                  className="text_field"
                  variant="outlined"
                  fullWidth
                  placeholder="Enter Expiry Date"
                  value={formik.values.expiryDate}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.expiryDate && Boolean(formik.errors.expiryDate)}
                  helperText={
                    formik.touched.expiryDate &&
                    formik.errors.expiryDate
                  }
                />
              </Grid>

              <Grid item xs={12}>
                <Box>
                  <Typography component="h5" style={{ fontWeight: "bold" }}>
                    Documents
                  </Typography>
                  <Box className="docs_div">
                    <figure>
                      <img
                        src={formik.values.frontImage || ByIdDetails?.frontImage}
                        alt=""
                      />
                      <input
                        type="file"
                        id="upload-front-image"
                        style={{ display: "none" }}
                        onChange={(event) => handleImageUpload(event, "front")}
                      />
                    </figure>
                    <label htmlFor="upload-front-image" style={{ cursor: "pointer" }}>
                      <EditIcon />
                    </label>
                    <figure>
                      <img
                        src={formik.values.backImage || ByIdDetails?.backImage}
                        alt=""
                      />
                      <input
                        type="file"
                        id="upload-back-image"
                        style={{ display: "none" }}
                        onChange={(event) => handleImageUpload(event, "back")}
                      />
                    </figure>
                    <label htmlFor="upload-back-image" style={{ cursor: "pointer" }}>
                      <EditIcon />
                    </label>
                  </Box>
                </Box>
              </Grid>

            </Grid>
            <div className="form_btn">
              <Button
                size="large"
                type="submit"
                className="btn btn_primary"
              >
                Submit
              </Button>
            </div>
          </CardContent>
        </form>
      </Card>
    </div>
  );
};

export default CompanyDocumentation;
