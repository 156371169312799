import { Box, Dialog, DialogContent, IconButton } from '@mui/material'
import React, { Dispatch, SetStateAction } from 'react'
import CloseIcon from '@mui/icons-material/Close';

type props = {
    open: boolean;
    handleClose: () => void;
    image: string | null;
};

export default function ImageModal({ open, handleClose, image }: props) {
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="sm"
            fullWidth
        >
            <DialogContent sx={{ p: 0, position: 'relative' }}>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: "absolute",
                        top: 10,
                        right: 10,
                        color: "black",
                        border:'solid darkgrey',
                        borderRadius:"50px"
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <img
                        src={image || ''}
                        alt="Selected"
                        style={{ width: '100%', height: 'auto', objectFit: 'contain' }}
                    />
                </Box>
            </DialogContent>
        </Dialog>
    )
}
