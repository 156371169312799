import { Box, Button, Card, CardContent, Grid, IconButton, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate, useParams } from "react-router-dom";

import { useLazyGetSpecificCompanyPropertyByIdQuery } from "../../services/users";
import { errorToast, successToast } from "../../helpers/toast";
import { useLazyDeleteByIdPropertyQuery } from "../../services/property";
import Pagination from "../../components/Pagination";
import WarnModal from "../../components/WarnModal";
const CompanyProperty = () => {

  const { _id } = useParams();
  const [deletePropertyById] = useLazyDeleteByIdPropertyQuery();
  const [totalCount, setTotalCount] = useState<number>(0);
  const [allDetailsById, setAllDetailsById] = useState<any>([]);
  const [open, setOpen] = useState(false);
  const [limit, setLimit] = useState<number>(10);
  let totalPages = Math.ceil(totalCount / limit);
  console.log("alllwdxed", allDetailsById);
  const [page, setPage] = useState(1);
  const onPageChange1 = (newPage: number) => {
    setPage(newPage);
    setAllDetailsById([]);
  };
  // const [editCompanybyId] = useEditUserMutation();
  const [selectedId, setSelectedId] = useState<number>(0);
  const [AllDetails] = useLazyGetSpecificCompanyPropertyByIdQuery();
  function createData(
    id: number,
    date: string,
    title: string,
    message: string,
    orders: boolean,
    status: boolean,
  ) {
    return { id, date, title, message, orders, status };
  }
  const fetchAllDataById = async (userId: any) => {
    try {
      const response = await AllDetails({ userId }).unwrap();
      if (response.statusCode === 200) {
        setAllDetailsById(response.data.propertyDetails || []);
        setTotalCount(response.data.count || 0);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleDeleteProperty = async (userId: any) => {
    try {
      const response = await deletePropertyById({ userId }).unwrap();
      if (response?.statusCode === 200) {
        successToast("Property deleted successfully");
        fetchAllDataById(userId);

      }
    } catch (error: any) {
      console.error(error);
      errorToast(error?.message || "");
    }
  };
  useEffect(() => {
    fetchAllDataById(_id);
  }, [_id]);

  useEffect(() => {
    fetchAllDataById(_id)
  }, [_id]);
  // const rows = [
  //   createData(
  //     1,
  //     "29/09/2023",
  //     "Title",
  //     "Message",
  //     true, // Orders-based changes and updates
  //     true // Status Updates (confirmed)
  //   ), // Example data, replace with actual data from database
  // ];

  const navigate = useNavigate();

  const label = { inputProps: { "aria-label": "Switch demo" } };

  return (
    <>


      <Card className="cards">
        <Box className="cards_header">

          <Box className="cards_header_right">


          </Box>
        </Box>
        <TableContainer className="table_container">
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">S.No</TableCell>
                <TableCell>Image</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Address</TableCell>
                <TableCell>Agent</TableCell>

                <TableCell>Actions</TableCell>

              </TableRow>
            </TableHead>
            <TableBody>
              {allDetailsById.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    No property found
                  </TableCell>
                </TableRow>
              ) : (
                allDetailsById.map((property: any, index: number) => (
                  <TableRow key={property.properties._id}>
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell>
                      <figure className="user_img">
                        <img
                          style={{ borderRadius: 0 }}
                          src={property.properties.images[0] || "/static/images/house.jpg"}
                          alt=""
                        />
                      </figure>
                    </TableCell>
                    <TableCell>{property.properties.name || "-"}</TableCell>
                    <TableCell>{property.properties.address || "-"}</TableCell>
                    <TableCell>{property.properties.ownerName || "-"}</TableCell>
                    <TableCell>
                      <Box className="table_actions">
                        <IconButton
                          onClick={() => navigate(`/company/property-listing/details/${property.properties._id}`)}
                        >
                          <VisibilityIcon />
                        </IconButton>
                        <IconButton onClick={() => {
                          setOpen(true);
                          setSelectedId(property?.properties?._id);
                        }}>
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
      {allDetailsById?.length > 0 ? (
        <Pagination
          setPage={setPage}
          limit={limit}
          setLimit={setLimit}
          module={allDetailsById}
          page={page}
          onPageChange={onPageChange1}
          totalPages={totalPages}
        />
      ) : (
        ""
      )}
      <WarnModal
        open={open}
        setOpen={setOpen}
        handleDelete={() => handleDeleteProperty(selectedId)}
        name="Property"
      />
    </>
  );
};

export default CompanyProperty;
