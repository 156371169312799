import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Box,
  Switch,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import SearchBar from "../../components/SearchBar";
import SearchBar2 from "../../components/SearchBar2";
import { useEditUserMutation, useLazyDeleteByIduserQuery, useLazyGetAllUserQuery } from "../../services/users";
import Pagination from "../../components/Pagination";

import { errorToast, successToast } from "../../helpers/toast";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody, User } from "../../types/General";
import { isValidInput } from "../../utils/validation";
import Loader from "../../helpers/constants/Loader";

import { useLazyExportNotifyQuery, useLazyGetAllNotifyEmailQuery } from "../../services/emails";
const ManageEmails = () => {

  const [gaetAllEmail, { isLoading }] = useLazyGetAllNotifyEmailQuery();
  const [rows, setRows] = useState<any>([]);
  console.log("row2222", rows);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  let totalPages = Math.ceil(totalCount / limit);
  const [editUSERbyId] = useEditUserMutation();
  const [deleteUserById] = useLazyDeleteByIduserQuery();
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState<number>(0);
  const onPageChange = (newPage: number) => {
    setPage(newPage);
    setRows([]);
  };
  const [exportCsv] = useLazyExportNotifyQuery();
  const handleExportCsv = async () => {
    try {
      const res = await exportCsv({

      }).unwrap();
      console.log("eugded", res);




      if (res?.statusCode === 200) {
        window.open(res?.data);
        successToast("CSV exported successfully")
      } else {

        errorToast("Error: s3Location not found in response");
      }
    } catch (error: any) {
      errorToast(error?.message);
    }
  };
  const fetchData = async (page: number, search: string) => {
    try {
      const response = await gaetAllEmail({ page: page, search: search, limit: limit }).unwrap();
      if (response.statusCode === 200) {
        setRows(response?.data?.notifyMe || []);
        setTotalCount(response?.data?.count);
      }
    } catch (error) {
      console.error(error);
    }
  };


  useEffect(() => {
    fetchData(page, debouncedSearchTerm);
  }, [page, debouncedSearchTerm, limit]);

  return (
    <MainContainer>
      <Loader isLoad={isLoading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Manage Emails</h1>
        </div>
        <Loader isLoad={isLoading} />
        <Card className="cards">
          <Box className="cards_header">
            <SearchBar2
              value={searchTerm}
              searchTerm={searchTerm}
              onCross={() => setSearchTerm("")}
              setDebouncedSearchTerm={setDebouncedSearchTerm}
              onChange={(val: any) => {
                if (isValidInput(val.target.value)) {
                  setSearchTerm(val.target.value);
                }
              }}
            />
            <Box className="cards_header_right">
              <Button
                className="btn btn_primary"
                onClick={handleExportCsv}
              >
                <FileDownloadIcon /> Export CSV
              </Button>
            </Box>
          </Box>
          <TableContainer className="table_container">
            <Box className="heading"></Box>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">S.No</TableCell>
                  <TableCell>Email</TableCell>




                </TableRow>
              </TableHead>
              <TableBody>
                {rows.length == 0 ? (
                  <TableRow>
                    <TableCell colSpan={2} align="center">
                      No email found
                    </TableCell>
                  </TableRow>
                ) : (
                  rows.map((row: any, i: any) => (
                    <TableRow key={row.name}>
                      <TableCell align="center">{(page - 1) * 10 + i + 1}</TableCell>

                      <TableCell>{row?.email || "-"}</TableCell>



                    </TableRow>
                  ))
                )}

              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </div>
      {rows?.length > 0 ? (
        <Pagination
          setPage={setPage}
          module={rows}
          page={page}
          onPageChange={onPageChange}
          totalPages={totalPages}
          limit={limit}
          setLimit={setLimit}
        />
      ) : (
        ""
      )}

    </MainContainer>
  );
};

export default ManageEmails;
