import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  Modal,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useLazyGetSpecificUserByIdQuery } from "../../services/users";
import { errorToast } from "../../helpers";
import { ImageModal } from "../../components";

const AgentDetails = () => {
  const { _id } = useParams();
  const [ByIdDetails, setByIdDetails] = useState<any>([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  const [fetchApi, { isLoading }] = useLazyGetSpecificUserByIdQuery();

  const fetchDataById = async (_id: any) => {
    try {
      const response = await fetchApi({ userId: _id }).unwrap();
      if (response.statusCode === 200) {
        setByIdDetails(response?.data || []);
      }
    } catch (error: any) {
      errorToast(error?.message || "");
    }
  };

  useEffect(() => {
    fetchDataById(_id);
  }, [_id]);

  const formatDate = (dateString: string) => {
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const handleOpenModal = (imageUrl: string) => {
    setSelectedImage(imageUrl);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedImage("");
  };

  return (
    <>
      <Card className="cards">
        <CardContent sx={{ p: 1 }}>
          <Grid container spacing={2} className="view_box">
            <Grid item lg={2} md={2} sm={6} xs={12}>
              <figure className="profile_img">
                <img
                  src={
                    ByIdDetails?.image
                      ? ByIdDetails?.image
                      : `/static/images/user_placeholder.png`
                  }
                  alt="Profile"
                />
                <div className="overlay">
                  <img
                    onClick={() => handleOpenModal(ByIdDetails?.image ? ByIdDetails?.image : "/static/images/user_placeholder.png")}
                    src='/static/images/visibility.png' alt='img'
                  />
                </div>
              </figure>
            </Grid>
            <Grid item xs={10} className="view_box_list">
              <Grid container spacing={3}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Box>
                    <Typography component="h2" style={{ marginBottom: "-10px" }}>
                      <Box sx={{ fontWeight: "bold", m: 0 }}>Personal Info</Box>
                    </Typography>
                  </Box>
                </Grid>
                {ByIdDetails?.companyName ? (
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Company Name</Typography>
                      <Typography component="p">
                        {ByIdDetails?.companyName || "-"}
                      </Typography>
                    </Box>
                  </Grid>
                ) : (
                  ""
                )}
                {/* Personal info fields */}
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box>
                    <Typography component="h5">Full Name</Typography>
                    <Typography component="p">
                      {ByIdDetails?.name || "-"}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box>
                    <Typography component="h5">Email</Typography>
                    <Typography component="p">
                      {ByIdDetails?.email || "-"}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box>
                    <Typography component="h5">Phone Number</Typography>
                    <Typography component="p">
                      {ByIdDetails?.phoneNo || "-"}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box>
                    <Typography component="h5">Emirates Id</Typography>
                    <Typography component="p">
                      {ByIdDetails?.emiratesId || "-"}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box>
                    <Typography component="h5">Available Tokens</Typography>
                    <Typography component="p">
                      {ByIdDetails?.token || "-"}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box>
                    <Typography component="h5">Subscription</Typography>
                    <Typography component="p">
                      {ByIdDetails?.currentSubscription || "-"}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box>
                    <Typography component="h5">Subscription Expiry Date</Typography>
                    <Typography component="p">
                      {ByIdDetails?.subscriptionExpiryDate
                        ? formatDate(ByIdDetails?.subscriptionExpiryDate)
                        : "-"}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box>
                    <Typography component="h5">Address</Typography>
                    <Typography component="p">
                      {ByIdDetails?.address || "-"}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box>
                    <Typography component="h5">Account Status</Typography>
                    <Typography component="p">
                      {ByIdDetails?.isBlocked === false ? "Active" : "Blocked"}
                    </Typography>
                  </Box>
                </Grid>
                {/* Documents section */}
                <Grid item xs={12}>
                  <Box>
                    <Typography component="h5">Documents</Typography>
                    <Box className="docs_div">
                      <figure>
                        {ByIdDetails?.documents?.frontImage ? (
                          <figure>
                            <img
                              src={ByIdDetails?.documents?.frontImage}
                              alt="Front Document"
                              style={{ cursor: "pointer", maxWidth: "300px" }}
                            />
                            <div className="overlay">
                              <img
                                onClick={() => handleOpenModal(ByIdDetails?.documents?.frontImage ? ByIdDetails?.documents?.frontImage : "/static/images/user_placeholder.png")}
                                src='/static/images/visibility.png' alt='img'
                              />
                            </div>
                          </figure>

                        ) : (
                          "No front image document available"
                        )}
                      </figure>
                      <figure>
                        {ByIdDetails?.documents?.backImage ? (
                          <figure>
                            <img
                              src={ByIdDetails?.documents?.backImage}
                              alt="Back Document"
                              style={{ cursor: "pointer", maxWidth: "300px" }}
                            />
                            <div className="overlay">
                              <img
                                onClick={() => handleOpenModal(ByIdDetails?.documents?.backImage ? ByIdDetails?.documents?.backImage : "/static/images/user_placeholder.png")}
                                src='/static/images/visibility.png' alt='img'
                              />
                            </div>
                          </figure>
                        ) : (
                          "No back image document available"
                        )}
                      </figure>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <ImageModal open={openModal} handleClose={handleCloseModal} image={selectedImage} />
    </>
  );
};

export default AgentDetails;
