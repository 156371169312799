import { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Button,
  Card, IconButton, Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  useLazyGetAllNotificatiosnQuery,
  useLazyDeleteByIdNotificationQuery,
} from "../../services/notifications";
import { errorToast, successToast } from "../../helpers";
import Pagination from "../../components/Pagination";
import WarnModal from "../../components/WarnModal";
import SearchBar2 from "../../components/SearchBar2";
import { isValidInput } from "../../utils/validation";
import Loader from "../../helpers/constants/Loader";

const ManageNotifications = () => {
  const navigate = useNavigate();
  const [row1, setRow1] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState<number>(0);
   const [limit, setLimit] = useState<number>(10);
  let totalPages = Math.ceil(totalCount / limit);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState<number>(0);

  const [fetchAllListing, { isLoading }] = useLazyGetAllNotificatiosnQuery();
  const [deleteNotificationById] = useLazyDeleteByIdNotificationQuery();
  const onPageChange = (newPage: number) => {
    setPage(newPage);
    setRow1([]);
  };

  const formatDate = (isoString: string | number | Date) => {
    const date = new Date(isoString);
    return date.toLocaleDateString(undefined, {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const allListing = async (page: number, search: string) => {
    try {
      const response = await fetchAllListing({ page:page, search:search,limit:limit }).unwrap();
      setRow1(response?.data?.notification);
      setTotalCount(response?.data?.count || 0);
    } catch (e: any) {
      errorToast(e?.message);
    }
  };

  const handleDeleteNotification = async (userId: any) => {
    try {
      const response = await deleteNotificationById({ userId }).unwrap();
      if (response?.statusCode === 200) {
        successToast("Notification deleted successfully");
        allListing(page, searchTerm);
      }
    } catch (error: any) {
      console.error(error);
      errorToast(error?.message || "");
    }
  };
  useEffect(() => {
    allListing(page, debouncedSearchTerm);
  }, [page, debouncedSearchTerm,limit]);

  return (
    <MainContainer>
      <div className="main_layout">
        <div className="dashboard">
          <h3 className="mn_hdng">Manage Notifications</h3>
        </div>
        <Loader isLoasd={isLoading} />
        <Card className="cards">
          <Box className="cards_header">
            <SearchBar2
              value={searchTerm}
              searchTerm={searchTerm}
              onCross={() => setSearchTerm("")}
              setDebouncedSearchTerm={setDebouncedSearchTerm}
              onChange={(val: any) => {
                if (isValidInput(val.target.value)) {
                  setSearchTerm(val.target.value);
                }
              }}
            />
            <Box className="cards_header_right">
              <Button
                className="btn btn_primary"
                onClick={() => navigate("/manage-notifications/add")}
              >
                        <AddIcon/> Add Notification
              </Button>
            </Box>
          </Box>
          <TableContainer className="table_container">
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">S.No</TableCell>
                  <TableCell>Sent To</TableCell>
                  <TableCell>Date</TableCell>

                  <TableCell>Title</TableCell>
                  <TableCell>Messages</TableCell>

                  <TableCell align="center">Delete</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {row1.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={6} align="center">
                      No notification found
                    </TableCell>
                  </TableRow>
                ) : (
                  row1.map((row: any, index: any) => (
                    <TableRow key={row?._id}>
                      <TableCell align="center">
                        {(page - 1) * 10 + index + 1}
                      </TableCell>
                      <TableCell>
                        {row?.role == "3"
                          ? "Agent"
                          : row?.role == "4"
                            ? "User"
                            : row?.role == "4"
                            ? "Company"
                          :"All"}
                      </TableCell>
                      <TableCell> {formatDate(row?.createdAt)}</TableCell>
                      <TableCell>{row?.title}</TableCell>

                      <TableCell>{row?.message}</TableCell>

                      <TableCell align="center">
                        <IconButton
                          onClick={() => {
                            setOpen(true);
                            setSelectedId(row?._id);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </div>
      {row1?.length > 0 ? (
        <Pagination
          setPage={setPage}
          module={row1}
          page={page}
          limit={limit}
          setLimit={setLimit}
          onPageChange={onPageChange}
          totalPages={totalPages}
        />
      ) : (
        ""
      )}
      <WarnModal
        open={open}
        setOpen={setOpen}
        handleDelete={() => handleDeleteNotification(selectedId)}
        name="Notification"
      />
    </MainContainer>
  );
};

export default ManageNotifications;
