import { useEffect, useState } from "react";
import {
  Card,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useLazyGetReelByAgentQuery } from "../../services/users";
import { format } from "date-fns";
import WarnModal from "../../components/WarnModal";
import { useLazyDeleteChatByIdQuery } from "../../services/chat";
import { errorToast, successToast } from "../../helpers";
import Pagination from "../../components/Pagination";

const AgentReels = () => {
  const navigate = useNavigate();
  const { _id } = useParams();
  const [deleteChatById] = useLazyDeleteChatByIdQuery();
  const [data, setData] = useState<any[]>([]);
  const [open, setOpen] = useState(false);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const [ReelsListing, { isLoading }] = useLazyGetReelByAgentQuery();
  const [page, setPage] = useState(1);
  const [limit,setLimit]=useState<number>(10);

  // Calculating total pages based on the total count and page size
  let totalPages = Math.ceil(totalCount / limit);

  // Format the date and time
  const formatDateTime = (dateString: string | number | Date) => {
    if (!dateString) return "-";
    const date = format(new Date(dateString), "yyyy-MM-dd");
    const time = format(new Date(dateString), "HH:mm:ss");
    return `${date}, Time: ${time}`;
  };

  // Fetching API data
  const fetchApi = async (userId: any) => {
    try {
      const response = await ReelsListing({ userId: _id }).unwrap();
      setData(response?.data?.reels || []);
      setTotalCount(response?.data?.count);
    } catch (e: any) {
      errorToast(e?.message || "Failed to fetch data");
    }
  };

  // useEffect to call fetchApi on component mount and page change
  useEffect(() => {
    fetchApi(_id);
  }, [_id, page]);

  // Handling page change
  const onPageChange = (newPage: number) => {
    setPage(newPage);
    setData([]); // Clear data to show loading state
    fetchApi(_id);
  };

  // Handling chat deletion
  const handleDeleteChat = async (userId: string) => {
    try {
      const response = await deleteChatById({ userId }).unwrap();
      if (response?.statusCode === 200) {
        successToast("Chat deleted successfully");
        fetchApi(page);
      }
    } catch (error: any) {
      errorToast(error?.message || "Failed to delete chat");
    }
  };

  return (
    <Card className="cards">
      <Box className="cards_header">
        <Box className="cards_header_right">
          {/* Additional buttons or actions can go here */}
        </Box>
      </Box>
      <TableContainer className="table_container">
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">S.No</TableCell>
              <TableCell>Property Name</TableCell>
              <TableCell>Posted By</TableCell>
              <TableCell align="center">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length === 0 ? (
              <TableRow>
                <TableCell colSpan={9} align="center">
                  No reels found
                </TableCell>
              </TableRow>
            ) : (
              data?.map((row, index) => (
                <TableRow key={row._id}>
                  <TableCell align="center">
                    {(page - 1) * 10 + index + 1}
                  </TableCell>
                  <TableCell>{row?.name || "-"}</TableCell>
                  <TableCell>{row?.users?.name || "-"}</TableCell>
                  <TableCell>
                    <Box className="table_actions">
                      <IconButton
                        onClick={() =>
                          navigate(`/manage-reels/agents/${row?._id}`)
                        }
                      >
                        <VisibilityIcon />
                      </IconButton>
                      <IconButton
                        onClick={() => {
                          setSelectedId(row._id);
                          setOpen(true);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {data?.length > 0 && (
        <Pagination
          setPage={setPage}
          module={data}
          page={page}
          limit={limit}
          setLimit={setLimit}
          onPageChange={onPageChange}
          totalPages={totalPages}
        />
      )}
      <WarnModal
        open={open}
        setOpen={setOpen}
        handleDelete={() => handleDeleteChat(selectedId as string)}
        name="Chat"
      />
    </Card>
  );
};

export default AgentReels;
