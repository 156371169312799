import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {
  Button,
  Card,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import Box from "@mui/material/Box";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import { errorToast, successToast } from "../../helpers/toast";
import { Amenities } from "../../types/General";
import SearchBar2 from "../../components/SearchBar2";
import { isValidInput } from "../../utils/validation";
import Pagination from "../../components/Pagination";
import WarnModal from "../../components/WarnModal";
import Loader from "../../helpers/constants/Loader";
import { useLazyDeleteByIdAmenitiesQuery, useLazyGetAllAmenitiesQuery } from "../../services/amenities";
const ManageAmenities = () => {
  const navigate = useNavigate();
  const [rows, setRows] = useState<Amenities[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState<number>(0);
  const onPageChange = (newPage: number) => {
    setPage(newPage);
    setRows([]);
  };
  const [totalCount, setTotalCount] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  let totalPages = Math.ceil(totalCount / limit);

  const [fetchAllCategory, { isLoading }] = useLazyGetAllAmenitiesQuery();
  const [deleteCategory] = useLazyDeleteByIdAmenitiesQuery();

  const CategoryList = async () => {
    try {
      const response = await fetchAllCategory({ page: page, search: debouncedSearchTerm, limit: limit }).unwrap();
      setRows(response?.data?.data || []);
      setTotalCount(response?.data?.total);
    }
    catch (e: any) {
      errorToast(e?.message);
    }
  }
  const handleDeleteCategory = async (userId: any) => {
    try {
      const response = await deleteCategory({ userId }).unwrap();
      if (response?.statusCode === 200) {
        successToast("Amenity deleted successfully");
        CategoryList();
      }
    } catch (error: any) {
      console.error(error);
      errorToast(error?.message || "");
    }
  };

  useEffect(() => {
    CategoryList()
  }, [page, debouncedSearchTerm,limit])



  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Manage Amenities</h1>
        </div>
        <Loader isLoad={isLoading} />
        <Card className="cards">
          <Box className="cards_header">
            <SearchBar2
              value={searchTerm}
              searchTerm={searchTerm}
              onCross={() => setSearchTerm("")}
              setDebouncedSearchTerm={setDebouncedSearchTerm}
              onChange={(val: any) => {
                if (isValidInput(val.target.value)) {
                  setSearchTerm(val.target.value);
                }
              }}
            />

            <Box className="cards_header_right">

              <Button
                className="btn btn_primary"
                onClick={() => {
                  navigate("/manage-amenities/add");
                }}
              >
                <AddIcon />
                Add Amenity
              </Button>
            </Box>
          </Box>
          <TableContainer className="table_container">
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">S.No</TableCell>

                  <TableCell>Amenity Name</TableCell>

                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows?.length == 0 ? (<TableRow>
                  <TableCell colSpan={12} align="center">
                    No amenity found
                  </TableCell>
                </TableRow>) :
                  (rows.map((row: any, index: any) => (
                    <TableRow key={row?._id}>
                      <TableCell align="center">{index + 1}</TableCell>

                      <TableCell>{row?.name}</TableCell>


                      <TableCell>
                        <Box className="table_actions">

                          <IconButton
                            onClick={() =>
                              navigate(`/manage-amenities/edit/${row?._id}`)
                            }
                          >
                            <ModeEditIcon />
                          </IconButton>
                          <IconButton onClick={() => {
                            setOpen(true);
                            setSelectedId(row?._id);
                          }}>
                            <DeleteIcon />
                          </IconButton>
                        </Box>
                      </TableCell>
                    </TableRow>
                  )))}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </div>
      {rows?.length > 0 ? (
        <Pagination
          setPage={setPage}
          module={rows}
          page={page}
          onPageChange={onPageChange}
          limit={limit}
          setLimit={setLimit}
          totalPages={totalPages}
        />
      ) : (
        ""
      )}
      <WarnModal
        open={open}
        setOpen={setOpen}
        handleDelete={() => handleDeleteCategory(selectedId)}
        name="Amenity"
      />
    </MainContainer>
  );
};

export default ManageAmenities;
