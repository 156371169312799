import emptySplitApi from "../utils/rtk";
import { User, updateProfile } from "../types/User";
import { END_POINTS } from "../helpers";
import { CommonBody } from "../types/General";

type CommonResponseType = {
  data: any;
 
  statusCode: number;
  message: string;
   s3Location?:string
};
 
 
interface CategoryResponse extends CommonResponseType {
  data: any;
}

interface QueryParams {
  userId?: any;
}

export const authApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    postAddNotificatiosn: builder.mutation<
      CommonResponseType & { data: any },
      CommonBody
    >({
      query: (body) => ({
        url: END_POINTS.notification,
        method: "POST",
        body,
      }),
    }),
    getAllNotificatiosn: builder.query<CommonResponseType & { data: any }, { page?: number; search?: string;limit:number }>({
      query: ({ page, search ,limit}) => {
        // let url = END_POINTS.notification;
        let url = `${END_POINTS.notification}?page=${page}&search=${search}&limit=${limit}`;
        // url+="?limit=10";
        // if (page && search) {
        //   url += `&page=${page}&search=${search}`;
        // } else if (page) {
        //   url += `&page=${page}`;
        // } else if (search) {
        //   url += `&search=${search}`;
        // }
       
        return {
          url: url,
          method: "GET",
        };
      },
    }),
    rcvdAllNotificatios: builder.query<CommonResponseType & { data: any }, { page?: number; search?: string;limit:number }>({
      query: ({ page, search ,limit}) => {
        // let url = END_POINTS.receivedNotification;
        let url = `${END_POINTS.receivedNotification}?page=${page}&search=${search}&limit=${limit}`;
        // if (page && search) {
        //   url += `?page=${page}&search=${search}`;
        // } else if (page) {
        //   url += `?page=${page}`;
        // } else if (search) {
        //   url += `?search=${search}`;
        // }
        return {
          url: url,
          method: "GET",
        };
      },
    }),
    getAllAgent: builder.query<CommonResponseType & { data: any }, {role?:number }>({
        query: ({ }) => {
          let url = END_POINTS.usersListing;
          url+=`?role=3`;
         
          return {
            url: url,
            method: "GET",
          };
        },
      }),
      getAllUserListNotification: builder.query<CommonResponseType & { data: any }, {role?:number }>({
        query: ({ }) => {
          let url = END_POINTS.usersListing;
          url+=`?role=4`;
         
          return {
            url: url,
            method: "GET",
          };
        },
      }),
    
      getAllAgents: builder.query<CommonResponseType & { data: any }, {role?:number }>({
        query: ({ }) => {
          let url = END_POINTS.usersListing;
          url+=`?role=3`;
         
          return {
            url: url,
            method: "GET",
          };
        },
      }),
      getAllCompany: builder.query<CommonResponseType & { data: any }, {role?:number }>({
        query: ({ }) => {
          let url = END_POINTS.usersListing;
          url+=`?role=5`;
         
          return {
            url: url,
            method: "GET",
          };
        },
      }),
    
    getSpecificNotificationById: builder.query<CategoryResponse, QueryParams>({
      query: ({ userId }) => ({
        url: `${END_POINTS.notification}/${userId}`,
        method: 'GET',
      }),
    }),
   
  
    EditNotification: builder.mutation<
    CommonResponseType & { data: CommonBody },
    { id: string | undefined; body: CommonBody }
  >({
    query: ({ body, id }) => ({
      url: `${END_POINTS.notification}/${id}`,
      method: "PUT",
      body,
    }),
  }),
  
    
      
    deleteByIdNotification:  builder.query<CommonResponseType & { data: any }, { userId: any}>({
        query: ({ userId }) => ({
          url: `${END_POINTS.notification}/${userId}`,
          method: "DELETE",
        }),
      }),
 
  }),
});

export const {
    usePostAddNotificatiosnMutation,
    useLazyGetAllNotificatiosnQuery,
    useLazyGetSpecificNotificationByIdQuery,
    useLazyDeleteByIdNotificationQuery,
    useLazyGetAllAgentQuery,
    useLazyGetAllAgentsQuery,
    useLazyGetAllCompanyQuery,
    useEditNotificationMutation,
    useLazyGetAllUserListNotificationQuery,
    useLazyRcvdAllNotificatiosQuery
} = authApi;
