import React, { ChangeEvent, useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  Input,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";

import MainContainer from "../../layout/MainContainer";
import { useNavigate, useParams } from "react-router-dom";

import {
  usePostAddSubscriptionMutation,
  useEditSubscriptionMutation,
  useLazyGetSpecificSubscriptionByIdQuery,
} from "../../services/subscription";
import { errorToast, successToast } from "../../helpers/toast";
import { generateEncryptedKeyBody } from "../../utils/crypto";

import { CommonBody, SubscriptionPackage } from "../../types/General";
import Loader from "../../helpers/constants/Loader";

const AddSubscription = () => {
  const navigate = useNavigate();
  const { _id } = useParams();
  // const [category, setCategory] = useState<string>("");
  const [subscriptionType, setSubscriptionType] = useState<string>("");
  const [ByIdDetails, setByIdDetails] = useState<SubscriptionPackage>();
  const [fetchById] = useLazyGetSpecificSubscriptionByIdQuery();
  const [addSubscription, { isLoading }] = usePostAddSubscriptionMutation();
  const [updateSubscription] = useEditSubscriptionMutation();


  // const handleCategoryChange = (event: SelectChangeEvent) => {
  //   setCategory(event.target.value as string);
  //   formik.setFieldValue('category', event.target.value);
  // };

  const handlesubscriptionTypeChange = (event: SelectChangeEvent) => {
    setSubscriptionType(event.target.value as string);
    formik.setFieldValue('subscriptionType', event.target.value);
  };


  const formik = useFormik({
    initialValues: {
      name: ByIdDetails?.name || "",
      price: ByIdDetails?.price || "",
      features: ByIdDetails?.features || "",
      description: ByIdDetails?.description || "",
      tokens: ByIdDetails?.token || "",
      agents: ByIdDetails?.noOfAgents || "",
      // category: category || "",
      subscriptionType: subscriptionType || ""
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required("Subscription Name is required")
        .max(40, "Maximum 40 characters are allowed")
        .min(2, "Minimum 2 characters are required"),
      price: Yup.string()
        .required("Price is required")
        .max(10, "Maximum 10 characters are allowed"),
      tokens: Yup.string()
        .required("This field is required")
        .max(10, "Maximum 10 characters are allowed"),
      agents: Yup.string()
        .required("This field is required")
        .max(10, "Maximum 10 characters are allowed"),

      // category: Yup.string().required("User Type is required"),
      subscriptionType: Yup.string().required("Subscription Type is required"),
      features: Yup.string()
        .required("Features is required")
        .max(200, "Maximum 200 characters are allowed")
        .min(2, "Minimum 2 characters are required"),
      description: Yup.string()
        .required("Description is required")
        .max(300, "Maximum 300 characters are allowed")
        .min(2, "Minimum 2 characters are required"),
    }),
    onSubmit: async (values) => {
      console.log("Form submitted", values);

      let body = {
        name: values.name,
        description: values.description,
        price: Number(values.price),
        userType: "5",
        features: values.features,
        subscriptionPack: subscriptionType,
        token: Number(values.tokens),
        noOfAgents: Number(values.agents),
      }
      // if (category === "3") {
      //   body = {
      //     name: values.name,
      //     description: values.description,
      //     price: values.price,
      //     userType: "5",
      //     features: values.features,
      //     subscriptionPack: subscriptionType,
      //   };
      // } else {}



      console.log(body, "body");

      try {
        const encryptedBody = generateEncryptedKeyBody(body) as CommonBody;

        if (_id) {
          console.log("Updating subscription with id", _id);
          const response = await updateSubscription({ id: _id, body: encryptedBody }).unwrap();
          if (response.statusCode === 200) {
            successToast("Subscription Updated Successfully");
            navigate("/manage-subscription");
          } else {
            errorToast(response.message);
          }
        } else {
          console.log("Adding new subscription");
          const response = await addSubscription(encryptedBody).unwrap();
          if (response.statusCode === 200) {
            successToast("Subscription Added Successfully");
            navigate("/manage-subscription");
          } else {
            errorToast(response.message);
          }
        }
      } catch (e: any) {
        console.error("API call failed", e);
        errorToast(e?.data?.message);
      }
    },
  });






  const fetchDataById = async (id: any) => {
    try {
      const response = await fetchById({ userId: id }).unwrap();
      if (response.statusCode === 200) {
        setByIdDetails(response?.data);
        // setCategory(response.data.userType.toString());
        setSubscriptionType(response.data.subscriptionPack.toString());
      }
    } catch (error) {
      console.error(error);
    }
  };


  useEffect(() => {
    if (ByIdDetails) {
      formik.setValues({
        name: ByIdDetails?.name || "",
        description: ByIdDetails?.description || "",
        price: ByIdDetails?.price || "",
        features: ByIdDetails?.features || "",
        tokens: ByIdDetails?.token || "",
        agents: ByIdDetails?.noOfAgents || "",
        // category: category || "",
        subscriptionType: subscriptionType || ""
      });
    }
  }, [ByIdDetails]);
  useEffect(() => {
    if (_id) {
      fetchDataById(_id);
    }
  }, [_id]);


  return (
    <MainContainer>
      <Loader isLoad={isLoading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">{_id ? `Edit Subscription` : `Add Subscription`}</h1>
          <Button
            className="btn btn_primary"
            onClick={() => navigate("/manage-subscription")}
          >
            Back
          </Button>
        </div>
        <Card className="cards">
          <form onSubmit={formik.handleSubmit}>
            <CardContent sx={{ p: 1 }}>
              <Grid container spacing={2}>

                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">Subscription Name</Typography>
                  <TextField
                    hiddenLabel
                    type="text"
                    name="name"
                    variant="outlined"
                    fullWidth
                    className="text_field"
                    placeholder="Enter Subscription Name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={
                      formik.touched.name && formik.errors.name
                        ? formik.errors.name.toString()
                        : ""
                    }
                  />
                </Grid>

                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">Price</Typography>
                  <TextField
                    hiddenLabel
                    type="text"
                    name="price"
                    className="text_field"
                    variant="outlined"
                    fullWidth
                    placeholder="Enter Price"
                    value={formik?.values?.price}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.price && Boolean(formik.errors?.price)}
                    helperText={
                      formik.touched?.price && formik.errors?.price
                        ? formik.errors?.price.toString()
                        : ""
                    }
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">No. of Tokens</Typography>
                  <TextField
                    hiddenLabel
                    type="text"
                    name="tokens"
                    className="text_field"
                    variant="outlined"
                    fullWidth
                    placeholder="Enter nu. of tokens"
                    value={formik?.values?.tokens}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.tokens && Boolean(formik.errors?.tokens)}
                    helperText={
                      formik.touched?.tokens && formik.errors?.tokens
                        ? formik.errors?.tokens.toString()
                        : ""
                    }
                  />
                </Grid>
                {/* <Grid item lg={4} md={4} sm={6} xs={12}>
                  <FormControl fullWidth>
                    <Typography className="custom_label">User Type</Typography>
                    <Select
                      className="select_div"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={category}
                      onChange={handleCategoryChange}
                      onBlur={() => formik.setFieldTouched('category', true)}
                      displayEmpty
                    >
                      <MenuItem value="" disabled>
                        Select
                      </MenuItem>

                      <MenuItem value="3">Agent</MenuItem>
                      <MenuItem value="5">Company</MenuItem>
                    </Select>
                    {formik.touched.category && formik.errors.category && (
                      <Typography color="error">{formik.errors.category}</Typography>
                    )}
                  </FormControl>
                </Grid> */}
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <FormControl fullWidth>
                    <Typography className="custom_label">Subscription Type</Typography>
                    <Select
                      className="select_div"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={subscriptionType}
                      onChange={handlesubscriptionTypeChange}
                      onBlur={() => formik.setFieldTouched('subscriptionType', true)}
                      displayEmpty
                    >
                      <MenuItem value="" disabled>
                        Select
                      </MenuItem>

                      <MenuItem value="1">Monthly</MenuItem>
                      <MenuItem value="3">Quarterly</MenuItem>
                      {/* <MenuItem value="6">Half-Yearly</MenuItem> */}
                      <MenuItem value="12">Yearly</MenuItem>
                    </Select>
                    {formik.touched.subscriptionType && formik.errors.subscriptionType && (
                      <Typography style={{ fontSize: "14px" }} color="error">{formik.errors.subscriptionType}</Typography>
                    )}
                  </FormControl>
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">No. of Agents</Typography>
                  <TextField
                    hiddenLabel
                    type="text"
                    name="agents"
                    className="text_field"
                    variant="outlined"
                    fullWidth
                    placeholder="Enter no. of agents"
                    value={formik?.values?.agents}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.agents && Boolean(formik.errors?.agents)}
                    helperText={
                      formik.touched?.agents && formik.errors?.agents
                        ? formik.errors?.agents.toString()
                        : ""
                    }
                  />
                </Grid>


                {/* <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">Description</Typography>
                  <TextField
                    hiddenLabel
                    type="text"
                    name="description"
                    className="text_field"
                    variant="outlined"
                    fullWidth
                    placeholder="Enter Description"
                    value={formik?.values?.description}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.description && Boolean(formik.errors?.description)}
                    helperText={
                      formik.touched?.description && formik.errors?.description
                        ? formik.errors?.description.toString()
                        : ""
                    }
                  />
                </Grid> */}
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">Features</Typography>
                  <TextField
                    hiddenLabel
                    type="text"
                    name="features"
                    className="text_field"
                    variant="outlined"
                    fullWidth
                    placeholder="Enter Features"
                    value={formik?.values?.features}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.features && Boolean(formik.errors?.features)}
                    helperText={
                      formik.touched?.features && formik.errors?.features
                        ? formik.errors?.features.toString()
                        : ""
                    }
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">
                    Description
                  </Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="description"
                    className="text_field"
                    variant="outlined"
                    fullWidth
                    multiline
                    minRows={4}
                    placeholder="Enter Description"
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.description && Boolean(formik.errors.description)}
                    helperText={
                      formik.touched.description && formik.errors.description
                        ? formik.errors.description.toString()
                        : ""
                    }
                  />
                </Grid>
              </Grid>
            </CardContent>
            <CardContent sx={{ pt: 0, px: 1 }}>
              <Button
                className="btn btn_primary"
                type="submit"
                disabled={isLoading}
              >
                {_id ? `Update` : `Save`}
              </Button>
            </CardContent>
          </form>
        </Card>
      </div>
    </MainContainer>
  );
};

export default AddSubscription;

