import emptySplitApi from "../utils/rtk";
 
import { END_POINTS } from "../helpers";
 

type CommonResponseType = {
  statusCode: number;
  message: string;
};
type ExportParams={
  query:any,
type :any
}
 
export const settingsApi = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
      
      exportUserCsv: builder.query<CommonResponseType & { data: any }, {}>({
        query: ({ }) => {
          let url = END_POINTS.export;
          url+="?role=4";
         
          return {
            url: url,
            method: "GET",
          };
        },
      }),
      exportAgentCsv: builder.query<CommonResponseType & { data: any }, {}>({
        query: ({ }) => {
          let url = END_POINTS.export;
          url+="?role=3";
         
          return {
            url: url,
            method: "GET",
          };
        },
      }),
      exportCompanyCsv: builder.query<CommonResponseType & { data: any }, {}>({
        query: ({ }) => {
          let url = END_POINTS.export;
          url+="?role=5";
         
          return {
            url: url,
            method: "GET",
          };
        },
      }),
      exportPropertyCsv: builder.query<CommonResponseType & { data: any }, {}>({
        query: ({ }) => {
          let url = END_POINTS.propertyExport;
          return {
            url: url,
            method: "GET",
          };
        },
      }),

      GetUserGraph: builder.query<CommonResponseType & { data: any },string>({  
        query: (type) => ({ 
          url: `${END_POINTS.dashboard}?userType=${type}&role=4`,
          method: "GET",
        }),
      }), 
      GetAgentGraph: builder.query<CommonResponseType & { data: any },string>({  
        query: (type) => ({ 
          url: `${END_POINTS.dashboard}?userType=${type}&role=3`,
          method: "GET",
        }),
      }), 
      GetCompanyGraph: builder.query<CommonResponseType & { data: any },string>({  
        query: (type) => ({ 
           
          url: `${END_POINTS.dashboard}?userType=${type}&role=5`,

          method: "GET",
        }),
      }), 
      GetRevenueGraph: builder.query<CommonResponseType & { data: any },string>({  
        query: (type) => ({ 
          url: `${END_POINTS.dashboard}?revenueType=${type}`,  
          method: "GET",
        }),
      }), 
      GetPropertyGraph: builder.query<CommonResponseType & { data: any },string>({  
        query: (type) => ({ 
          url: `${END_POINTS.analytics}?propertyType=${type}`,  
          method: "GET",
        }),
      }), 
      GetDashboArdApi: builder.query<CommonResponseType & { data: any },{}>({
        query: () => ({
          url: `${END_POINTS.dashboard}`,
          method: "GET",
        }),
      }), 

    }),
  });

  

export const {  
  useLazyExportUserCsvQuery,
  useLazyExportAgentCsvQuery,
  useLazyExportCompanyCsvQuery,
  useLazyExportPropertyCsvQuery,
useLazyGetUserGraphQuery,
useLazyGetRevenueGraphQuery,
useLazyGetPropertyGraphQuery,
    useLazyGetDashboArdApiQuery,
    useLazyGetAgentGraphQuery,
    useLazyGetCompanyGraphQuery
 } = settingsApi;
