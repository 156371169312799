// export const API_URL = "https://getpropapi.appgrowthcompany.com/api/v1/Admin";
// export const media_url="https://getpropapi.appgrowthcompany.com/api/v1/Upload/";
export const API_URL= "https://getpropmicroapi.appgrowthcompany.com/admin/api/v1/Admin";
export const media_url = "https://getpropmicroapi.appgrowthcompany.com/common/api/v1/Upload/"

export const END_POINTS = {
  login: "login",
  mediaUpload: "uploadFile",
  get_user: "getProfile",
  logout: "logout",
  forgotPassword: "forgotPassword",
  verifyOtp: "verifyOtp",
  resetPassword: "resetPassword",
  changePassword: "changePassword",
  uploadFiles:"uploadFiles",
//user
user:"user",
getDetails:"getDetails",
//category
category:"category",
  ////profile
  updateProfile: "updateProfile",
   getProfile:"getProfile",
   //cms
   addCms:"cms",
   getCms:"cms",
  //ads
  ads:"ads",
 //banners
 banner:"banner",
 //subscription
 subscription:"subscription",
//settings
settings:"setting",
//property
property:"property",
dashboard:"dashboard",
analytics:"analytics",
boostProfile:"boostProfile",
propertyExport:"propertyExport",
amenitiesListing:"amenitiesListing",
propertyId:"propertyId",
//chat
chats:"chats",
notification:"notification",
usersListing:"usersListing",
 //
 export:"export",
 manageRevenue:"manageRevenue",
 notifyList:"notifyList",
 notifyExport:"notifyExport",
 receivedNotification:"receivedNotifications",
 amenities:"amenities",
 propertyType:"propertyType",
 propertyTypeListing:"propertyTypeListing",
 reels:"reels"
};
