import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Button,
  Card,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import SearchBar from "../../components/SearchBar";
import { useNavigate } from "react-router-dom";
import {
 
  useLazyGetAllBannerQuery,
  
  useEditBannerMutation,
  useLazyDeleteByIdBannerQuery,
} from "../../services/banners";
import { errorToast, successToast } from "../../helpers";
import SearchBar2 from "../../components/SearchBar2";
import { isValidInput } from "../../utils/validation";
import { Banner, CommonBody } from "../../types/General";
import AddIcon from '@mui/icons-material/Add';
import { generateEncryptedKeyBody } from "../../utils/crypto";
import Pagination from "../../components/Pagination";
import WarnModal from "../../components/WarnModal";
import Loader from "../../helpers/constants/Loader";
const ManageBanners = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const[ChangeUpdate]=useEditBannerMutation();
  const [page, setPage] = useState(1);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState<number>(0);
  const onPageChange = (newPage: number) => {
    setPage(newPage);
    setRows1([]);
  };
  const [totalCount, setTotalCount] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  let totalPages = Math.ceil(totalCount / limit);
  const [rows1, setRows1] = useState<Banner[]>([]);
  const [BannerListing, { isLoading }] = useLazyGetAllBannerQuery();
  const [deleteBanner] = useLazyDeleteByIdBannerQuery();


  const BannersList = async (page: number, search: string) => {
    try {
      const response = await BannerListing({ page:page, search:search,limit:limit }).unwrap();
      setRows1(response?.data?.banners || []);
      setTotalCount(response?.data?.count);
    }
    catch (e: any) {
      errorToast(e?.message);
    }
  }
  const changeBannerStatus=async(userId:string, userStatus:boolean)=>{
    try{
     let body={
      isBlocked:String(userStatus)
     }
     let encryptedBody=generateEncryptedKeyBody(body) as CommonBody;
     const response =await ChangeUpdate({body:encryptedBody,id:userId}).unwrap();
  if(response?.statusCode===200)
  {
    successToast("Status updated successfully");
  }
    }
    catch (error: any) {
      errorToast(error?.message);
      console.error(error);
    }
   }

   const handleDeleteBanner = async (userId: any) => {
    try {
      const response = await deleteBanner({ userId }).unwrap();
      if (response?.statusCode === 200) {
        successToast("Banner deleted successfully");
        BannersList(page, searchTerm);
      }
    } catch (error: any) {
      console.error(error);
      errorToast(error?.message || "");
    }
  };


  useEffect(() => {
    BannersList(page, debouncedSearchTerm)
  }, [page, debouncedSearchTerm,limit])
  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Manage Banners</h1>
        </div>
        <Card className="cards">
          <Box className="cards_header">
            <SearchBar2
              value={searchTerm}
              searchTerm={searchTerm}
              onCross={() => setSearchTerm("")}
              setDebouncedSearchTerm={setDebouncedSearchTerm}
              onChange={(val: any) => {
                if (isValidInput(val.target.value)) {
                  setSearchTerm(val.target.value);
                }
              }}
            />
             <Loader isLoad={isLoading} />
            <Box className="cards_header_right">
              <Button
                className="btn btn_primary"
                onClick={() => navigate("/manage-banners/add")}
              >
                <AddIcon/>
                Add Banners
              </Button>
            </Box>
          </Box>
          <TableContainer className="table_container">
            <Box className="heading"></Box>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">S.No</TableCell>
                  <TableCell>Image</TableCell>
                  <TableCell>Property Name</TableCell>
                  <TableCell>Address</TableCell>
                  <TableCell>Description</TableCell>


                  <TableCell>Status</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows1.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={7} align="center">No Banner Found</TableCell>
                  </TableRow>
                ) : (
                  rows1.map((row: any, i: number) => (
                    <TableRow key={row.name}>
                      <TableCell align="center">{i + 1}</TableCell>
                      <TableCell>
                        <figure className="user_img">
                          <img style={{ borderRadius: 0 }} src={row?.image || `/static/images/house.jpg`} alt="" />
                        </figure>
                      </TableCell>
                      <TableCell>{row?.title || "-"}</TableCell>
                      <TableCell>{row?.address || "-"}</TableCell>
                      <TableCell>
                        <Typography variant="h6" fontSize={14}>
                          {row?.description || "-"}
                        </Typography>
                      </TableCell>
                      <TableCell>
                      <Switch
          defaultChecked={!row.isBlocked}
          onChange={(e) => {
            const isChecked = !e.target.checked;
            changeBannerStatus(row._id, isChecked);  
          }}
          size="small"
        />
                      </TableCell>
                      <TableCell>
                        <Box className="table_actions">
                          <IconButton onClick={() => navigate(`/manage-banners/details/${row?._id}`)}>
                            <VisibilityIcon />
                          </IconButton>
                          <IconButton
                            onClick={() => navigate(`/manage-banners/edit/${row?._id}`)}
                          >
                            <ModeEditIcon />
                          </IconButton>
                          <IconButton onClick={()=>{
                            setOpen(true);
                            setSelectedId(row?._id);
                          }}>
                            <DeleteIcon />
                          </IconButton>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))
                )}

              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </div>
      {rows1?.length > 0 ? (
        <Pagination
          setPage={setPage}
          module={rows1}
          page={page}
          onPageChange={onPageChange}
          totalPages={totalPages}
          limit={limit}
          setLimit={setLimit}
        />
      ) : (
        ""
      )}
      <WarnModal
        open={open}
        setOpen={setOpen}
        handleDelete={() => handleDeleteBanner(selectedId)}
        name="Banner"
      />
    </MainContainer>
  );
};

export default ManageBanners;
