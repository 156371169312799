import React, { ChangeEvent, useEffect, useState } from "react";
import { Box, Button, Card, CardContent, CardMedia, Grid, Input, TextField, Typography } from "@mui/material";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CancelIcon from "@mui/icons-material/Cancel";
import PhoneInput from "react-phone-input-2";
import { useEditUserMutation, useLazyGetSpecificUserByIdQuery } from "../../services/users";
import { errorToast, successToast } from "../../helpers/toast";
import { UploadMedia } from "../../utils/uploadMedia";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody, User } from "../../types/General";
import { useLazyGetSpecificAgentByIdQuery } from "../../services/agents";
import { Autocomplete } from "@react-google-maps/api";


const CompanyProfile = () => {
  const { id } = useParams();
  const location = useLocation();
  const { state } = location;
  const navigate = useNavigate();

  const [profile_picture, setProfilePicture] = useState("");

  const [editUSERbyId] = useEditUserMutation();
  const [ByIdDetails, setByIdDetails] = useState<User>();
  const [fetchApi, { isLoading }] = useLazyGetSpecificAgentByIdQuery();
  const [autocomplete, setAutocomplete] = useState<any>();
  const [dialCode, setPhoneCode] = useState("+91");
  const fetchDataById = async (id: any) => {
    try {
      const response = await fetchApi({ userId: id }).unwrap();
      if (response.statusCode === 200) {
        setByIdDetails(response?.data);
      }
    } catch (error) {
      console.error(error);
    }
  };


  const [error, setError] = useState(false);
  const handleImageUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    const file = files?.length ? files[0] : null;

    try {
      if (!file) {
        formik.setFieldValue("profile_picture", "");
        setProfilePicture("");

        return;
      }

      const res = await UploadMedia(file);
      if (res?.statusCode === 200) {
        const imageUrl = res?.data[0]?.location;
        formik.setFieldValue("profile_picture", imageUrl);
        setProfilePicture(imageUrl);
        successToast("Image uploaded successfully.");
      } else {
        errorToast(res?.message);
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      errorToast("Error uploading image. Please try again.");
    }
  };
  const onLoad = (autocompleteObj: any) => {
    setAutocomplete(autocompleteObj);
  };
  const onPlaceChanged = async () => {
    if (autocomplete) {
      let place = await (autocomplete as any).getPlace();

      if (place && place.address_components) {
        let address = place.address_components;

        let state,
          city,
          country,
          zip = "";

        address.forEach(function (component: any) {
          let types = component.types;

          if (
            types.indexOf("locality") > -1 ||
            types.indexOf("administrative_area_level_3") > -1
          ) {
            city = component.long_name;
          }
          if (types.indexOf("postal_code") > -1) {
            zip = component.long_name;
          }
          if (types.indexOf("administrative_area_level_1") > -1) {
            state = component?.long_name || "";
          }
          if (types.indexOf("country") > -1) {
            country = component?.long_name || "";
          }
        });
        var lat = place.geometry.location.lat();
        var lng = place.geometry.location.lng();
        formik.setFieldValue("address", place?.formatted_address);
        formik.setFieldValue("latitude", lat || "");
        formik.setFieldValue("longitude", lng || "");
      }
    }
  };
  const formik = useFormik({
    initialValues: {
      fullName: "",
      email: "",
      phoneNo: "",
      address: "",
      emiratesId: "",
      dialCode: dialCode,
      profile_picture: profile_picture,
    },
    validationSchema: Yup.object().shape({
      fullName: Yup.string().required("Company name is required"),
      email: Yup.string()
        .required("Email is required")
        .matches(
          /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
          "Enter a valid email address"
        )
        .matches(
          /^[^\uD800-\uDBFF\uD800-\uDBFF]+$/,
          "Emojis are not allowed in the email address"
        ),
      phoneNo: Yup.string().required("Phone number is required"),
      address: Yup.string().required("Address is required"),
      emiratesId: Yup.string().required("Emirates Id is required")
      //   companyName: Yup.string().required("Company name is required"),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      formik.setSubmitting(true);
      let body = {
        companyName: values.fullName || "",
        email: values.email || "",
        phoneNo: values.phoneNo || "",
        image: values?.profile_picture || "",
        dialCode: dialCode,
        address: values?.address || "",
        emiratesId: values?.emiratesId || ""
      };
      console.log("b1body", body);
      let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;

      try {
        const response = await editUSERbyId({ id, body: encryptedBody }).unwrap();
        if (response?.statusCode === 200) {
          successToast("Company updated successfully");
          navigate("/manage-company");
        }
      } catch (error: any) {
        console.error(error);
        errorToast(error?.message);
      } finally {
        setSubmitting(false);
      }
    },
  });
  const handleChangePhone = (phone: any, country: any) => {
    formik.setFieldValue(
      "phoneNo",
      phone?.replace(country?.dialCode, "")
    );
    setPhoneCode(country?.dialCode.includes("+") ? "" : "+" + country?.dialCode);
  };

  useEffect(() => {
    {
      fetchDataById(id);
    }
  }, [id]);
  useEffect(() => {
    if (ByIdDetails) {
      formik.setValues({
        fullName: ByIdDetails?.companyName || "",
        email: ByIdDetails?.email || "",
        phoneNo: ByIdDetails?.phoneNo || "",
        profile_picture: ByIdDetails?.image || "",
        dialCode: ByIdDetails?.dialCode || "",
        address: ByIdDetails?.address || "",
        emiratesId: ByIdDetails?.emiratesId || ""
      });
    }
  }, [ByIdDetails]);
  return (
    <div className="main_loyout">
      <div className="dashboard">
        <h1 className="mn_hdng">{state?.type === "edit" ? "Edit Company" : "Company Details"}</h1>
        <Button
          className="btn btn_primary"
          onClick={() => {
            navigate("/manage-company");
          }}
        >
          Back
        </Button>
      </div>
      <Card className="cards">
        <form onSubmit={formik.handleSubmit}>
          <CardContent sx={{ p: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} className="heading">
                <Typography className="custom_label" variant="h5">
                  Edit Company Details
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography className="custom_label">Image</Typography>
                {formik.values.profile_picture ? (
                  <div className="upload_image_preview">
                    <CardMedia
                      component="img"
                      image={formik.values.profile_picture}
                      alt="photo"
                    />
                    <CancelIcon
                      onClick={(e) => {
                        e.preventDefault();
                        setProfilePicture("");
                        formik.setFieldValue("profile_picture", "");
                      }}
                    />
                  </div>
                ) : (
                  <Box className="upload_image">
                    <label htmlFor="icon-button-file">
                      <Input
                        sx={{ display: "none" }}
                        id="icon-button-file"
                        type="file"
                        inputProps={{
                          accept: "image/png,image/jpeg",
                        }}
                        onChange={handleImageUpload}
                      />
                      <Button
                        component="span"
                        className="upload_image_btn"
                      >
                        <img
                          src="/static/images/user_placeholder.png"
                          alt=""
                        />
                        <CameraAltIcon />
                      </Button>
                    </label>
                  </Box>
                )}
              </Grid>
              {ByIdDetails?.companyOnboard ? <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography className="custom_label">Company Name</Typography>

                <TextField
                  hiddenLabel
                  type="text"
                  className="text_field"
                  name="fullName"
                  variant="outlined"
                  fullWidth
                  placeholder="Company Name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.fullName}
                  error={formik.touched.fullName && Boolean(formik.errors.fullName)}
                  helperText={formik.touched.fullName && formik.errors.fullName}
                />
              </Grid> : ""}


              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography className="custom_label">Email</Typography>

                <TextField
                  hiddenLabel
                  type="email"
                  name="email"
                  className="text_field"
                  variant="outlined"
                  fullWidth
                  placeholder="Email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography className="custom_label">Emirates  Id</Typography>

                <TextField
                  hiddenLabel
                  type="text"
                  name="emiratesId"
                  className="text_field"
                  variant="outlined"
                  fullWidth
                  placeholder="Emirates Id"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.emiratesId}
                  error={formik.touched.emiratesId && Boolean(formik.errors.emiratesId)}
                  helperText={formik.touched.emiratesId && formik.errors.emiratesId}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <Typography className="custom_label">
                  Phone Number
                </Typography>
                <PhoneInput
                  value={formik.values?.dialCode + formik.values.phoneNo}
                  country={"India"}

                  placeholder="0 (000) 000-000"
                  enableSearch={true}
                  inputStyle={{ width: "100%" }}
                  onChange={(phone, country) =>
                    handleChangePhone(phone, country)
                  }
                  onBlur={formik.handleBlur}
                />
                {formik.touched.phoneNo && formik.errors.phoneNo ? (
                  <h6 className="err_msg">
                    {formik.touched.phoneNo &&
                      formik.errors.phoneNo.toString()}
                  </h6>
                ) : (
                  ""
                )}
              </Grid>

              <Grid item lg={4} md={4} sm={6} xs={12}>
                <div className="control_group w_50">
                  <Typography className="custom_label">Address</Typography>
                  <Autocomplete
                    onLoad={onLoad}
                    onPlaceChanged={() => onPlaceChanged()}
                  >
                    <TextField
                      className="text_field"
                      hiddenLabel
                      name="address"
                      value={formik.values?.address}
                      placeholder="Enter Address"
                      fullWidth
                      helperText={
                        formik.touched.address &&
                          typeof formik.errors.address === "string"
                          ? formik.errors.address
                          : undefined
                      }
                      onChange={(e) => {
                        if (
                          e.target.value.trim() === "" ||
                          e.target.value === "."
                        ) {
                          formik.setFieldValue("address", "");
                        } else {
                          formik.handleChange(e);
                        }
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                        }
                      }}
                    />
                  </Autocomplete>
                </div>
              </Grid>





            </Grid>
            <div className="form_btn">
              <Button
                size="large"
                type="submit"
                className="btn btn_primary"
                onClick={() => setError(true)}
              >
                Submit
              </Button>
            </div>
          </CardContent>
        </form>
      </Card>
    </div>
  );
};

export default CompanyProfile;
