import emptySplitApi from "../utils/rtk";
import { User, updateProfile } from "../types/User";
import { END_POINTS } from "../helpers";
import { CommonBody } from "../types/General";

type CommonResponseType = {
  statusCode: number;
  message: string;
  s3Location?: string
};


export const authApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({

    getSubscriptionListing: builder.query<CommonResponseType & { data: any }, { page?: number; search?: string; limit: number; startDate: string, endDate: string }>({
      query: ({ page, search, limit, startDate, endDate }) => {
        let url = `${END_POINTS.manageRevenue}?revenueType=1&page=${page}&search=${search}&limit=${limit}&startDate=${startDate}&endDate=${endDate}`;
       

        return {
          url: url,
          method: "GET",
        };
      },
    }),

    getAllTokenListing: builder.query<CommonResponseType & { data: any }, { page?: number; search?: string; limit: number; startDate: string, endDate: string }>({
      query: ({ page, search, limit, startDate, endDate  }) => {
        let url = `${END_POINTS.manageRevenue}?revenueType=3&page=${page}&search=${search}&limit=${limit}&startDate=${startDate}&endDate=${endDate}`;
      

        return {
          url: url,
          method: "GET",
        };
      },
    }),

    getAllBoostProfileListing: builder.query<CommonResponseType & { data: any }, { page?: number; search?: string; limit: number; startDate: string, endDate: string }>({
      query: ({ page, search, limit, startDate, endDate  }) => {
        let url = `${END_POINTS.manageRevenue}?revenueType=2&page=${page}&search=${search}&limit=${limit}&startDate=${startDate}&endDate=${endDate}`;
       

        return {
          url: url,
          method: "GET",
        };
      },
    }),

    getSpecificRevenueById: builder.query<CommonResponseType & { data: any }, { userId: any }>
      ({
        query: ({ userId }) => ({
          url: `${END_POINTS.manageRevenue}/${userId}`,
          method: "GET"
        })
      }),

    exportRevenueCsvSubscribe: builder.query<CommonResponseType & { data: any }, {}>({
      query: ({ }) => {
        let url = END_POINTS.manageRevenue;
        url += "?revenueType=1&isExport=true";

        return {
          url: url,
          method: "GET",
        };
      },
    }),
    exportRevenueCsvToken: builder.query<CommonResponseType & { data: any }, {}>({
      query: ({ }) => {
        let url = END_POINTS.manageRevenue;
        url += "?revenueType=3&isExport=true";

        return {
          url: url,
          method: "GET",
        };
      },
    }),
    exportRevenueCsvBoost: builder.query<CommonResponseType & { data: any }, {}>({
      query: ({ }) => {
        let url = END_POINTS.manageRevenue;
        url += "?revenueType=2&isExport=true";

        return {
          url: url,
          method: "GET",
        };
      },
    }),

  }),
});

export const {
  useLazyGetSubscriptionListingQuery,
  useLazyGetAllTokenListingQuery,
  useLazyGetAllBoostProfileListingQuery,
  useLazyGetSpecificRevenueByIdQuery,
  useLazyExportRevenueCsvSubscribeQuery,
  useLazyExportRevenueCsvTokenQuery,
  useLazyExportRevenueCsvBoostQuery

} = authApi;
