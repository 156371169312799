import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Input,
  TextField,
  Typography,
} from "@mui/material";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { ChangeEvent, useEffect, useState } from "react";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CancelIcon from "@mui/icons-material/Cancel";
import MainContainer from "../../../layout/MainContainer";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from 'yup';
import { UploadMedia } from "../../../utils/uploadMedia";
import { errorToast, successToast } from "../../../helpers/toast";
import { generateEncryptedKeyBody } from "../../../utils/crypto";
import { useFormik } from "formik";
import { useEditUserMutation, useLazyGetSpecificUserByIdQuery } from "../../../services/users";
import { CommonBody } from "../../../types/General";
import Loader from "../../../helpers/constants/Loader";
import { useLazyGetSpecificAgentByIdQuery } from "../../../services/agents";
import { Autocomplete } from "@react-google-maps/api";

const UsersForm = () => {
 
  const [imageId, setImageId] = useState(null);
  const onLoad = (autocompleteObj: any) => {
    setAutocomplete(autocompleteObj);
  };
  const [profile_picture, setProfilePicture] = useState("");
  const [ByIdDetails, setByIdDetails] = useState<any>(null);
  console.log("By2ed2ed",ByIdDetails);
  const navigate = useNavigate();
  const [dialCode, setPhoneCode] = useState("+91");
  const [editUSERbyId] = useEditUserMutation();
  const { id } = useParams();
  const [fetchApi, { isLoading }] = useLazyGetSpecificAgentByIdQuery();
  const [autocomplete, setAutocomplete] = useState<any>();
  const onPlaceChanged = async () => {
    if (autocomplete) {
      let place = await (autocomplete as any).getPlace();

      if (place && place.address_components) {
        let address = place.address_components;

        let state,
          city,
          country,
          zip = "";

        address.forEach(function (component: any) {
          let types = component.types;

          if (
            types.indexOf("locality") > -1 ||
            types.indexOf("administrative_area_level_3") > -1
          ) {
            city = component.long_name;
          }
          if (types.indexOf("postal_code") > -1) {
            zip = component.long_name;
          }
          if (types.indexOf("administrative_area_level_1") > -1) {
            state = component?.long_name || "";
          }
          if (types.indexOf("country") > -1) {
            country = component?.long_name || "";
          }
        });
        var lat = place.geometry.location.lat();
        var lng = place.geometry.location.lng();
        formik.setFieldValue("address", place?.formatted_address);
        formik.setFieldValue("latitude", lat || "");
        formik.setFieldValue("longitude", lng || "");
      }
    }
  };

  const handleImageUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    const file = files?.length ? files[0] : null;

    try {
      if (!file) {
        formik.setFieldValue("profile_picture", "");
        setProfilePicture("");
        setImageId(null);
        return;
      }

      const res = await UploadMedia(file);
      if (res?.statusCode === 200) {
        const imageUrl = res?.data[0]?.location;
        formik.setFieldValue("profile_picture", imageUrl);
        setProfilePicture(imageUrl);
        successToast("Image uploaded successfully.");
      } else {
        errorToast(res?.message);
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      errorToast("Error uploading image. Please try again.");
    }
  };

  const fetchDataById = async (id: any) => {
    try {
      const response = await fetchApi({ userId: id }).unwrap();
      if (response.statusCode === 200) {
        setByIdDetails(response?.data||[]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (id) {
      fetchDataById(id);
    }
  }, [id]);

  const formik = useFormik({
    initialValues: {
      name: ByIdDetails?.name || "",
      email: ByIdDetails?.email || "",
      phoneNo: ByIdDetails?.phoneNo || "",
      profile_picture: ByIdDetails?.image || profile_picture,
      dialCode: dialCode,
      address: ByIdDetails?.address || "",
      emiratesId:ByIdDetails?.emiratesId||""
    },
    validationSchema: Yup.object({
      email: Yup.string()
      .required("Email is required")
      .matches(
        /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Enter a valid email address"
      )
      .matches(
        /^[^\uD800-\uDBFF\uD800-\uDBFF]+$/,  
        "Emojis are not allowed in the email address"
      ),
      name: Yup.string()
        .required("Full name is required")
        .min(2, "Minimum 2 characters are required")
        .max(80, "Maximum 80 characters are allowed")
        .matches(/^[a-zA-Z\s]+$/, "Full name must contain only letters and spaces"),
      phoneNo: Yup.string()
        .required("Phone number is required")
        .min(7, "Phone number must be at least 7 characters")
        .max(20, "Phone number must be at most 20 characters"),
      address: Yup.string()
        .required("Address is required")
        .min(2, "Minimum 2 characters are required")
        .max(200, "Maximum 200 characters are allowed"),
     emiratesId:Yup.string()
     .required("Emirates Id is required")
        .min(2, "Minimum 2 characters are required")
        .max(16, "Maximum 16 characters are allowed"),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      formik.setSubmitting(true);
      let body = {
        name: values.name || "",
        email: values.email || "",
        phoneNo: values.phoneNo || "",
        image: values?.profile_picture || "",
        dialCode: dialCode,
        address: values?.address || "",
        emiratesId:values?.emiratesId||""
      };
  console.log("sdwaqsxdsw",body);
      let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
  
      try {
        const response = await editUSERbyId({ id, body: encryptedBody }).unwrap();
        if (response?.statusCode === 200) {
          successToast("User updated successfully");
          navigate("/manage-users");
        }
      } catch (error: any) {
        console.error(error);
        errorToast(error?.data?.message);
      } finally {
        setSubmitting(false);
      }
    },
  });
  

  const handleChangePhone = (phone: any, country: any) => {
    formik.setFieldValue(
      "phoneNo",
      phone?.replace(country?.dialCode, "")
    );
    setPhoneCode(country?.dialCode.includes("+") ? "" : "+" + country?.dialCode);
  };

  useEffect(() => {
    if (ByIdDetails) {
      formik.setValues({
        name: ByIdDetails?.name || "",
        email: ByIdDetails?.email || "",
        phoneNo: ByIdDetails?.phoneNo || "",
        profile_picture: ByIdDetails?.image || "",
        dialCode: ByIdDetails?.dialCode || "",
        address: ByIdDetails?.address || "",
        emiratesId:ByIdDetails?.emiratesId||""
      });
    }
  }, [ByIdDetails]);

  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Edit User Profile</h1>
          <Loader isLoad={isLoading} />
          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/manage-users");
            }}
          >
            Back
          </Button>
        </div>
        <Card className="cards">
          <form onSubmit={formik.handleSubmit}>
            <CardContent sx={{ p: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography className="custom_label">Image</Typography>
                  {formik.values.profile_picture ? (
                    <div className="upload_image_preview">
                      <CardMedia
                        component="img"
                        image={formik.values.profile_picture}
                        alt="photo"
                      />
                      <CancelIcon
                        onClick={(e) => {
                          e.preventDefault();
                          setProfilePicture("");
                          formik.setFieldValue("profile_picture", "");
                        }}
                      />
                    </div>
                  ) : (
                    <Box className="upload_image">
                      <label htmlFor="icon-button-file">
                        <Input
                          sx={{ display: "none" }}
                          id="icon-button-file"
                          type="file"
                          inputProps={{
                            accept: "image/png,image/jpeg",
                          }}
                          onChange={handleImageUpload}
                        />
                        <Button
                          component="span"
                          className="upload_image_btn"
                        >
                          <img
                            src="/static/images/user_placeholder.png"
                            alt=""
                          />
                          <CameraAltIcon />
                        </Button>
                      </label>
                    </Box>
                  )}
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">Full Name</Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="name"
                    variant="outlined"
                    fullWidth
                    placeholder="Full Name"
                    onBlur={formik.handleBlur}
                    value={formik.values.name}
                    className="text_field"
                    onChange={formik.handleChange}
                    helperText={
                      formik.touched.name && formik.errors.name
                        ? formik.errors.name.toString()
                        : ""
                    }
                  ></TextField>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">Email</Typography>
                  <TextField
                    className="text_field"
                    hiddenLabel
                    type={"email"}
                    name="email"
                    variant="outlined"
                    fullWidth
                    placeholder="Email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    helperText={
                      formik.touched.email && formik.errors.email
                        ? formik.errors.email.toString()
                        : ""
                    }
                  ></TextField>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">
                    Phone Number
                  </Typography>
                  <PhoneInput
                    value={formik.values?.dialCode + formik.values.phoneNo}
                    country={"us"}
                    placeholder="0 (000) 000-000"
                    enableSearch={true}
                    inputStyle={{ width: "100%" }}
                    onChange={(phone, country) =>
                      handleChangePhone(phone, country)
                    }
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.phoneNo && formik.errors.phoneNo ? (
                    <h6 className="err_msg">
                      {formik.touched.phoneNo &&
                        formik.errors.phoneNo.toString()}
                    </h6>
                  ) : (
                    ""
                  )}
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">Emirtes Id</Typography>
                  <TextField
                    className="text_field"
                    hiddenLabel
                    type={"test"}
                    name="emiratesId"
                    variant="outlined"
                    fullWidth
                    placeholder="Emirtes Id"
                    value={formik.values.emiratesId}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    helperText={
                      formik.touched.emiratesId && formik.errors.emiratesId
                        ? formik.errors.emiratesId.toString()
                        : ""
                    }
                  ></TextField>
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <div className="control_group w_50">
                    <Typography className="custom_label">Address</Typography>
                    <Autocomplete
                      onLoad={onLoad}
                      onPlaceChanged={() => onPlaceChanged()}
                    >
                      <TextField
                        className="text_field"
                        hiddenLabel
                        name="address"
                        value={formik.values?.address}
                        placeholder="Enter Address"
                        fullWidth
                        helperText={
                          formik.touched.address &&
                          typeof formik.errors.address === "string"
                            ? formik.errors.address
                            : undefined
                        }
                        onChange={(e) => {
                          if (
                            e.target.value.trim() === "" ||
                            e.target.value === "."
                          ) {
                            formik.setFieldValue("address", "");
                          } else {
                            formik.handleChange(e);
                          }
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            e.preventDefault();
                          }
                        }}
                      />
                    </Autocomplete>
                  </div>
                </Grid>
              </Grid>
              <div className="form_btn">
                <Button
                  size="large"
                  type="submit"
                  className="btn btn_primary"
                >
                  Save
                </Button>
              </div>
            </CardContent>
          </form>
        </Card>
      </div>
    </MainContainer>
  );
};

export default UsersForm;
