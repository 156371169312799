import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Switch,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import moment from "moment";
import MainContainer from "../../layout/MainContainer";
import {usePostAddSubscriptionMutation,
  useLazyGetAllSubscriptionQuery,
  useLazyGetSpecificSubscriptionByIdQuery,
  useLazyDeleteByIdSubscriptionQuery,
  useDeleteByIdSubscriptionQuery}from "../../services/subscription";
import Loader from "../../helpers/constants/Loader";
 
 

const SubscriptionDetails = () => {
  const {_id}=useParams();
  const navigate = useNavigate();
  const UserPlanFeatures = [
    { id: 1, name: "50 posts per hour " },
    { id: 2, name: "unlimited chats with clients " },
    { id: 3, name: "24*7 customer support " },
   
  ];
 
  const[ByIdDetails,setByIdDetails]=useState<any>([]);

  const[fetchApi,{isLoading}]=useLazyGetSpecificSubscriptionByIdQuery();

  const fetchDataById = async (_id: any) => {
    try {
      const response = await fetchApi({ userId: _id }).unwrap();
      console.log("9090", response);
      if (response.statusCode === 200) {
        setByIdDetails(response?.data);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(()=>{
fetchDataById(_id);
  },[_id])
  

  return (
    <MainContainer>
      <Loader isLoad={isLoading}/>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">View Subscription Details</h1>
          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/manage-subscription");
            }}
          >
            Back
          </Button>
        </div>
        <Card className="cards">
          <CardContent sx={{ p: 1 }}>
            <Grid container spacing={2} className="view_box">
              <Grid item xs={12} className="view_box_list">
                <Grid container spacing={3}>
                  <Grid item mt={3} xs={12} className="view_box_list">
                    <Grid container spacing={3}>
                      <Grid item lg={4} md={6} sm={6} xs={12}>
                        <Box>
                          <Typography component="h5">Subscription Name</Typography>
                          <Typography component="p">{ByIdDetails.name||"-"}</Typography>
                        </Box>
                      </Grid>
                      <Grid item lg={4} md={6} sm={6} xs={12}>
                        <Box>
                          <Typography component="h5">Subscription Pack</Typography>
                          <Typography component="p">
                            {ByIdDetails.subscriptionPack === 1 ? "Monthly" :
                             ByIdDetails.subscriptionPack === 3 ? "Quarterly" :
                             ByIdDetails.subscriptionPack === 6 ? "Half-Yearly" :
                             "Yearly"}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item lg={4} md={6} sm={6} xs={12}>
                        <Box>
                          <Typography component="h5">Price</Typography>
                          <Typography component="p">${ByIdDetails.price||"-"}</Typography>
                        </Box>
                      </Grid>
                      <Grid item lg={4} md={6} sm={6} xs={12}>
                        <Box>
                          <Typography component="h5">Token</Typography>
                          <Typography component="p">{ByIdDetails.token||"-"}</Typography>
                        </Box>
                      </Grid>
                      <Grid item lg={4} md={6} sm={6} xs={12}>
                        <Box>
                          <Typography component="h5">Number of Agents</Typography>
                          <Typography component="p">{ByIdDetails.noOfAgents||"-"}</Typography>
                        </Box>
                      </Grid>
                      
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <Box>
                          <Typography component="h5">Features</Typography>
                          <Typography component="p">{ByIdDetails.features||"-"}</Typography>
                        </Box>
                      </Grid>
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <Box>
                          <Typography component="h5">Description</Typography>
                          <Typography component="p">{ByIdDetails.description||"-"}</Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </div>
    </MainContainer>
  );

};

export default SubscriptionDetails;
