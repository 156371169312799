import emptySplitApi from "../utils/rtk";
import { User, updateProfile } from "../types/User";
import { END_POINTS } from "../helpers";
import { CommonBody } from "../types/General";

type CommonResponseType = {
  statusCode: number;
  message: string;
   s3Location?:string
};
 
export const authApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
     
    getAllChats: builder.query<CommonResponseType & { data: any }, { page?: number; search?: string;limit:number}>({
      query: ({ page, search ,limit}) => {
        // let url = END_POINTS.chats;
        let url = `${END_POINTS.chats}?page=${page}&search=${search}&limit=${limit}`;
        // url+=`?limit=10`;
        // if (page && search) {
        //   url += `&page=${page}&search=${search}`;
        // } else if (page) {
        //   url += `&page=${page}`;
        // } else if (search) {
        //   url += `&search=${search}`;
        // }
       
        return {
          url: url,
          method: "GET",
        };
      },
    }),
    
    
   
    getSpecificChatById: builder.query<CommonResponseType & { data: any }, { userId: any}>
    ({
      query: ({ userId }) => ({
        url: `${END_POINTS.chats}/${userId}`,
        method: "GET"
      })
    }),
   
    deleteChatById:  builder.query<CommonResponseType & { data: any }, { userId: any}>({
        query: ({ userId }) => ({
          url: `${END_POINTS.chats}/${userId}`,
          method: "DELETE",
        }),
      }),
 
  }),
});

export const {
    useLazyGetAllChatsQuery,
    useLazyGetSpecificChatByIdQuery,
    useLazyDeleteChatByIdQuery,
 
} = authApi;
