import emptySplitApi from "../utils/rtk";
 
import { END_POINTS } from "../helpers";
import { CommonBody } from "../types/General";

type CommonResponseType = {
  statusCode: number;
  message: string;
   s3Location?:string
};

 
 
interface CategoryResponse extends CommonResponseType {
  data: any;
}

interface QueryParams {
  userId?: any;
  page?:any
}

export const ReelsApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
   
    getAllReels: builder.query<CommonResponseType & { data: any }, { page?: number; search?: string;limit:number }>({
      query: ({ page, search ,limit}) => {
        // let url = END_POINTS.reels;
        let url = `${END_POINTS.reels}?page=${page}&search=${search}&limit=${limit}`;
        // url+="?limit=10";
        // if (page && search) {
        //   url += `&page=${page}&search=${search}`;
        // } else if (page) {
        //   url += `&page=${page}`;
        // } else if (search) {
        //   url += `&search=${search}`;
        // }
       
        return {
          url: url,
          method: "GET",
        };
      },
    }),
    
   
    getSpecificReelsById: builder.query<CategoryResponse, QueryParams>({
      query: ({ userId, page }) => {
        let url = `${END_POINTS.reels}/${userId}`;
        if (page !== undefined) {
          url += `?page=${page}`;
        }
        return {
          url,
          method: 'GET',
        };
      },
    }),
    
      
    deleteReelsById:  builder.query<CommonResponseType & { data: any }, { userId: any}>({
        query: ({ userId }) => ({
          url: `${END_POINTS.reels}/${userId}`,
          method: "DELETE",
        }),
      }),
 
  }),
});

export const {
    useLazyGetAllReelsQuery,
    useLazyGetSpecificReelsByIdQuery,
    useLazyDeleteReelsByIdQuery
} = ReelsApi;
