import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Input,
  TextField,
  Typography,
} from "@mui/material";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { ChangeEvent, useEffect, useState } from "react";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CancelIcon from "@mui/icons-material/Cancel";
import MainContainer from "../../layout/MainContainer";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";

import * as Yup from "yup";
import useAuth from "../../hooks/useAuth";
 
 
import { getFromStorage } from "../../constants/storage";
import { UploadMedia } from "../../utils/uploadMedia";
import { CommonBody } from "../../types/General";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { usePutUpdateProfileMutation } from "../../services/profile";
import { UploadResponse } from "../../types/User";
import { useSelector } from "react-redux";
import { getToken, setCredentials } from "../../reducers/authSlice";
import { useAppDispatch } from "../../hooks/store";
import { errorToast, successToast } from "../../helpers/toast";
import { STORAGE_KEYS } from "../../helpers/constants/storageKeys";
const Profile = () => {
  const token:any=useSelector(getToken);
  const dispatch=useAppDispatch();
  const user = useAuth();
  console.log("rfgurfurfr",user);
  const navigate = useNavigate();
  const [updateprofilre] = usePutUpdateProfileMutation();
  const [dialCode, setPhoneCode] = useState("+91");
  const [profile_picture, setProfilePicture] = useState<any>(null);
  console.log("profile_picture", profile_picture);
  const [imageId, setImageId] = useState(null);

  const handleImageUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    const file = files?.length ? files[0] : null;
  
    try {
      if (!file) {
        formik.setFieldValue("profile_picture", "");
        setProfilePicture("");
        setImageId(null);
        return;
      }
  
      const res = await UploadMedia(file);
      if (res?.statusCode === 200) {
        const imageUrl = res?.data[0]?.location;
        formik.setFieldValue("profile_picture", imageUrl);
        setProfilePicture(imageUrl);
        successToast("Image uploaded successfully.");
      } else {
        errorToast(res?.message);
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      errorToast("Error uploading image. Please try again.");
    }
  };
  

  console.log("ussser", user);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: user?.email || "",
     name:user?.name||"",
      phoneNo: user?.phoneNo || "",
      profile_picture: user?.image || "",
      dialCode: dialCode,

    },
    validationSchema: Yup.object({
      email: Yup.string()
      .required("Email is required")
      .matches(
        /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Enter a valid email address"
      )
      .matches(
        /^[^\uD800-\uDBFF\uD800-\uDBFF]+$/, // Regex to disallow emojis
        "Emojis are not allowed in the email address"
      ),
  name: Yup.string()
        .required("Full Name is required")
        .min(2, "Minimum 2 characters are required")
        .max(25, "Maximum 25 characters are allowed")
        .test(
          "is-not-email",
          "Email address is not allowed in First Name",
          function(value) {
            return !Yup.string()
              .matches(
                /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i
              )
              .isValidSync(value);
          }
        ),
      
      // phoneNo: Yup.string()
      //   .required("Phone number is required")
      //   .min(6, "Phone number must be at least 6 digits")
      //   .max(16, "Phone number must be at least 16 digits"),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      if (!formik.isValid) return;
      try {
        setSubmitting(true);
        const body = {
          email: values.email,
          name: values.name,
          // dialCode: values.dialCode,
          // phoneNo: values.phoneNo,
          image: values.profile_picture,
        };
        const encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
        const response = await updateprofilre(encryptedBody).unwrap();
        if (response?.statusCode === 200) {
          successToast("Profile Updated Successfully.");
          navigate("/dashboard");
        } else {
          if (response?.statusCode === 400 && response?.message == "phone already exists") {
            errorToast("Phone number already exists. Please use a different phone number.");
          } else {
            errorToast(response?.message || "Failed to update profile.");
          }
        }
      } catch (error) {
        errorToast("An error occurred while updating profile.");
      } finally {
        setSubmitting(false);
      }
    },
    


  });


  const handleChangePhone = (phone: any, country: any) => {
    formik.setFieldValue(
      "phoneNo",
      phone?.replace(country?.dialCode, "")
    );
    setPhoneCode(country?.dialCode.includes("+") ? "" : "+" + country?.dialCode);
  };
 
  useEffect(() => {
  
    setProfilePicture(user?.image || "");

  }, [user]);

  return (
    <>
      <MainContainer>
        <div className="main_loyout">
          <div className="dashboard">
            <h1>Edit Profile</h1>
          </div>
          <Card className="cards">
            <form onSubmit={formik.handleSubmit}>
              <CardContent sx={{ p: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography className="custom_label">Image</Typography>
                    {formik.values.profile_picture ? (
                      <div className="upload_image_preview">
                        <CardMedia
                          component="img"
                          image={formik.values.profile_picture}
                          alt="photo"
                        />
                        <CancelIcon
                          onClick={(e) => {
                            e.preventDefault();
                            setProfilePicture("");
                            formik.setFieldValue("profile_picture", "");
                          }}
                        />
                      </div>
                    ) : (
                      <Box className="upload_image">
                        <label htmlFor="icon-button-file">
                          <Input
                            sx={{ display: "none" }}
                            id="icon-button-file"
                            type="file"
                            inputProps={{
                              accept: "image/png,image/jpeg",
                            }}
                            onChange={handleImageUpload}
                          />
                          <Button component="span" className="upload_image_btn">
                            <img
                              src="/static/images/user_placeholder.png"
                              alt=""
                            />
                            <CameraAltIcon />
                          </Button>
                        </label>
                      </Box>
                    )}
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">Full Name</Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="name"
                      variant="outlined"
                      fullWidth
                      placeholder="Full Name"
                      onBlur={formik.handleBlur}
                      value={formik.values.name}
                      className="text_field"
                      onChange={formik.handleChange}
                      helperText={
                        formik.touched.name && formik.errors.name
                          ? formik.errors.name.toString()
                          : ""
                      }
                    ></TextField>
                  </Grid>
                 
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">Email</Typography>
                    <TextField
                      hiddenLabel
                      type="email"
                      name="email"
                      variant="outlined"
                      fullWidth
                      placeholder="Email"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                      className="text_field"
                      disabled
                      helperText={
                        formik.touched.email && formik.errors.email
                          ? formik.errors.email.toString()
                          : ""
                      }
                    ></TextField>
                  </Grid>
                  {/* <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      Phone Number
                    </Typography>
                    <PhoneInput
                      value={ dialCode + formik.values.phoneNo}
                      country={"us"}
                      placeholder="0 (000) 000-000"
                      enableSearch={true}
                      inputStyle={{ width: "100%" }}
                      onChange={(phone, country) =>
                        handleChangePhone(phone, country)
                      }
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.phoneNo && formik.errors.phoneNo ? (
                      <h6 className="err_msg">
                        {formik.touched.phoneNo && formik.errors.phoneNo}
                      </h6>
                    ) : (
                      ""
                    )}
                  </Grid> */}
                </Grid>
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                    disabled={formik.isSubmitting}
                  >
                    Save
                  </Button>
                </div>
              </CardContent>
            </form>
          </Card>
        </div>
      </MainContainer>
    </>
  );
};

export default Profile;
 
