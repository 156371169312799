import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid, Typography
} from "@mui/material";
import MainContainer from "../../layout/MainContainer";
import Messages from "../../features/users/Messages";
import { useLazyGetSpecificPropertyByIdQuery } from "../../services/property";
import { ImageModal } from "../../components";
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const CompanyPropertyDetails = () => {
  const navigate = useNavigate();
  const { _id } = useParams();
  const [openModal, setOpenModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  const handleOpenModal = (imageUrl: string) => {
    setSelectedImage(imageUrl);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedImage(null);
  };


  const [fetchApi, { isLoading }] = useLazyGetSpecificPropertyByIdQuery();

  const [ByIdDetails, setByIdDetails] = useState<any>({});

  const fetchDataById = async (propertyId: any) => {
    try {
      const response = await fetchApi({ userId: _id }).unwrap();
      if (response.statusCode === 200) {
        setByIdDetails(response.data || {});
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchDataById(_id);
  }, [_id]);

  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h2 className="mn_hdng">View Company Property Listing Details</h2>
          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate(`/manage-company`);
            }}
          >
            Back
          </Button>
        </div>
        <Card className="cards">
          <CardContent sx={{ p: 1 }}>
            <Grid container spacing={2} className="view_box">
              <Grid item xs={12} className="view_box_list">
                <Grid container spacing={3}>
                  <Grid item mt={3} xs={12} className="view_box_list">
                    <Grid container spacing={3}>
                      {ByIdDetails?.images?.length > 0 && (
                        <Grid item xs={12}>
                          <Typography component="h5">Images</Typography>
                          <Grid container spacing={2}>
                            {ByIdDetails.images.map((image: string, index: number) => (
                              <Grid item lg={2} md={3} sm={4} xs={6} key={index}>
                                <figure className="profile_img">
                                  <img
                                    style={{ borderRadius: 0, width: "100%" }}
                                    src={image}
                                    alt={`property-${index}`}
                                  />
                                  <div className="overlay">
                                    <img
                                      onClick={() => handleOpenModal(image)}
                                      src='/static/images/visibility.png' alt='img'
                                    />
                                  </div>
                                </figure>
                              </Grid>
                            ))}
                          </Grid>
                        </Grid>
                      )}
                      <Grid item lg={4} md={6} sm={6} xs={12}>
                        <Box>
                          <Typography component="h5" style={{ paddingTop: 10 }}>Property Name</Typography>
                          <Typography component="p">{ByIdDetails?.name || "-"}</Typography>
                        </Box>
                      </Grid>
                      <Grid item lg={4} md={6} sm={6} xs={12}>
                        <Box>
                          <Typography component="h5" style={{ paddingTop: 10 }}>Owner</Typography>
                          <Typography component="p">{ByIdDetails?.userId?.name || "-"}</Typography>
                        </Box>
                      </Grid>
                      <Grid item lg={4} md={6} sm={6} xs={12}>
                        <Box>
                          <Typography component="h5">Price</Typography>
                          <Typography component="p">{ByIdDetails?.price || "-"}</Typography>
                        </Box>
                      </Grid>
                      <Grid item lg={4} md={6} sm={6} xs={12}>
                        <Box>
                          <Typography component="h5">Category</Typography>
                          <Typography component="p">{ByIdDetails?.categoryId?.name || "-"}</Typography>
                        </Box>
                      </Grid>
                      <Grid item lg={4} md={6} sm={6} xs={12}>
                        <Box>
                          <Typography component="h5">Property Type</Typography>
                          <Typography component="p">{ByIdDetails?.type === 1 ? "Buy" : ByIdDetails?.type === 2 ? "Rent" : "-"}</Typography>
                        </Box>
                      </Grid>
                      <Grid item lg={4} md={6} sm={6} xs={12}>
                        <Box>
                          <Typography component="h5">Description</Typography>
                          <Typography component="p">{ByIdDetails?.description || "-"}</Typography>
                        </Box>
                      </Grid>
                      <Grid item lg={4} md={6} sm={6} xs={12}>
                        <Box>
                          <Typography component="h5">Specifications</Typography>
                          <Typography component="p">{ByIdDetails?.specifications || "-"}</Typography>
                        </Box>
                      </Grid>
                      <Grid item lg={4} md={6} sm={6} xs={12}>
                        <Box>
                          <Typography component="h5">Key Features</Typography>
                          <Typography component="p">{ByIdDetails?.keyFeature || "-"}</Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <div style={{ paddingTop: 20 }}>
          <h1 className="mn_hdng" style={{ fontSize: 21 }}> Property Chat Details</h1>
        </div>
        <Messages />
      </div>
      <ImageModal open={openModal} handleClose={handleCloseModal} image={selectedImage} />
    </MainContainer>
  );
};

export default CompanyPropertyDetails;
