 
import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Box,
  Switch,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import SearchBar2 from "../../components/SearchBar2";
import { useEditUserMutation, useLazyDeleteByIduserQuery, useLazyGetAllUserQuery } from "../../services/users";
import Pagination from "../../components/Pagination";
import WarnModal from "../../components/WarnModal";
import { errorToast, successToast } from "../../helpers/toast";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";
import { isValidInput } from "../../utils/validation";
import Loader from "../../helpers/constants/Loader";
import { useLazyExportUserCsvQuery } from "../../services/dashboard";

const ManageUsers = () => {
  const navigate = useNavigate();
  const role = 4;
  const [getallusers, { isLoading }] = useLazyGetAllUserQuery();
  const [rows, setRows] = useState<any>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState<number>(0);
   const [limit, setLimit] = useState<number>(10);
  let totalPages = Math.ceil(totalCount / limit);
  const [editUSERbyId] = useEditUserMutation();
  const [deleteUserById] = useLazyDeleteByIduserQuery();
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState<number>(0);
  const onPageChange = (newPage: number) => {
    setPage(newPage);
    setRows([]);
  };
  const [exportCsv] = useLazyExportUserCsvQuery();
  const handleExportCsv = async () => {
    try {
      const res = await exportCsv({}).unwrap();
      if (res?.statusCode === 200) {
        window.open(res?.data);
        successToast("CSV exported successfully");
      } else {
        errorToast("Error: s3Location not found in response");
      }
    } catch (error: any) {
      errorToast(error?.message);
    }
  };
  const fetchData = async (role: number, page: number, search: string) => {
    try {
      const response = await getallusers({ page:page, search:search,limit:limit }).unwrap();
      if (response.statusCode === 200) {
        setRows(response?.data?.user || []);
        setTotalCount(response?.data?.totalCount);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleDeleteUser = async (userId: any) => {
    try {
      const response = await deleteUserById({ userId }).unwrap();
      if (response?.statusCode === 200) {
        successToast("User deleted successfully");
        fetchData(role, page, searchTerm);
      }
    } catch (error: any) {
      console.error(error);
      errorToast(error?.message || "");
    }
  };
  const changeUserStatus = async (userId: string, userStatus: boolean) => {
    try {
      let body = {
        isBlocked: String(userStatus),
      };
      let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
      const response = await editUSERbyId({ body: encryptedBody, id: userId }).unwrap();
      if (response?.statusCode === 200) {
        successToast("Status updated successfully");
      }
    } catch (error: any) {
      errorToast(error?.message);
      console.error(error);
    }
  };

  const label = { inputProps: { "aria-label": "Switch demo" } };

  useEffect(() => {
    fetchData(role, page, debouncedSearchTerm);
  }, [role, page, debouncedSearchTerm,limit]);

  return (
    <MainContainer>
      <Loader isLoad={isLoading} />
      <div className="main_layout">
        <div className="dashboard">
        <h2 className="mn_hdng">Manage Users</h2>
        </div>
        <Card className="cards">
          <Box className="cards_header">
            <SearchBar2
              value={searchTerm}
              searchTerm={searchTerm}
              onCross={() => setSearchTerm("")}
              setDebouncedSearchTerm={setDebouncedSearchTerm}
              onChange={(val: any) => {
                if (isValidInput(val.target.value)) {
                  setSearchTerm(val.target.value);
                }
              }}
            />
            <Box className="cards_header_right">
              <Button className="btn btn_primary" onClick={handleExportCsv}>
                <FileDownloadIcon /> Export CSV
              </Button>
            </Box>
          </Box>
          <Box className="table_container" sx={{ height: '600px', overflowY: 'auto' }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">S.No</TableCell>
                  <TableCell>Image</TableCell>
                  <TableCell>Full name</TableCell>
                  <TableCell>Phone Number</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Token</TableCell>
                  <TableCell>Address</TableCell>
                  <TableCell>Account Status</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.length == 0 ? (
                  <TableRow>
                    <TableCell colSpan={9} align="center">
                      No user found
                    </TableCell>
                  </TableRow>
                ) : (
                  rows.map((row: any, i: any) => (
                    <TableRow key={row.name}>
                      <TableCell align="center">{(page - 1) * 10 + i + 1}</TableCell>
                      <TableCell>
                        <figure className="user_img">
                          <img src={row?.image || "/static/images/user_placeholder.png"} alt="" />
                        </figure>
                      </TableCell>
                      <TableCell>{row?.name || "-"}</TableCell>
                      <TableCell>{(row?.dialCode + row?.phoneNo) || "-"}</TableCell>
                      <TableCell>{row?.email || "-"}</TableCell>
                      <TableCell>{row?.token || "-"}</TableCell>
                      <TableCell>{row?.address || "-"}</TableCell>
                      <TableCell>
                        <Switch
                          defaultChecked={!row.isBlocked}
                          onChange={(e) => {
                            const isChecked = !e.target.checked;
                            changeUserStatus(row._id, isChecked);
                          }}
                          size="small"
                        />
                      </TableCell>
                      <TableCell>
                        <Box className="table_actions">
                          <IconButton onClick={() => navigate(`/manage-users/details/${row?._id}`)}>
                            <VisibilityIcon />
                          </IconButton>
                          <IconButton onClick={() => navigate(`/manage-users/usersform/${row?._id}`)}>
                            <ModeEditIcon />
                          </IconButton>
                          <IconButton onClick={() => {
                            setOpen(true);
                            setSelectedId(row?._id);
                          }}>
                            <DeleteIcon />
                          </IconButton>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </Box>
        </Card>
      </div>
      {rows?.length > 0 ? (
        <Pagination
          setPage={setPage}
          module={rows}
          page={page}
          onPageChange={onPageChange}
          totalPages={totalPages}
          limit={limit}
          setLimit={setLimit}
        />
      ) : (
        ""
      )}
      <WarnModal
        open={open}
        setOpen={setOpen}
        handleDelete={() => handleDeleteUser(selectedId)}
        name="User"
      />
    </MainContainer>
  );
};

export default ManageUsers;
