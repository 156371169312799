// eslint-disable-next-line import/no-extraneous-dependencies
import crypto from "crypto-js";

export function generateSEKAndHash(token: any) {
  const iv = "3t4p2jkrtyhsoirp".slice(0, 16);
  try {
    const key = crypto.lib.WordArray.random(32);
    let dataPayload = {};
    if (token === "" || token === null || token === undefined) {
      dataPayload = {
        appKey: new Date(),
      };
    } else {
      dataPayload = {
        authorization: `Bearer ${token}`,
        appKey: new Date(),
      };
    }
    let appkey = JSON.stringify(dataPayload);

    const encrypted = crypto.AES.encrypt(appkey, key, {
      iv: crypto.enc.Utf8.parse(iv),
      mode: crypto.mode.CBC,
    });
    const encryptedHex = encrypted.ciphertext.toString();
    const keyHash = key.toString();

    return {
      hash: keyHash,
      sek: encryptedHex,
    };
  } catch (error) {
    console.error("", error);
    return null;
  }
}

export function generateEncryptedKeyBody(body: any) {
  const iv = "3t4p2jkrtyhsoirp".slice(0, 16);
  try {
    const key = crypto.lib.WordArray.random(32);
    let dataPayload = JSON.stringify(body);

    const encrypted = crypto.AES.encrypt(dataPayload, key, {
      iv: crypto.enc.Utf8.parse(iv),
      mode: crypto.mode.CBC,
    });
    const encryptedHex = encrypted.ciphertext.toString();
    const keyHash = key.toString();

    return {
      hash: keyHash,
      sek: encryptedHex,
    };
  } catch (error) {
    console.error("", error);
    return null;
  }
}
