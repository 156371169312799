import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Box,
  Switch,
  Button,
  Select,
  MenuItem,
  SelectChangeEvent,
  Typography,
  Tabs,
  Tab,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import SearchBar from "../../components/SearchBar";
import {
  useEditUserMutation, useLazyDeleteByIduserQuery, useLazyGetPendingCompanyQuery, useLazyGetAllUserQuery, useLazyGetApprovedCompanyQuery,
  useLazyGetRejectedCompanyQuery, useLazyGetPendingAgentQuery,
  useLazyGetApprovedAgentQuery,
  useLazyGetRejectedAgentQuery
} from "../../services/users";

import SearchBar2 from "../../components/SearchBar2";
import { isValidInput } from "../../utils/validation";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";
import { errorToast, successToast } from "../../helpers/toast";
import Pagination from "../../components/Pagination";
import WarnModal from "../../components/WarnModal";
import Loader from "../../helpers/constants/Loader";
import { useLazyExportAgentCsvQuery } from "../../services/dashboard";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ManageAgents = () => {
  const navigate = useNavigate();
  let role = 5;
  const [rows1, setRows] = useState<any>([]);
  const [rowsApproved, setRowsApproved] = useState<any>([]);
  const [rowsRejected, setRowsRejected] = useState<any>([]);
  console.log("company", rows1);
  const [editUSERbyId] = useEditUserMutation();
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount1] = useState<number>(0);
  const [totalCount2, setTotalCount2] = useState<number>(0);
  console.log("tt2", totalCount2);
  const [totalCount3, setTotalCount3] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  let totalPages = Math.ceil(totalCount / limit);
  let totalPages2 = Math.ceil(totalCount2 / limit);
  let totalPages3 = Math.ceil(totalCount3 / limit);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [open, setOpen] = useState(false);
  const [editCompanybyId] = useEditUserMutation();
  const [selectedId, setSelectedId] = useState<number>(0);
  const onPageChange = (newPage: number) => {
    setPage(newPage);
    setRows([]);
  };
  const [exportCsv] = useLazyExportAgentCsvQuery();
  const [getPendingCompany, { isLoading }] = useLazyGetPendingAgentQuery();
  const [getApprovedCompany] = useLazyGetApprovedAgentQuery();
  const [getRejectedCompany] = useLazyGetRejectedAgentQuery();
  const [deleteUserById] = useLazyDeleteByIduserQuery();
  const handleExportCsv = async () => {
    try {
      const res = await exportCsv({

      }).unwrap();
      console.log("eugded", res);




      if (res?.statusCode === 200) {
        window.open(res?.data);
        successToast("CSV exported successfully")
      } else {

        errorToast("Error: s3Location not found in response");
      }
    } catch (error: any) {
      errorToast(error?.message);
    }
  };
  const fetchData = async (role: number, page: number, search: string) => {
    try {
      const response = await getPendingCompany({ page: page, search: search, limit: limit }).unwrap();
      if (response.statusCode === 200) {
        setRows(response?.data?.user || []);
        setTotalCount1(response?.data?.totalCount);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const fetchApproved = async (role: number, page: number, search: string) => {
    try {
      const response = await getApprovedCompany({ page: page, search: search, limit: limit }).unwrap();
      if (response.statusCode === 200) {
        setRowsApproved(response?.data?.user || []);
        setTotalCount2(response?.data?.totalCount);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const fetchRejectedData = async (role: number, page: number, search: string) => {
    try {
      const response = await getRejectedCompany({ page: page, search: search, limit: limit }).unwrap();
      if (response.statusCode === 200) {
        setRowsRejected(response?.data?.user || []);
        setTotalCount3(response?.data?.totalCount);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const changeUserStatus = async (userId: string, userStatus: boolean) => {
    try {
      let body = {
        isBlocked: String(userStatus)
      }
      let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
      const response = await editUSERbyId({ body: encryptedBody, id: userId }).unwrap();
      if (response?.statusCode === 200) {
        successToast("Status updated successfully");
      }
    }
    catch (error: any) {
      errorToast(error?.message);
      console.error(error);
    }
  }

  const handleDeleteCompany = async (userId: any) => {
    try {
      const response = await deleteUserById({ userId }).unwrap();
      if (response?.statusCode === 200) {
        successToast("Agent deleted successfully");
        fetchData(role, page, searchTerm);
      }
    } catch (error: any) {
      console.error(error);
      errorToast(error?.message || "");
    }
  };
  const [receiver, setReceiver] = useState<string>("1");
  const handleReceiverChange = async (event: SelectChangeEvent, userId: any) => {
    const isAdminAprooved = Number(event.target.value);
    const body = {
      isAdminAprooved,
    };
    console.log("appr", body);

    try {
      let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
      const response = await editCompanybyId({ body: encryptedBody, id: userId }).unwrap();

      if (response?.statusCode === 200) {
        if (isAdminAprooved === 2) {
          successToast("Agent approved successfully");
        } else if (isAdminAprooved === 3) {
          successToast("Agent rejected successfully");
        } else {
          successToast(response?.message || "Request approved successfully");
        }

        fetchData(role, page, debouncedSearchTerm);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };




  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    setPage(1);
  };


  const formatDate = (dateString: string | number | Date) => {
    if (!dateString) return "-";
    const date = new Date(dateString);
    return date.toLocaleDateString();
  };


  const label = { inputProps: { "aria-label": "Switch demo" } };

  useEffect(() => {
    if (value === 0) {
      fetchData(role, page, debouncedSearchTerm);
    } else if (value === 1) {
      fetchApproved(role, page, debouncedSearchTerm);
    } else if (value === 2) {
      fetchRejectedData(role, page, debouncedSearchTerm);
    }
  }, [role, page, debouncedSearchTerm, value, limit]);

  return (
    <MainContainer>
      <Loader isLoad={isLoading} />


      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Manage Agents</h1>
        </div>
        <Card className="cards">
          <Box className="custom_tabs">
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="basic tabs example"
              className="custom_tabs_links"
            >
              <Tab label="Pending" {...a11yProps(0)} />
              <Tab label="Approved" {...a11yProps(1)} />
              <Tab label="Rejected" {...a11yProps(2)} />
            </Tabs>
            <CustomTabPanel value={value} index={0}>
              <Box className="cards_header">
                <SearchBar2
                  value={searchTerm}
                  searchTerm={searchTerm}
                  onCross={() => setSearchTerm("")}
                  setDebouncedSearchTerm={setDebouncedSearchTerm}
                  onChange={(val: any) => {
                    if (isValidInput(val.target.value)) {
                      setSearchTerm(val.target.value);
                    }
                  }}
                />
                <Box className="cards_header_right">
                  <Button
                    className="btn btn_primary"
                    onClick={handleExportCsv}
                  >
                    <FileDownloadIcon /> Export CSV
                  </Button>
                </Box>
              </Box>
              <TableContainer className="table_container">
                <Box className="heading"></Box>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">S.No</TableCell>
                      <TableCell>Image</TableCell>
                      <TableCell>Full name</TableCell>
                      <TableCell>Phone Number</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Company Name</TableCell>
                      {/* <TableCell>Tokens</TableCell>
                  <TableCell>Subscription</TableCell>
                  <TableCell>Subscription Expiry Date</TableCell> */}
                      <TableCell>Address</TableCell>
                      <TableCell>Request</TableCell>

                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>


                  <TableBody>
                    {rows1.length == "0" ? (
                      <TableRow>
                        <TableCell colSpan={12} align="center">
                          No agent found
                        </TableCell>
                      </TableRow>
                    ) :
                      (rows1.map((row: any, i: number) => (
                        <TableRow key={row.i}>
                          <TableCell align="center">{(page - 1) * 10 + i + 1}</TableCell>
                          <TableCell>
                            <figure className="user_img">
                              <img src={row?.image || `/static/images/user_placeholder.png`} alt="" />
                            </figure>
                          </TableCell>
                          <TableCell>{row?.name || "-"}</TableCell>

                          <TableCell>{(row?.dialCode + row?.phoneNo) || "-"}</TableCell>
                          <TableCell>{row?.email || "-"}</TableCell>
                          <TableCell>{row?.companyName || "-"}</TableCell>
                          {/* <TableCell>{row?.token||"-"}</TableCell>
                    <TableCell>{row?.subscription||"-"}</TableCell>
                    <TableCell>{row?.expiryDate||"-"}</TableCell> */}
                          <TableCell>{row?.address || "-"}</TableCell>

                          <TableCell>
                            <Select
                              fullWidth
                              className="select_div reqst_Select"
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={receiver}
                              inputProps={{
                                "aria-label": "Without label",
                              }}
                              displayEmpty
                              onChange={(value: any) => {
                                handleReceiverChange(value, row?._id);
                              }}
                            >

                              <MenuItem value="1" disabled>Pending</MenuItem>
                              <MenuItem value="2">Approve</MenuItem>
                              <MenuItem value="3">Reject</MenuItem>
                            </Select>
                          </TableCell>

                          <TableCell>
                            <Box className="table_actions">
                              <IconButton
                                onClick={() => navigate(`/manage-agents/details/${row?._id}`)}
                              >
                                <VisibilityIcon />
                              </IconButton>
                              <IconButton
                                onClick={() =>
                                  navigate(`/manage-agents/edit/${row?._id}`)
                                }
                              >
                                <ModeEditIcon />
                              </IconButton>
                              <IconButton onClick={() => {
                                setOpen(true);
                                setSelectedId(row?._id)
                              }}>
                                <DeleteIcon />
                              </IconButton>
                            </Box>
                          </TableCell>
                        </TableRow>
                      )))}
                  </TableBody>

                </Table>
              </TableContainer>
              {rows1?.length > 0 ? (
                <Pagination
                  setPage={setPage}
                  module={rows1}
                  page={page}
                  onPageChange={onPageChange}
                  totalPages={totalPages}
                  limit={limit}
                  setLimit={setLimit}
                />
              ) : (
                ""
              )}
              <WarnModal
                open={open}
                setOpen={setOpen}
                handleDelete={() => handleDeleteCompany(selectedId)}
                name="User"
              />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <Box className="cards_header">
                <SearchBar2
                  value={searchTerm}
                  searchTerm={searchTerm}
                  onCross={() => setSearchTerm("")}
                  setDebouncedSearchTerm={setDebouncedSearchTerm}
                  onChange={(val: any) => {
                    if (isValidInput(val.target.value)) {
                      setSearchTerm(val.target.value);
                    }
                  }}
                />

                <Box className="cards_header_right">
                  <Button
                    className="btn btn_primary"
                    onClick={handleExportCsv}
                  >
                    <FileDownloadIcon /> Export CSV
                  </Button>
                </Box>
              </Box>
              <TableContainer className="table_container">
                <Box className="heading"></Box>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">S.No</TableCell>
                      <TableCell>Image</TableCell>
                      <TableCell>Full name</TableCell>
                      <TableCell>Phone Number</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Company Name</TableCell>
                      <TableCell>Tokens</TableCell>
                      <TableCell>Subscription</TableCell>
                      <TableCell>Subscription Expiry Date</TableCell>
                      <TableCell>Address</TableCell>
                      <TableCell>Account Status</TableCell>

                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>


                  <TableBody>
                    {rowsApproved.length == "0" ? (
                      <TableRow>
                        <TableCell colSpan={12} align="center">
                          No agent found
                        </TableCell>
                      </TableRow>
                    ) : (rowsApproved.map((row: any, i: number) => (
                      <TableRow key={row.i}>
                        <TableCell align="center">{(page - 1) * 10 + i + 1}</TableCell>
                        <TableCell>
                          <figure className="user_img">
                            <img src={row?.image || `/static/images/user_placeholder.png`} alt="" />
                          </figure>
                        </TableCell>
                        <TableCell>{row?.name || "-"}</TableCell>

                        <TableCell>{(row?.dialCode + row?.phoneNo) || "-"}</TableCell>
                        <TableCell>{row?.email || "-"}</TableCell>
                        <TableCell>{row?.companyName || "-"}</TableCell>
                        <TableCell>{row?.token || "-"}</TableCell>
                        <TableCell>{row?.subscriptionName || "-"}</TableCell>
                        <TableCell>{formatDate(row?.usersSubscriptionEndDate?.subscriptionEndDate)}</TableCell>
                        <TableCell>{row?.address || "-"}</TableCell>
                        <TableCell>
                          <Switch
                            defaultChecked={!row.isBlocked}
                            onChange={(e) => {
                              const isChecked = !e.target.checked;
                              changeUserStatus(row._id, isChecked);
                            }}
                            size="small"
                          />
                        </TableCell>


                        <TableCell>
                          <Box className="table_actions">
                            <IconButton
                              onClick={() => navigate(`/manage-agents/details/${row?._id}`)}
                            >
                              <VisibilityIcon />
                            </IconButton>
                            <IconButton
                              onClick={() =>
                                navigate(`/manage-agents/edit/${row?._id}`)
                              }
                            >
                              <ModeEditIcon />
                            </IconButton>
                            <IconButton onClick={() => {
                              setOpen(true);
                              setSelectedId(row?._id)
                            }}>
                              <DeleteIcon />
                            </IconButton>
                          </Box>
                        </TableCell>
                      </TableRow>
                    )))}
                  </TableBody>
                </Table>
              </TableContainer>
              {rowsApproved?.length > 0 ? (
                <Pagination
                  setPage={setPage}
                  module={rowsApproved}
                  page={page}
                  onPageChange={onPageChange}
                  totalPages={totalPages2}
                  limit={limit}
                  setLimit={setLimit}
                />
              ) : (
                ""
              )}
              <WarnModal
                open={open}
                setOpen={setOpen}
                handleDelete={() => handleDeleteCompany(selectedId)}
                name="Agent"
              />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <Box className="cards_header">
                <SearchBar2
                  value={searchTerm}
                  searchTerm={searchTerm}
                  onCross={() => setSearchTerm("")}
                  setDebouncedSearchTerm={setDebouncedSearchTerm}
                  onChange={(val: any) => {
                    if (isValidInput(val.target.value)) {
                      setSearchTerm(val.target.value);
                    }
                  }}
                />
                <Box className="cards_header_right">
                  <Button
                    className="btn btn_primary"
                    onClick={handleExportCsv}
                  >
                    <FileDownloadIcon /> Export CSV
                  </Button>
                </Box>
              </Box>
              <TableContainer className="table_container">
                <Box className="heading"></Box>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">S.No</TableCell>
                      <TableCell>Image</TableCell>
                      <TableCell>Full name</TableCell>
                      <TableCell>Phone Number</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Company Name</TableCell>
                      {/* <TableCell>Tokens</TableCell>
                  <TableCell>Subscription</TableCell>
                  <TableCell>Subscription Expiry Date</TableCell> */}
                      <TableCell>Address</TableCell>


                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>


                  <TableBody>
                    {rowsRejected.length == "0" ? (<TableRow>
                      <TableCell colSpan={11} align="center">
                        No agent found
                      </TableCell>
                    </TableRow>) : (rowsRejected.map((row: any, i: number) => (
                      <TableRow key={row.i}>
                        <TableCell align="center">{(page - 1) * 10 + i + 1}</TableCell>
                        <TableCell>
                          <figure className="user_img">
                            <img src={row?.image || `/static/images/user_placeholder.png`} alt="" />
                          </figure>
                        </TableCell>
                        <TableCell>{row?.name || "-"}</TableCell>

                        <TableCell>{(row?.dialCode + row?.phoneNo) || "-"}</TableCell>
                        <TableCell>{row?.email || "-"}</TableCell>
                        <TableCell>{row?.companyName || "-"}</TableCell>
                        {/* <TableCell>{row?.token||"-"}</TableCell>
                    <TableCell>{row?.subscriptionName||"-"}</TableCell>
                    <TableCell>{row?.expiryDate||"-"}</TableCell> */}
                        <TableCell>{row?.address || "-"}</TableCell>



                        <TableCell>
                          <Box className="table_actions">
                            <IconButton
                              onClick={() => navigate(`/manage-agents/details/${row?._id}`)}
                            >
                              <VisibilityIcon />
                            </IconButton>
                            <IconButton
                              onClick={() =>
                                navigate(`/manage-agents/edit/${row?._id}`)
                              }
                            >
                              <ModeEditIcon />
                            </IconButton>
                            <IconButton onClick={() => {
                              setOpen(true);
                              setSelectedId(row?._id)
                            }}>
                              <DeleteIcon />
                            </IconButton>
                          </Box>
                        </TableCell>
                      </TableRow>
                    )))}
                  </TableBody>
                </Table>
              </TableContainer>
              {rowsRejected?.length > 0 ? (
                <Pagination
                  setPage={setPage}
                  module={rowsRejected}
                  page={page}
                  onPageChange={onPageChange}
                  totalPages={totalPages3}
                  limit={limit}
                  setLimit={setLimit}
                />
              ) : (
                ""
              )}
              <WarnModal
                open={open}
                setOpen={setOpen}
                handleDelete={() => handleDeleteCompany(selectedId)}
                name="Agent"
              />
            </CustomTabPanel>
          </Box>
        </Card>
      </div>

    </MainContainer>
  );
};

export default ManageAgents;


