import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Button,
  Card,
  FormControlLabel,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Box from "@mui/material/Box";
import SearchBar from "../../components/SearchBar";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useLazyDeleteChatByIdQuery, useLazyGetAllChatsQuery } from "../../services/chat";
import WarnModal from "../../components/WarnModal";
import Pagination from "../../components/Pagination";
import { errorToast, successToast } from "../../helpers/toast";
import { format } from 'date-fns';
import SearchBar2 from "../../components/SearchBar2";
import { isValidInput } from "../../utils/validation";
import Loader from "../../helpers/constants/Loader";


const ManageChat = () => {
  const navigate = useNavigate();
  const [chatListing, { isLoading }] = useLazyGetAllChatsQuery();
  const [rows, setRows] = useState<any>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [open, setOpen] = useState(false);
  const [deleteChatById] = useLazyDeleteChatByIdQuery();
  const [selectedId, setSelectedId] = useState<number>(0);
  const onPageChange = (newPage: number) => {
    setPage(newPage);
    setRows([]);
  };
  const [limit, setLimit] = useState<number>(10);
  let totalPages = Math.ceil(totalCount / limit);
  function createData(
    id: number,
    date: string,
    title: string,
    message: string,
    orders: boolean,
    status: boolean // Change 'statusUpdates' to 'status'
  ) {
    return { id, date, title, message, orders, status };
  }


  const fetchApi = async (page: any, search: any) => {
    try {
      const response = await chatListing({ page: page, search: search, limit: limit }).unwrap();
      if (response?.statusCode === 200) {
        setRows(response?.data?.chats || []);
        setTotalCount(response?.data?.count);
      }
      else {

      }
    }
    catch (e) {

    }
  }
  const formatDateTime = (dateString: string | number | Date) => {
    if (!dateString) return "-";
    const date = format(new Date(dateString), 'yyyy-MM-dd');
    const time = format(new Date(dateString), 'HH:mm:ss');
    return ` ${date}, Time: ${time}`;
  };

  const handleDeleteChat = async (userId: any) => {
    try {
      const response = await deleteChatById({ userId }).unwrap();
      if (response?.statusCode === 200) {
        successToast("Chat deleted successfully");
        fetchApi(page, searchTerm);
      }
    } catch (error: any) {
      console.error(error);
      errorToast(error?.data?.message || "");
    }
  };
  useEffect(() => {
    fetchApi(page, debouncedSearchTerm);
  }, [page, debouncedSearchTerm, limit]);
  return (
    <MainContainer>
      <Loader isLoad={isLoading} />
      <div className="main_layout">
        <div className="dashboard">
          <h3 className="mn_hdng">Manage Chat</h3>
        </div>
        <Card className="cards">
          <Box className="cards_header">
            <SearchBar2
              value={searchTerm}
              searchTerm={searchTerm}
              onCross={() => setSearchTerm("")}
              setDebouncedSearchTerm={setDebouncedSearchTerm}
              onChange={(val: any) => {
                if (isValidInput(val.target.value)) {
                  setSearchTerm(val.target.value);
                }
              }}
            />
            <Box className="cards_header_right">

            </Box>
          </Box>
          <TableContainer className="table_container">
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">S.No</TableCell>
                  <TableCell>From</TableCell>
                  <TableCell>To</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Property </TableCell>
                  {/* <TableCell>Title</TableCell> */}


                  <TableCell align="center">Action</TableCell>

                </TableRow>
              </TableHead>
              <TableBody>
                {rows.length == 0 ? (
                  <TableRow>
                    <TableCell colSpan={9} align="center">
                      No chat found
                    </TableCell>
                  </TableRow>
                ) : (rows.map((row: any, index: any) => (
                  <TableRow key={row?._id}>
                    <TableCell align="center">{(page - 1) * 10 + index + 1}</TableCell>
                    <TableCell>{row?.senders?.name || "-"}</TableCell>
                    <TableCell>{row?.receivers?.name || "-"}</TableCell>
                    <TableCell>{formatDateTime(row?.createdAt) || "-"}</TableCell>
                    <TableCell>{row?.properties?.name || "Personal chat"}</TableCell>


                    <TableCell>
                      <Box className="table_actions">
                        <IconButton
                          onClick={() => navigate(`/manage-chat/details/${row?.connectionId}`)}
                        >
                          <VisibilityIcon />

                        </IconButton>
                        <IconButton
                          onClick={() => {
                            setSelectedId(row?.chatId);
                            setOpen(true)
                          }
                          }>
                          <DeleteIcon />
                        </IconButton>

                      </Box>
                    </TableCell>

                  </TableRow>
                )))}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </div>
      {rows?.length > 0 ? (
        <Pagination
          setPage={setPage}
          module={rows}
          page={page}
          onPageChange={onPageChange}
          totalPages={totalPages}
          limit={limit}
          setLimit={setLimit}
        />
      ) : (
        ""
      )}
      <WarnModal
        open={open}
        setOpen={setOpen}
        handleDelete={() => handleDeleteChat(selectedId)}
        name="Chat"
      />
    </MainContainer>
  );
};

export default ManageChat;
