import { Box, Button, Card, CardContent, Grid, Typography } from '@mui/material';
import React from 'react'
import MainContainer from '../../layout/MainContainer';
import { useNavigate } from 'react-router-dom';
 
 const UserTokenHistoryDetails=()=>{
    const navigate=useNavigate();
    return (
        <MainContainer>
        <div className="main_loyout">
          <div className="dashboard">
            <h1 className="mn_hdng">View Token History Details</h1>
            <Button
              className="btn btn_primary"
              onClick={() => {
                navigate("/manage-users/details");
              }}
            >
              Back
            </Button>
          </div>
        <Card className="cards">
          <CardContent sx={{ p: 1 }}>
            <Grid container spacing={2} className="view_box">
              
              <Grid item xs={10} className="view_box_list">
                <Grid container spacing={3}>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Purchase History</Typography>
                      <Typography component="p">17 June 2023</Typography>
                    </Box>
                   
                        </Grid>
                        
                        <Grid item lg={4} md={4} sm={6} xs={12}>
                            <Box>
                      <Typography component="h5">Spends</Typography>
                      <Typography component="p">5</Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                            <Box>
                      <Typography component="h5">Available</Typography>
                      <Typography component="p">11</Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Spend History</Typography>
                      <Typography component="p">
                        <ul>
                          <li style={{marginLeft:-20}}>Added Property on 17th June,2023</li>
 
                        </ul>
                      </Typography>
                    </Box>
                  </Grid>

    
                
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        </div>
    </MainContainer>
      );
 }
export default UserTokenHistoryDetails