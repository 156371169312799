import { readConfigFile } from "typescript";

 
  import * as Yup from "yup";
  import { useFormik } from "formik";
  import MainContainer from "../../layout/MainContainer";
  import { useNavigate, useParams } from "react-router-dom";
  import CloseIcon from "@mui/icons-material/Close";
  import { ChangeEvent, useEffect, useState } from "react";
 
 
 
import { Box, Button, Card, CardContent, Grid, Input, TextField, Typography } from "@mui/material";
import { useEditBoostProfileMutation, useLazyGetSpecificBoostProfileByIdQuery, usePostAddboostProfileMutation } from "../../services/boostProfile";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";
import { errorToast, successToast } from "../../helpers/toast";
import { isNumber } from "../../utils/validation";
  
  const AddBoost = () => {
    const {id}=useParams();
    const [displayEditText,setDisplayEditText]=useState(true);
    const navigate = useNavigate();
    const [ByIdDetails, setByIdDetails] = useState<any>(null);
   const[addBoost,{isLoading}]=usePostAddboostProfileMutation();
   const[updateBoost]=useEditBoostProfileMutation();
    
 const[fetchById]=useLazyGetSpecificBoostProfileByIdQuery();
 
 
 
 

    const formik = useFormik({
      enableReinitialize: true,
      initialValues: {
        title: "",
        description:"",
        price:"",
        validity:""
      },
      validationSchema: Yup.object({
        title: Yup.string()
          .required("This field is required")
          .max(40, "Maximum 40 characters are allowed")
          .min(2, "Minimum 2 characters are required"),
          description: Yup.string()
          .required("This field is required")
          .max(200, "Maximum 200 characters are allowed")
          .min(2, "Minimum 2 characters are required"),
          validity: Yup.string()
          .required("This field is required")
          .max(4, "Maximum 4 characters are allowed")
          .min(1, "Minimum 1 characters are required"),
          price:Yup.string()
          .required("This field is required")
          .max(10, "Maximum 10 characters are allowed")
          .min(1, "Minimum 1 character are required"),
      }),
      onSubmit: async () => {
        formik.setSubmitting(true);
  
        const body = {
          title: formik?.values?.title,
          price:formik?.values?.price,
          description:formik?.values?.description,
          validity:formik?.values?.validity
        };
        const encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
        try {
          if (!id) {
            const response = await addBoost(encryptedBody).unwrap();
            if (response?.statusCode === 200) {
              successToast("Boost Added Successfully.");
              navigate("/manage-boost");
            }
          } else {
            const response = await updateBoost({
              id: id,
              body: encryptedBody,
            }).unwrap();
            if (response?.statusCode === 200) {
              successToast("Boost Updated Successfully.");
              navigate("/manage-boost");
            }
          }
        } catch (error: any) {
          errorToast(error?.data?.message);
        } finally {
          formik.setSubmitting(false);
        }
       
      },
    });
    const fetchDataById = async (id: any) => {
      try {
        const response = await fetchById({ userId: id }).unwrap();
        if (response.statusCode === 200) {
          setByIdDetails(response?.data);
        }
      } catch (error) {
        console.error(error);
      }
    };
  
    useEffect(() => {
      if (id) {
        fetchDataById(id);
      }
    }, [id]);
 
    useEffect(() => {
      if (ByIdDetails) {
        formik.setValues({
          title: ByIdDetails?.title || "",
          description:ByIdDetails?.description||"",
          price:ByIdDetails?.price||"",
          validity:ByIdDetails?.validity||""
        });
      }
    }, [ByIdDetails]);
  
    return (
      <>
        <MainContainer>
          <div className="main_loyout">
            <div className="dashboard">
              <h1>{id?`Edit Boost Profile`:`Add Boost Profile`}</h1>
              <Button
                className="btn btn_primary"
                onClick={() => {
                  navigate("/manage-boost");
                }}
              >
                Back
              </Button>
            </div>
            <Card className="cards">
              <form onSubmit={formik.handleSubmit}>
                <CardContent sx={{ p: 1 }}>
                  <Grid container spacing={2}>
              
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                      <Typography className="custom_label">
                    Title
                      </Typography>
                      <TextField
                        hiddenLabel
                        type={"text"}
                        name="title"
                        // inputProps={{ maxLength: 40 }}
                        variant="outlined"
                        fullWidth
                        value={formik.values.title}
                        placeholder="Enter Title"
                        className="text_field"
                        onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                        helperText={formik.touched.title && formik.errors.title}
                      ></TextField>
                    </Grid>
                   
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">Price</Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    className="text_field"
                    name="price"
                    variant="outlined"
                    fullWidth
                    placeholder="Enter Price"
                    value={formik.values.price}
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else if (isNumber(val.target.value)) {
                        formik.handleChange(val);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.touched.price && Boolean(formik.errors.price)}
                    helperText={formik.touched.price && formik.errors.price}
                  />
                </Grid>

                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">Validity</Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="validity"
                    className="text_field"
                    variant="outlined"
                    fullWidth
                    placeholder="Enter Validity"
                    value={formik.values.validity}
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else if (isNumber(val.target.value)) {
                        formik.handleChange(val);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.touched.validity && Boolean(formik.errors.validity)}
                    helperText={formik.touched.validity && formik.errors.validity}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}/>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">Description</Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="description"
                    className="text_field"
                    variant="outlined"
                    fullWidth
                    multiline
                    minRows={4}
                    placeholder="Enter Description"
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.description && Boolean(formik.errors.description)}
                    helperText={formik.touched.description && formik.errors.description}
                  />
                </Grid>
                    {/* <Grid item xs={4}>
                      <Typography className="custom_label">
                        Sub-Category Name
                      </Typography>
                      <TextField
                        hiddenLabel
                        type={"text"}
                        name="title"
                        inputProps={{ maxLength: 40 }}
                        variant="outlined"
                        fullWidth
                        value={formik.values.title}
                        placeholder=" Enter Sub-Category Name"
                        className="text_field"
                        onBlur={formik.handleBlur}
                        onChange={(val) => {
                          if (
                            val.target.value === " " ||
                            val.target.value === "."
                          ) {
                          } else if ((val.target.value)) {
                            formik.handleChange(val);
                          }
                        }}
                        helperText={formik.touched.title && formik.errors.title}
                      ></TextField>
                    </Grid> */}
                    {/* <Grid item xs={12}>
                  <Typography className="dialog_title" style={{ paddingTop: '20px' }}>Description</Typography>
 
  <div style={{ width: '66%' }}>  
  { displayEditText ? <EditText
      content={description}
      setContent={setDescription}
    />:null}
  </div>
</Grid> */}
                  </Grid>
                  <div className="form_btn">
                    <Button
                      size="large"
                      type="submit"
                      className="btn btn_primary"
                    >
                      Save
                    </Button>
                  </div>
                </CardContent>
              </form>
            </Card>
          </div>
        </MainContainer>
      </>
    );
  };
  
  export default AddBoost;
