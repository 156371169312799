import React from "react";
import PhoneInput from "react-phone-input-2";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Box, Button, Card, CardContent, Grid, IconButton, Menu, MenuItem, TextField, Typography } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import MainContainer from "../../layout/MainContainer";
import { useNavigate } from "react-router-dom";
const AgentChatDetails = () => {
  const options = ["Share Profile", "Report"];
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const ITEM_HEIGHT = 48;
  const navigate=useNavigate();
  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng"> Chat Details</h1>
          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/manage-agents/details");
            }}
          >
            Back
          </Button>
        </div>
        <Card className="cards">
          <CardContent sx={{ p: 1 }}>
        <div className="main">
      <div className="heading">
        <div className="userDetail">
          <figure>
            <img src="/static/images/user_placeholder.png" alt="" />
          </figure>
          <div className="username">
            <h3>User1</h3>
          </div>
        </div>
 
      </div>
      <div className="chat-div">
        <div className="chats_lst">
          <div className="time">
            <p>Today at 5:03 PM</p>
          </div>
          <div className="out-going">
            <p>Hello, are you nearby?</p>
          </div>
          <div className="incoming">
            <p>I'll be there in a few mins</p>
          </div>
          <div className="out-going">
            <p>OK, I'm in front of the bus stop</p>
          </div>
          <div className="time">
            <p>Today at 5:03 PM</p>
          </div>
          <div className="incoming">
            <p>Sorry , I'm stuck in traffic. Please give me a moment.</p>
          </div>
        </div>
        {/* <div className="chat_foot">
          <TextField
            hiddenLabel
            placeholder="Type a message"
            fullWidth
            className="text_field"
            name="text"
            type="message"
            variant="outlined"
            // multiline
          />
          <SendIcon />
        </div> */}
      </div>
    </div>

 </CardContent>
 </Card>
      </div>
    </MainContainer>
  );
};
export default AgentChatDetails;