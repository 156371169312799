import emptySplitApi from "../utils/rtk";
import { User, updateProfile } from "../types/User";
import { END_POINTS } from "../helpers";
import { CommonBody } from "../types/General";

type CommonResponseType = {
  statusCode: number;
  message: string;
  s3Location?: string
};

type PostLoginBody = {
  email: string;
  password: string;
};
type queryUser = {
  page: number,
  search: string
}
export const authApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({

    getAllUser: builder.query<CommonResponseType & { data: any }, { page?: number; search?: string;limit:number }>({
      query: ({  page, search,limit }) => {
        // let url = END_POINTS.user;
        let url = `${END_POINTS.user}?role=4&page=${page}&search=${search}&limit=${limit}`;
        // url += `?role=4&limit=10`;
        // if (page && search) {
        //   url += `&page=${page}&search=${search}`;
        // } else if (page) {
        //   url += `&page=${page}`;
        // } else if (search) {
        //   url += `?search=${search}`;
        // }

        return {
          url: url,
          method: "GET",
        };
      },
    }),
    getPendingCompany: builder.query<CommonResponseType & { data: any }, { page?: number; search?: string ;limit:number}>({
      query: ({  page, search,limit }) => {
        // let url = END_POINTS.user;
        let url = `${END_POINTS.user}?role=5&isAdminAprooved=1&page=${page}&search=${search}&limit=${limit}`;
        // url += `?role=5&isAdminAprooved=1&limit=10`;
        // if (page && search) {
        //   url += `&page=${page}&search=${search}`;
        // } else if (page) {
        //   url += `&page=${page}`;
        // } else if (search) {
        //   url += `?search=${search}`;
        // }

        return {
          url: url,
          method: "GET",
        };
      },
    }),

    getApprovedCompany: builder.query<CommonResponseType & { data: any }, { page?: number; search?: string;limit:number}>({
      query: ({  page, search,limit }) => {
        // let url = END_POINTS.user;
        let url = `${END_POINTS.user}?role=5&isAdminAprooved=2&page=${page}&search=${search}&limit=${limit}`;
        // url += `?role=5&isAdminAprooved=2&limit=10`;
        // if (page && search) {
        //   url += `&page=${page}&search=${search}`;
        // } else if (page) {
        //   url += `&page=${page}`;
        // } else if (search) {
        //   url += `?search=${search}`;
        // }

        return {
          url: url,
          method: "GET",
        };
      },
    }),
    getRejectedCompany: builder.query<CommonResponseType & { data: any }, { page?: number; search?: string, limit:number }>({
      query: ({  page, search,limit }) => {
        // let url = END_POINTS.user;
        let url = `${END_POINTS.user}?role=5&isAdminAprooved=3&page=${page}&search=${search}&limit=${limit}`;
        // if (page && search) {
        //   url += `&page=${page}&search=${search}`;
        // } else if (page) {
        //   url += `&page=${page}`;
        // } else if (search) {
        //   url += `?search=${search}`;
        // }

        return {
          url: url,
          method: "GET",
        };
      },
    }),

    getPendingAgent: builder.query<CommonResponseType & { data: any }, { page?: number; search?: string ;limit:number}>({
      query: ({  page, search,limit }) => {
        let url = `${END_POINTS.user}?role=3&isAdminAprooved=1&page=${page}&search=${search}&limit=${limit}`;
        // url += `?role=3&isAdminAprooved=1&limit=10`;
        // if (page && search) {
        //   url += `&page=${page}&search=${search}`;
        // } else if (page) {
        //   url += `&page=${page}`;
        // } else if (search) {
        //   url += `?search=${search}`;
        // }

        return {
          url: url,
          method: "GET",
        };
      },
    }),

    getApprovedAgent: builder.query<CommonResponseType & { data: any }, { page?: number; search?: string ,limit:number}>({
      query: ({  page, search ,limit}) => {
        let url = `${END_POINTS.user}?role=3&isAdminAprooved=2&page=${page}&search=${search}&limit=${limit}`;
        // url += `?role=3&isAdminAprooved=2&limit=10`;
        // if (page && search) {
        //   url += `&page=${page}&search=${search}`;
        // } else if (page) {
        //   url += `&page=${page}`;
        // } else if (search) {
        //   url += `?search=${search}`;
        // }

        return {
          url: url,
          method: "GET",
        };
      },
    }),
    getRejectedAgent: builder.query<CommonResponseType & { data: any }, { page?: number; search?: string, limit:number }>({
      query: ({  page, search ,limit}) => {
        let url = `${END_POINTS.user}?role=3&isAdminAprooved=3&page=${page}&search=${search}&limit=${limit}`;
        // url += `?role=3&isAdminAprooved=3&limit=10`;
        // if (page && search) {
        //   url += `&page=${page}&search=${search}`;
        // } else if (page) {
        //   url += `&page=${page}`;
        // } else if (search) {
        //   url += `?search=${search}`;
        // }

        return {
          url: url,
          method: "GET",
        };
      },
    }),


    getSpecificUserById: builder.query<CommonResponseType & { data: any }, { userId: any }>({
      query: ({ userId }) => {
        const url = `${END_POINTS.getDetails}/${userId}?detailType=1`;
        return {
          url,
          method: "GET"
        };
      }
    }),
    GetReelByAgent: builder.query<CommonResponseType & { data: any }, { userId: any }>({
      query: ({ userId }) => {
        const url = `${END_POINTS.getDetails}/${userId}?detailType=6`;
        return {
          url,
          method: "GET"
        };
      }
    }),
    getSpecificCompanyAllAgentsById: builder.query<CommonResponseType & { data: any }, { userId: any }>({
      query: ({ userId }) => {
        const url = `${END_POINTS.getDetails}/${userId}?detailType=2`;
        return {
          url,
          method: "GET"
        };
      }
    }),
    getSpecificCompanyPropertyById: builder.query<CommonResponseType & { data: any }, { userId: any }>({
      query: ({ userId }) => {
        const url = `${END_POINTS.getDetails}/${userId}?detailType=3&limit=10`;
        return {
          url,
          method: "GET"
        };
      }
    }),
    getTokenListing: builder.query<CommonResponseType & { data: any }, { userId: any }>({
      query: ({ userId }) => {
        const url = `${END_POINTS.getDetails}/${userId}?detailType=5&limit=10`;
        return {
          url,
          method: "GET"
        };
      }
    }),

    getAllDetailsById: builder.query<
      CommonResponseType & { data: any },
      { page?: number; userId: any; search?: string ,limit:number}
    >({
      query: ({ page, userId, search,limit }) => {
        let url = `${END_POINTS.getDetails}/${userId}?page=${page}&search=${search}&limit=${limit}`;

        // if (page && search) {
        //   url += `?page=${page}&search=${search}`;
        // } else if (page) {
        //   url += `?page=${page}`;
        // } else if (search) {
        //   url += `?search=${search}`;
        // }

        return {
          url,
          method: "GET"
        };
      }
    }),

    EditUser: builder.mutation<
      CommonResponseType & { data: CommonBody },
      { id: string | undefined; body: CommonBody }
    >({
      query: ({ body, id }) => ({
        url: `${END_POINTS.user}/${id}`,
        method: "PUT",
        body,
      }),
    }),



    deleteByIduser: builder.query<CommonResponseType & { data: any }, { userId: any }>({
      query: ({ userId }) => ({
        url: `${END_POINTS.user}/${userId}`,
        method: "DELETE",
      }),
    }),
    getChatListing: builder.query<CommonResponseType & { data: any }, { userId: any }>({
      query: ({ userId }) => {
        const url = `${END_POINTS.getDetails}/${userId}?detailType=4&limit=10`;
        return {
          url,
          method: "GET"
        };
      }
    }),
  }),
});

export const {
  useLazyGetAllUserQuery,
  useLazyGetPendingCompanyQuery,
  useLazyGetApprovedCompanyQuery,
  useLazyGetRejectedCompanyQuery,
  useLazyGetSpecificUserByIdQuery,
  useEditUserMutation,
  useLazyDeleteByIduserQuery,
  useLazyGetPendingAgentQuery,
  useLazyGetApprovedAgentQuery,
  useLazyGetRejectedAgentQuery,
  useLazyGetAllDetailsByIdQuery,
  useLazyGetSpecificCompanyAllAgentsByIdQuery,
  useLazyGetSpecificCompanyPropertyByIdQuery,
  useLazyGetTokenListingQuery,
  useLazyGetChatListingQuery,
  useLazyGetReelByAgentQuery

} = authApi;
